import Store from 'localforage'
import _ from 'lodash/fp'
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'

import { actions, selectors, types } from '../reducers/auth'
import { authenticatedPostRequest, postEffect, putEffect } from './network'

import * as rolesReducer from '../reducers/roles'
import { actions as clubsActions } from '../reducers/clubs'
import { actions as coursesActions } from '../reducers/courses'
import { actions as teesActions } from '../reducers/tees'
import { actions as guidesActions } from '../reducers/guides'
import { navigateReplaceRoot } from './redirects'
import i18n from '../i18n'

export const TOKEN_KEY = 'business_token_guyey'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loginEffect ({ email, password }) {
  const request = {
    url: 'authenticate',
    options: {
      body: {
        username: email,
        password
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * authenticatedEffect () {
  const request = {
    url: 'authenticated'
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * logoutEffect () {
  yield call(clearToken)
}

export function * renewSessionEffect () {
  const url = 'token'
  const token = yield select(selectors.getToken)
  const request = yield call(authenticatedPostRequest, token)
  const { error, expired_at: expiredAt } = yield call(request, url)
  return error
    ? { error }
    : { token, expired_at: expiredAt }
}

export function * clearToken () {
  yield Store.clear()
}

export function * saveToken (tokenObject) {
  yield Store.setItem(TOKEN_KEY, tokenObject)
}

export function * loadToken () {
  return yield Store.getItem(TOKEN_KEY)
}

export function * initResetPassword (email) {
  const request = {
    url: 'reset_password',
    options: {
      body: {
        email
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * resetPassword ({ password, email, token }) {
  const request = {
    url: 'reset_password',
    options: {
      body: {
        email,
        password,
        token
      }
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * takeLatest(types.LOGIN_REQUEST, loginFlow)
 */
export function * loginFlow ({ payload }) {
  const first = yield race({
    login: call(loginEffect, payload),
    logout: take(types.LOGOUT)
  })
  yield put(actions.loginRequestDone())

  const nextPathName = yield select(_.get('router.location.pathname'))
  if (first.login) {
    const { error, ...loginResponse } = first.login
    if (error) {
      yield put(actions.loginError({ error }))
    } else {
      yield put(actions.setToken({ token: loginResponse.token }))

      const userOrError = yield call(authenticatedEffect)

      if (userOrError.error) {
        yield put(actions.loginError({ error: userOrError.error }))
      } else {
        yield call(saveToken, loginResponse)
        yield put(rolesReducer.actions.loadRequest())
        // wait for response
        yield take([rolesReducer.types.LOAD_SUCCESS, rolesReducer.types.LOAD_ERROR])
        // don't care if roles were retrieved
        yield put(actions.loginSuccess({ ...userOrError, ...loginResponse, nextPathName }))
      }
    }
  } else if (first.logout) {
    yield call(logoutFlow)
  }
}

export function * bootstrapFlow () {
  const nextPathName = yield select(_.get('router.location.pathname'))
  const tokenObject = yield call(loadToken)

  if (tokenObject) {
    const { token } = tokenObject
    yield put(actions.setToken({ token }))
    const userOrError = yield call(authenticatedEffect)

    if (userOrError.error) {
      yield put(actions.logout({ nextPathName }))
    } else {
      yield put(rolesReducer.actions.loadRequest())
      // wait for response
      yield take([rolesReducer.types.LOAD_SUCCESS, rolesReducer.types.LOAD_ERROR])
      // don't care if roles were retrieved
      yield put(actions.loginSuccess({ ...userOrError, token, nextPathName }))
    }
  } else {
    yield put(actions.logout({ nextPathName }))
  }
  yield put(actions.bootstrapDone())
}

/**
 * takeLatest(types.LOGOUT, logoutFlow)
 */
export function * logoutFlow () {
  const token = yield select(selectors.getToken)
  yield [
    call(logoutEffect, token),
    call(clearToken)
  ]
}

/**
 * takeLatest(types.RENEW_SESSION_REQUEST, renewSessionFlow)
 */
export function * renewSessionFlow () {
  const { token, expired_at: expiredAt, error } = yield call(renewSessionEffect)
  if (error) {
    yield put(actions.logout())
  } else {
    yield put(actions.renewSessionSuccess({ token, expired_at: expiredAt }))
  }
}

export function * getClubResources () {
  yield put(clubsActions.loadRequestSingle())
  yield put(coursesActions.loadRequest())
  yield put(teesActions.loadRequest())
  yield put(guidesActions.loadRequest())
}

export function * initResetPasswordFlow ({ payload }) {
  return yield call(initResetPassword, payload)
}

export function * resetPasswordFlow ({ payload }) {
  const result = yield call(resetPassword, payload)
  if (result.error) {
    yield put(actions.passwordResetError(result.error))
  } else {
    yield put(actions.passwordResetSuccess(true))
    yield call(navigateReplaceRoot, '/signin')
  }
  yield put(actions.passwordResetDone())
}

function * reloadProfileFlow () {
  const result = yield call(authenticatedEffect)
  if (result.error) {
    yield put(actions.reloadProfileError(result.error))
  } else {
    yield put(actions.reloadProfileSuccess(result))
  }
}

function * setLanguageFlow ({payload}) {
  i18n.changeLanguage(_.get('language', payload))
  i18n.reloadResources() // fetch languages from server
}

export default function * () {
  return yield all([
    takeLatest(types.LOGIN_REQUEST, loginFlow),
    takeLatest(types.LOGOUT, logoutFlow),
    takeLatest(types.RENEW_SESSION_REQUEST, renewSessionFlow),
    takeLatest(types.BOOTSTRAP_REQUEST, bootstrapFlow),
    takeLatest(types.INIT_PASSWORD_RESET, initResetPasswordFlow),
    takeLatest(types.PASSWORD_RESET, resetPasswordFlow),
    takeLatest(types.RELOAD_PROFILE_REQUEST, reloadProfileFlow),
    takeLatest(types.LOGIN_SUCCESS, setLanguageFlow),
    takeLatest(types.RELOAD_PROFILE_SUCCESS, setLanguageFlow)
  ])
}
