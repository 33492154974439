import _ from 'lodash/fp'
import { goBack, push, LOCATION_CHANGE } from 'connected-react-router'
import { all, call, put, takeLatest, take } from 'redux-saga/effects'
import { getClubResources } from './auth'

import { types as authTypes } from '../reducers/auth'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * navigateReplaceRoot (route) {
  yield put(push(route))
}

export function * navigateGoBack () {
  yield put(goBack())
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

// export function * redirectGenericFlow ({ payload }) {
//   yield call(navigateReplaceRoot, payload.path)
// }

/**
 * After Login Success
 * Much string hacking is needed because we have some implicit redirects.
 * Yes, it's sad. :(
 */
export function * redirectLoginSuccess ({ payload }) {
  const { nextPathName, club_id: clubId } = payload

  if (_.startsWith('/reset_password', nextPathName)) {
    return
  }

  if (nextPathName && nextPathName !== '/' && nextPathName !== '/signin') {
    yield call(navigateReplaceRoot, `${nextPathName}`)
  } else {
    yield call(navigateReplaceRoot, `/${clubId}/home`)
  }
  yield take(LOCATION_CHANGE)
  yield call(getClubResources)
}

export function * redirectLogout ({ payload }) {
  if (!payload) {
    yield call(navigateReplaceRoot, '/signin')
  } else {
    const { nextPathName } = payload
    if (_.startsWith('/reset_password', nextPathName)) {
      return
    }

    yield call(navigateReplaceRoot, '/signin?nextPathName=' + nextPathName)
  }
}

export default function * () {
  return yield all([
    takeLatest(authTypes.LOGIN_SUCCESS, redirectLoginSuccess),
    takeLatest(authTypes.LOGOUT, redirectLogout)
  ])
}
