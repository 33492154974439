import _ from 'lodash/fp'
import { createTypes, createActions, createReducer, mergeLeft } from '@fe/redux/reducers/util'
import { createSelector } from 'reselect'

const namespace = 'graphics'

const typeKeys = [
  'SET_THEMES',
  'SET_GROUPS',
  'SET_LAYERS',
  'SET_ELEMENTS',
  'SET_ATTRIBUTES',
  'SET_ATTRIBUTE_UNITS',
  'SET_THEME_ATTRIBUTES',
  'SET_GEOMETRY_TYPES',
  'SET_GRAPHICS_ATTRIBUTES',
  'SET_GRAPHICS',

  'GET_GRAPHICS',
  'GET_GRAPHICS_SUCCESS',
  'GET_GRAPHICS_FAILURE',

  'GET_GRAPHICS_THEMES',
  'GET_GRAPHICS_LAYERS',
  'GET_GRAPHICS_ATTRIBUTES',
  'GET_GRAPHICS_THEME_ATTRIBUTES',
  'GET_GRAPHICS_ATTRIBUTE_UNITS',
  'GET_GRAPHICS_ELEMENTS',
  'GET_GRAPHICS_GROUPS',
  'GET_GRAPHICS_ATTRIBUTE_GRAPHICS',

  'NEW_ELEMENT', // should go trough the saga
  'NEW_ATTRIBUTE', // should go trough the saga
  'CONNECT_ATTRIBUTE', // should go trough the saga
  'CONNECT_ATTRIBUTE_FAIL',
  'CONNECT_ATTRIBUTE_SUCCESS',

  'LOAD_CONTEXT_DATA',
  'LOAD_CONTEXT_DATA_FAILURE',
  'LOAD_CONTEXT_DATA_SUCCESS'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  graphicsRequestAt: undefined,
  graphicsFailedAt: undefined,
  graphicsLoadedAt: undefined,

  themes: {},
  groups: {},
  layers: {},
  elements: {},
  attributes: {},
  attributeUnits: {},
  themeAttributes: {},
  geometryTypes: ['line', 'point', 'polygon'],
  contextData: {}
}

const reducer = {
  [types.GET_GRAPHICS]: p => _.set(`graphicsRequestAt`, Date.now()),
  [types.GET_GRAPHICS_SUCCESS]: p => _.set(`graphicsLoadedAt`, Date.now()),
  [types.GET_GRAPHICS_FAILURE]: p => _.set(`graphicsFailedAt`, Date.now()),
  [types.SET_THEMES]: _.set('themes'),
  [types.SET_GROUPS]: _.set('groups'),
  [types.SET_LAYERS]: _.set('layers'),
  [types.SET_ELEMENTS]: _.set('elements'),
  [types.SET_ATTRIBUTES]: _.set('attributes'),
  [types.SET_ATTRIBUTE_UNITS]: _.set('attributeUnits'),
  [types.SET_THEME_ATTRIBUTES]: _.set('themeAttributes'),
  [types.SET_GEOMETRY_TYPES]: _.set('geometryTypes'),
  [types.SET_GRAPHICS_ATTRIBUTES]: _.set('graphicsAttributes'),
  [types.SET_GRAPHICS]: _.set('graphics'),
  [types.LOAD_CONTEXT_DATA_SUCCESS]: _.set('contextData'),

  [types.CONNECT_ATTRIBUTE]: p => _.flow(_.set('connecting', p), _.set('connected', undefined)),
  [types.CONNECT_ATTRIBUTE_FAIL]: p => _.flow(_.set('connecting', false), _.set('connected', _.set('succes', false, p))),
  [types.CONNECT_ATTRIBUTE_SUCCESS]: p => _.flow(
    _.set('connecting', false),
    _.set('connected', _.set('success', true, p))
  )
}

function createSelectors () {
  const connecting = _.get(`${namespace}.connecting`)
  const connected = _.get(`${namespace}.connected`)
  const contextData = _.get(`${namespace}.contextData`)
  const contextDataByHoleId = _.flow(
    contextData,
    _.get('course_data'),
    _.groupBy('customAttributes.holeId'),
    _.mapValues(_.keyBy('elementName'))
  )

  const getAllGroups = _.flow(
    _.get(`${namespace}.groups`),
    _.sortBy(_.flow(
      _.get('name'),
      _.toLower
    ))
  )

  const getMatchingGroups = (searchString = '') => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    return _.flow(
      _.get(`${namespace}.groups`),
      _.filter(({ name }) => searchStringExpression.test(name)),
      _.sortBy('name')
    )
  }

  const getElementsInGroup = (groupName = undefined, searchString = '') => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    return _.flow(
      _.get(`${namespace}.elements`),
      _.filter(({ name, groupName: elementGroupName }) => {
        const inGroup = groupName
          ? _.isEqual(elementGroupName, groupName)
          : true
        return inGroup && searchStringExpression.test(name)
      })
    )
  }

  const getAllElements = (searchString = '') => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    return _.flow(
      _.get(`${namespace}.elements`),
      _.filter(({ name }) => searchStringExpression.test(name)),
      _.sortBy(_.flow(
        _.get('name'),
        _.toLower
      ))
    )
  }

  const getMatchingAttributes = (searchString = '') => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    return _.flow(
      _.get(`${namespace}.attributes`),
      _.filter(({ name }) => searchStringExpression.test(name)),
      _.sortBy(_.flow(
        _.get('name'),
        _.toLower
      ))
    )
  }

  const elementAttributes = (elementName, themeName) => (state) => {
    // array of attributes in the theme
    const themeAttributes = _.get(`${namespace}.themeAttributes.${themeName}`)(state)
    // the entry with the correct elementName
    return _.flow(
      _.filter({ elementName }),
      _.keyBy('attributeName')
    )(themeAttributes)
  }

  const getAttributesForElement = (elementName = undefined, searchString = '', themeName) => (state) => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    // Cannot default in the arguments as null is a value and then the default is not used.
    themeName = themeName || 'basic'
    // Let's find an array of themes all the way up
    // e.g. ['pro', 'basic']
    let themes = [themeName]
    let parentTheme = _.get(`${namespace}.themes.${themeName}.parentName`, state)
    while (parentTheme) {
      themes = _.concat(themes, parentTheme)
      parentTheme = _.get(`${namespace}.themes.${parentTheme}.parentName`, state)
    }

    // Using this array, get attributes and never overwrite while iterating the array
    return _.reduce((attributes, themeName) => _.flow(
      _.get(`${namespace}.themeAttributes.${themeName}`),
      _.filter((themeAttribute) => {
        const isForElement = _.isEqual(_.get('elementName', themeAttribute), elementName)
        return isForElement && searchStringExpression.test(_.get('attributeName', themeAttribute))
      }),
      _.keyBy('attributeName'),
      mergeLeft(attributes)
    )(state), {}, themes)
  }

  const getBasicAttributes = _.flow(
    _.get(`${namespace}.themeAttributes.basic`),
    _.groupBy('elementName')
  )

  const getUnitForAttribute = (attributeName) => _.flow(
    _.get(`${namespace}.attributes.${attributeName}.attributeUnitName`)
  )

  const getGroupNames = _.flow(
    getAllGroups,
    _.map('name')
  )

  const getLayers =
    _.flow(
      _.get(`${namespace}.layers`)
    )

  const getUnits =
    _.flow(
      _.get(`${namespace}.attributeUnits`)
    )

  const getGeometryTypes =
    _.flow(
      _.get(`${namespace}.geometryTypes`)
    )

  const getThemes = (searchString = '') => {
    const searchStringExpression = new RegExp(_.trim(searchString), 'i')
    return _.flow(
      _.get(`${namespace}.themes`),
      _.filter(({ name }) => searchStringExpression.test(name)),
      _.sortBy(_.flow(
        _.get('name'),
        _.toLower
      ))
    )
  }

  const getThemesMap = createSelector(
    _.get(`${namespace}.themes`),
    _.identity
  )

  const getThemesSorted = createSelector(
    getThemesMap,
    _.flow(
      _.keys,
      _.sortBy(_.identity)
    )
  )

  const getDefinedElementsInTheme = (themeName = '') =>
    _.flow(
      _.get(`${namespace}.themeAttributes.${themeName}`),
      _.groupBy('elementName')
    )

  const getGraphicsForPair = (elementName, attributeName) =>
    _.flow(
      _.get(`${namespace}.graphicsAttributes.${elementName}`),
      _.filter(_.flow(
        _.get('attributeName'),
        _.isEqual(attributeName)
      ))
    )
  const getGraphicsAttributes = (elementName) => _.get(`${namespace}.graphicsAttributes.${elementName}`)

  const getGraphicsData = (graphicsName) =>
    _.flow(
      _.get(`${namespace}.graphics.${graphicsName}`)
    )

  const getGeometryType = (elementName) =>
    _.flow(
      _.get(`${namespace}.elements.${elementName}.geometryType`)
    )

  const getGroupName = (elementName) =>
    _.flow(
      _.get(`${namespace}.elements.${elementName}.groupName`)
    )

  return {
    connecting,
    connected,
    getAllGroups,
    getGroupNames,
    getGroupName,
    getMatchingGroups,
    getElementsInGroup,
    getAllElements,
    getMatchingAttributes,
    getBasicAttributes,
    getAttributesForElement,
    getUnitForAttribute,
    getLayers,
    getUnits,
    getGeometryTypes,
    getThemes,
    getThemesMap,
    getThemesSorted,
    getDefinedElementsInTheme,
    getGraphicsForPair,
    getGraphicsData,
    getGeometryType,
    getGraphicsAttributes,
    contextData,
    contextDataByHoleId,
    elementAttributes
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
