import { all, call, put, take, takeEvery } from 'redux-saga/effects'

import { types, actions } from '../reducers/drawingStyles'
import { getEffect } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadDrawingStylesEffect () {
  const url = 'drawing_styles'
  const request = { url }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadDrawingStylesFlow () {
  const drawingStyles = yield call(loadDrawingStylesEffect)

  if (drawingStyles.error) {
    yield put(actions.loadError(drawingStyles))
  } else {
    yield put(actions.loadSuccess(drawingStyles))
  }

  yield put(actions.loadRequestDone())
}

export default function * () {
  return yield all([
    takeEvery(types.LOAD_REQUEST, loadDrawingStylesFlow)
  ])
}
