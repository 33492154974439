import { PATH_API_BASE } from '../config'
import { call, take } from 'redux-saga/effects'
import { createWatchRequests } from '@fe/redux/sagas/network'

export * from '@fe/redux/sagas/network'

export const watchRequests = createWatchRequests(PATH_API_BASE)

export function * requestBuilder (request, effect) {
  const chan = yield call(effect, request)
  return yield take(chan)
}
