import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash/fp'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import withStyles from '@material-ui/core/styles/withStyles'
import colors from '@fe/ui/colors'

class SnackWhen extends React.PureComponent {
  static propTypes = {
    test: PropTypes.bool.isRequired,
    selector: PropTypes.func.isRequired,
    children: PropTypes.string.isRequired,
    top: PropTypes.bool,
    type: PropTypes.string
  }
  static defaultProps = {
    top: false,
    type: 'loading'
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.test && nextProps.test !== this.props.test) {
      this.setState({
        show: true
      })
    }
  }

  state = {
    show: true
  }

  render () {
    const { type, classes } = this.props
    return (
      <Snackbar
        onClose={(e, reason) => this.setState({ show: !(type === 'confirmation' || reason === 'clickaway') })}
        autoHideDuration={type === 'confirmation' ? 5000 : null}
        open={this.props.test && this.state.show}
        anchorOrigin={{
          vertical: this.props.top ? 'top' : 'bottom',
          horizontal: 'center'
        }}>
        <SnackbarContent
          className={
            type === 'error'
              ? classes.error
              : type === 'warning'
                ? classes.warning
                : type === 'confirmation'
                  ? classes.confirmation
                  : classes.default
          }
          message={this.props.children}
        />
      </Snackbar>
    )
  }
}

export default _.compose(
  withStyles(theme => ({
    close: {
      width: theme.spacing(1) * 4,
      height: theme.spacing(1) * 4
    },
    default: {
      backgroundColor: colors.businessBlue,
      color: colors.white
    },
    confirmation: {
      backgroundColor: colors.pieLightBlue,
      color: colors.white
    },
    error: {
      backgroundColor: colors.primaryRed,
      color: colors.white
    },
    warning: {
      backgroundColor: colors.primaryOrange,
      color: colors.white
    }
  })),
  connect(
    (s, { selector }) => ({ test: Boolean(selector(s)) })
  )
)(SnackWhen)
