import _ from 'lodash/fp'

import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'clubStatus'

export const typeKeys = [
  'DELETE_REQUEST',
  'DELETE_ERROR',
  'DELETE_REQUEST_DONE',
  'SAVE_REQUEST',
  'SAVE_ERROR',
  'SAVE_REQUEST_DONE',
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',
  'LOAD_REQUEST_DONE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  item: {},

  deleteError: null,
  deleteRequestWaiting: false,
  loadError: null,
  saveRequestWaiting: false,
  saveError: null
}

const reducer = {
  [types.DELETE_REQUEST]: p =>
    _.flow(
      _.set('deleteRequestWaiting', true),
      _.set('deleteError', null)
    ),
  [types.DELETE_ERROR]: p =>
    _.flow(
      _.set('deleteError', p)
    ),
  [types.DELETE_REQUEST_DONE]: p =>
    _.flow(
      _.set('deleteRequestWaiting', false)
    ),
  [types.SAVE_REQUEST]: p =>
    _.flow(
      _.set('saveRequestWaiting', true),
      _.set('saveError', null)
    ),
  [types.SAVE_REQUEST_DONE]: p =>
    _.flow(
      _.set('saveRequestWaiting', false)
    ),
  [types.SAVE_ERROR]: p =>
    _.flow(
      _.set('saveError', p)
    ),
  [types.LOAD_REQUEST]: p =>
    _.flow(
      _.set('loadRequestWaiting', true),
      _.set('loadError', null)
    ),
  [types.LOAD_SUCCESS]: p =>
    _.flow(
      _.set('item', _.flow(
        _.set('start', _.get('active[0]', p)),
        _.set('end', _.get('active[1]', p)),
        _.unset('active')
      )(p))
    ),
  [types.LOAD_ERROR]: p =>
    _.flow(
      _.set('loadError', p)
    ),
  [types.LOAD_REQUEST_DONE]: p =>
    _.flow(
      _.set('loadRequestWaiting', false),
      _.set('loadError', null)
    ),
  [types.LOAD_REQUEST_ERROR]: p =>
    _.flow(
      _.set('loadError', p),
      _.set('loadRequestWaiting', false)
    )
}

export default createReducer(initialState, reducer, namespace, {})

function createSelectors () {
  const clubStatus = _.get(`${namespace}.item`)
  const savingStatus = _.get(`${namespace}.saveRequestWaiting`)

  return {
    clubStatus,
    savingStatus
  }
}

export const selectors = createSelectors()
