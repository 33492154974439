import _ from 'lodash/fp'

import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'geoimages'

export const typeKeys = [

]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  projections: {
    sweden: {
      projection: 'EPSG:3006',
      projectionText: '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'
    },
    wgs84: {
      projection: 'EPSG:4326',
      projectionText: '+proj=longlat +datum=WGS84 +no_defs'
    }
  }
}

const reducer = {

}

export default createReducer(initialState, reducer, namespace, { whitelist: ['items', 'loadedAt'] })

function createSelectors () {
  const countryProjection = (country) => (s) => {
    return _.get(`${namespace}.projections.${country}`, s)
  }
  const countries = _.flow(
    _.get(`${namespace}.projections`),
    _.keys,
    _.sortBy(_.identity)
  )

  return {
    countryProjection,
    countries
  }
}

export const selectors = createSelectors()
