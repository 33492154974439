import { all, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'
import _ from 'lodash/fp'

import { types, actions } from '../reducers/guides'
import { deleteEffect, getEffect, postEffect, putEffect, prependClubsUrl, getCurrentClubId } from './network'
import * as snack from './snackMessage'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * createGuideEffect (guide) {
  const request = {
    url: (yield call(prependClubsUrl, `guides`)),
    options: {
      body: guide
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateAllEffect (sideEffects) {
  const request = {
    url: 'guides/release-all',
    options: {
      body: {
        sideEffects
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * copyGuideEffect (guideId, title) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/copy`)),
    options: {
      body: {
        guide_id: _.parseInt(10, guideId),
        title
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteGuideEffect (guideId) {
  const url = yield prependClubsUrl(`guides/${guideId}`)
  const request = {
    url
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

export function * generateDigitalEffect (guide, sideEffects = []) {
  const { guide_id: guideId } = guide
  // URL contains `drawing_style/0` for legacy reasons - is ignored by API
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/drawing_style/0/release`)),
    options: {
      body: {
        sideEffects
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateOverviewEffect (guide) {
  const { guide_id: guideId } = guide
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/generate_overview`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateWebImagesEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/generate_web_images`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateWebImagesLocationEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/web_images_location`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateGreenDrawingsEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/generate_green_drawings`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateGreenDrawingsLocationEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/green_drawings_location`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generatePdfEffect (guide) {
  const { guide_id: guideId } = guide
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/generate_pdf`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateTeeSignsEffect (guide) {
  const { guide_id: guideId } = guide
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/generate_tee_signs`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * generateTeeSignsLocationEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/tee_signs_location`))
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * loadGuidesEffect (payload) {
  const clubId = _.get('clubId', payload)
  const request = {
    url: (yield call(prependClubsUrl, 'guides', clubId))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveGuideEffect (guide) {
  const { guide_id: guideId } = guide
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}`)),
    options: {
      body: guide
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * uploadLogoEffect (logoData) {
  const { guideId, data } = logoData
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/icon`)),
    options: {
      body: {
        data
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

function * getOverridesEffect ({ guideId }) {
  const request = guideId
    ? {
      url: (yield prependClubsUrl(`guides/${guideId}/overrides`))
    }
    : {
      url: '/overrides'
    }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

function * loadAppOrdersEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'app-orders'))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

function * setAppOrdersEffect (orders) {
  const request = {
    url: (yield call(prependClubsUrl, 'app-orders')),
    options: {
      body: {
        orders
      }
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

// payload: { guideId, title }
export function * copyGuideFlow ({ payload }) {
  const { guideId, title } = payload

  const res = yield call(copyGuideEffect, guideId, title)

  if (res.error) {
    yield put(actions.copyError(res))
  } else {
    // fire up a load request to get the new guide
    yield put(actions.loadRequest())
    // when the success is put we don't yet have the new guide, but that's ok
    yield put(actions.copySuccess(res))
  }

  yield put(actions.copyDone())
}

export function * deleteGuideFlow ({ payload: guideId }) {
  const response = yield call(deleteGuideEffect, guideId)
  const error = _.get('error', response)

  if (error) {
    yield put(actions.deleteError(error))
  } else {
    yield put(actions.deleteSuccess(guideId))
  }

  yield put(actions.deleteDone())
}

export function * generateDigitalFlow ({ payload }) {
  yield snack.setMessage('Generating GLFR Guide')

  const { guide, sideEffects } = payload

  if (guide.guide_id) {
    const res = yield call(generateDigitalEffect, guide, sideEffects)

    if (res.error) {
      yield put(actions.generateDigitalError({ error: res.error }))
    } else {
      yield put(actions.generateDigitalSuccess({ guide }))
    }
  }

  yield put(actions.generateDigitalDone({ guide }))
}

export function * generateOverviewFlow ({ payload }) {
  yield snack.setMessage('Generating Overview')

  const { guide } = payload

  if (guide.guide_id) {
    const res = yield call(generateOverviewEffect, guide)

    if (res.error) {
      yield put(actions.generateOverviewError({ error: res.error }))
    } else {
      yield put(actions.generateOverviewSuccess({ guide }))
    }
  }

  yield put(actions.generateOverviewDone({ guide }))
}

export function * generatePdfFlow ({ payload }) {
  yield snack.setMessage('Generating PDF Guide')

  const { guide } = payload

  if (guide.guide_id) {
    const res = yield call(generatePdfEffect, guide)

    if (res.error) {
      yield put(actions.generatePdfError({ error: res.error }))
    } else {
      yield put(actions.generatePdfSuccess({ guide }))
    }
  }

  yield put(actions.generatePdfDone({ guide }))
}

export function * generateTeeSignsFlow ({ payload }) {
  yield snack.setMessage('Generating Tee Signs')

  const { guide } = payload
  const { guide_id: guideId } = guide

  if (guideId) {
    const res = yield call(generateTeeSignsEffect, guide)

    if (res.error) {
      yield put(actions.generateTeeSignsError({ error: res.error }))
    } else {
      yield put(actions.generateTeeSignsSuccess({ guide }))
    }
  }

  yield put(actions.generateTeeSignsDone({ guide }))
}

export function * generateTeeSignsUrlFlow ({ payload: guideId }) {
  const { location: url } = yield call(generateTeeSignsLocationEffect, guideId)
  yield put(actions.setTeeSignsUrl({ guideId, url }))
}

export function * reloadGuidesFlow ({ payload }) {
  const clubId = _.get('guide.club_id', payload)
  return yield call(loadGuidesFlow, { payload: { clubId } })
}

export function * loadGuidesFlow ({ payload }) {
  const guides = yield call(loadGuidesEffect, payload)
  yield put(actions.loadRequestDone())

  if (guides.error) {
    yield put(actions.loadError(guides))
  } else {
    yield put(actions.loadSuccess(guides))
  }
}

export function * saveGuideRedirectFlow ({ payload: { guide } }) {
  const res = yield call(saveGuideEffect, guide)

  if (res.error) {
    yield put(actions.saveError({ error: res.error }))
  } else {
    yield put(actions.saveSuccessRedirect(res))
  }

  yield put(actions.saveDone())
}

export function * saveGuideFlow ({ payload: guide }) {
  if (!guide.guide_id) {
    const res = yield call(createGuideEffect, guide)

    if (res.error) {
      yield put(actions.saveError({ error: res.error }))
    } else {
      yield put(actions.saveSuccessRedirect(res))
    }
  } else {
    const res = yield call(saveGuideEffect, guide)

    if (res.error) {
      yield put(actions.saveError({ error: res.error }))
    } else {
      yield put(actions.saveSuccess(res))
    }
  }
  yield put(actions.loadRequest({ clubId: _.get('club_id', guide) }))
  yield take([types.LOAD_SUCCESS, types.LOAD_ERROR])

  yield put(actions.saveDone())
}

export function * uploadLogoFlow ({ payload }) {
  const res = yield call(uploadLogoEffect, payload)

  if (res.error) {
    yield put(actions.uploadLogoError({ error: res.error }))
  } else {
    yield put(actions.uploadLogoSuccess(res))
  }

  yield put(actions.loadRequest({ clubId: _.get('clubId', payload) }))

  yield put(actions.uploadLogoDone())
}

export function * generateWebImagesFlow ({ payload: guideId }) {
  yield snack.setMessage('Generating Web Images')
  yield call(generateWebImagesEffect, guideId)
}

export function * generateWebImagesUrlFlow ({ payload: guideId }) {
  const { location: url } = yield call(generateWebImagesLocationEffect, guideId)
  yield put(actions.generateWebImagesUrlSet({ guideId, url }))
}

export function * generateGreenDrawingsFlow ({ payload: guideId }) {
  yield snack.setMessage('Generating Green Drawings')
  yield call(generateGreenDrawingsEffect, guideId)
}

export function * generateGreenDrawingsUrlFlow ({ payload: guideId }) {
  const { location: url } = yield call(generateGreenDrawingsLocationEffect, guideId)
  yield put(actions.generateGreenDrawingsUrlSet({ guideId, url }))
}

function * releaseAllFlow ({ payload: sideEffects = [] }) {
  const res = yield call(generateAllEffect, sideEffects)
  if (res.error) {
    yield snack.setMessage(`Failed starting generate all flow`)
  } else {
    yield snack.setMessage(`Started generate all flow ${_.isEmpty(sideEffects) ? '' : `with ${sideEffects}`}`)
  }
}

function * getOverridesFlow ({ payload }) {
  const res = yield call(getOverridesEffect, payload)
  if (res.error) {
    yield put(actions.getOverridesError())
  } else {
    yield put(actions.getOverridesSuccess(_.keyBy('id', res)))
  }
  yield put(actions.getOverridesDone())
}

function * loadAppOrdersFlow () {
  const res = yield call(loadAppOrdersEffect)
  if (res.error) {
    console.warn(res.error)
  }
  yield put(actions.loadAppOrdersDone(res))
}

function * setAppOrdersFlow ({ payload }) {
  const res = yield call(setAppOrdersEffect, payload)
  const clubId = yield call(getCurrentClubId)
  if (res.error) {
    console.warn(res.error)
    yield put(actions.setAppOrdersFailure(res.error))
  } else {
    yield call(loadGuidesFlow, { payload: { clubId } })
  }
  yield put(actions.setAppOrdersDone())
}

export default function * () {
  return yield all([
    takeEvery(types.COPY, copyGuideFlow),
    takeEvery(types.DELETE, deleteGuideFlow),
    takeEvery(types.GENERATE_DIGITAL, generateDigitalFlow),
    takeEvery(types.GENERATE_OVERVIEW, generateOverviewFlow),
    takeEvery(types.GENERATE_PDF, generatePdfFlow),
    takeEvery(types.GENERATE_TEE_SIGNS, generateTeeSignsFlow),
    takeLatest(types.GENERATE_TEE_SIGNS_URL, generateTeeSignsUrlFlow),
    takeEvery(types.GENERATE_DIGITAL_SUCCESS, reloadGuidesFlow),
    takeEvery(types.GENERATE_OVERVIEW_SUCCESS, reloadGuidesFlow),
    takeEvery(types.GENERATE_PDF_SUCCESS, reloadGuidesFlow),
    takeEvery(types.GENERATE_TEE_SIGNS_SUCCESS, reloadGuidesFlow),
    takeEvery(types.GENERATE_WEB_IMAGES, generateWebImagesFlow),
    takeEvery(types.GENERATE_WEB_IMAGES_URL_REQUEST, generateWebImagesUrlFlow),
    takeEvery(types.GENERATE_GREEN_DRAWINGS, generateGreenDrawingsFlow),
    takeEvery(types.GENERATE_GREEN_DRAWINGS_URL_REQUEST, generateGreenDrawingsUrlFlow),
    takeLatest(types.LOAD_REQUEST, loadGuidesFlow),
    takeEvery(types.SAVE, saveGuideFlow),
    takeEvery(types.SAVE_REDIRECT, saveGuideRedirectFlow),
    takeEvery(types.UPLOAD_LOGO, uploadLogoFlow),

    takeLatest(types.LOAD_APP_ORDERS, loadAppOrdersFlow),
    takeLatest(types.SET_APP_ORDERS, setAppOrdersFlow),

    takeLatest(types.RELEASE_ALL, releaseAllFlow),

    takeLatest(types.GET_OVERRIDES, getOverridesFlow)
  ])
}
