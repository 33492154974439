import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/logo'
import { postEffect, prependClubsUrl } from './network'

import { actions as clubActions } from '../reducers/clubs'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * saveRoundLogoEffect ({ data }) {
  const url = yield prependClubsUrl('logo')
  const body = { round_data: data }
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * saveRectangularLogoEffect ({ data }) {
  const url = yield prependClubsUrl('logo')
  const body = { rectangular_data: data }
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * saveLogoFlow ({ payload }) {
  const { clubId, roundData, rectangularData } = payload

  if (roundData) {
    yield call(saveRoundLogoEffect, { data: roundData })
  } else if (rectangularData) {
    yield call(saveRectangularLogoEffect, { data: rectangularData })
  }

  yield put(clubActions.loadRequestSingle({ clubId }))
  yield put(actions.saveLogoDone())
  yield put(clubActions.dialogUploadHide())
}

export default function * () {
  return yield all([
    takeLatest(types.SAVE_LOGO, saveLogoFlow)
  ])
}
