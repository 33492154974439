import { put, call, all, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash/fp'

import * as AssetsReducer from '../reducers/assets'
import * as ClubsReducer from '../reducers/clubs'

import { getEffect, postEffect, requestBuilder, deleteEffect } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

function * getCategoriesEffect () {
  const request = {
    url: 'assets/categories'
  }
  return yield requestBuilder(request, getEffect)
}

function * getAssetsEffect (payload) {
  const clubId = _.get('clubId', payload)
  const elementName = _.get('elemetnName', payload)
  let url = ''
  if (elementName) {
    url = `assets/${clubId}/${elementName}`
  } else {
    url = `assets/${clubId}`
  }
  const request = {
    url
  }
  return yield requestBuilder(request, getEffect)
}

function * postAssetEffect (payload) {
  const bundleName = _.get('bundle.name', payload)
  const request = {
    url: 'assets/upload',
    options: {
      body: _.flow(
        _.omit('bundle'),
        _.set('name', bundleName)
      )(payload)
    }
  }
  return yield requestBuilder(request, postEffect)
}

function * deleteAssetEffect (payload) {
  const bundleName = _.get('bundle.name', payload)
  const request = {
    url: `assets/upload/${bundleName}`
  }
  return yield requestBuilder(request, deleteEffect)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * getCategoriesFlow () {
  const result = yield call(getCategoriesEffect)
  const error = _.get('error', result)
  if (error) {
    yield put(AssetsReducer.actions.getCategoriesFailure(error))
  } else {
    yield put(AssetsReducer.actions.getCategoriesSuccess(result))
  }
}

function * getAssetsFlow ({ payload }) {
  const clubId = yield select(ClubsReducer.selectors.getActive)
  const result = yield call(getAssetsEffect, _.set('clubId', clubId, payload))
  const error = _.get('error', result)
  if (error) {
    yield put(AssetsReducer.actions.getAssetsFailure(error))
  } else {
    yield put(AssetsReducer.actions.getAssetsSuccess(result))
  }
}

function * postAssetsFlow ({ payload }) {
  const calls = _.map((bundlePackage) => {
    return call(postAssetEffect, bundlePackage)
  }, payload)
  try {
    yield all(calls)
  } catch (e) {
    return yield put(AssetsReducer.actions.postAssetsFailure(e))
  }
  yield put(AssetsReducer.actions.postAssetsSuccess('success'))
  yield put(AssetsReducer.actions.getAssets())
}

function * deleteAssetFlow ({ payload }) {
  const result = yield call(deleteAssetEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(AssetsReducer.actions.deleteAssetFailure(error))
  } else {
    yield put(AssetsReducer.actions.deleteAssetSuccess('delete sucess'))
    yield put(AssetsReducer.actions.getAssets())
  }
}

export default function * () {
  return yield all([
    takeLatest(AssetsReducer.types.GET_CATEGORIES, getCategoriesFlow),
    takeLatest(AssetsReducer.types.GET_ASSETS, getAssetsFlow),
    takeLatest(AssetsReducer.types.POST_ASSETS, postAssetsFlow),
    takeLatest(AssetsReducer.types.DELETE_ASSET, deleteAssetFlow)
  ])
}
