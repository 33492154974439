import _ from 'lodash/fp'
import { assignLeft, createReducer, createTypes, createActions } from '@fe/redux/reducers/util'
import { sponsorsLocation } from '../config'

export const imageLocation = (sponsor = {}) => {
  const { id, club_id: clubId, logo, logo_file_name: logoFilename, timestamp = '' } = sponsor

  if ((typeof logo === 'boolean') && !logo) {
    return null
  } else if ((typeof logo === 'boolean') && logo) {
    return logoFilename
      ? sponsorsLocation() + id + '_' + logoFilename + '?anticache=' + timestamp
      : sponsorsLocation() + id + '_' + clubId + '.png?anticache=' + timestamp
  } else if (logo) {
    return 'data:image/jpg;base64,' + logo
  } else {
    return null
  }
}

const namespace = 'sponsors'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'CREATE_REQUEST',
  'CREATE_REQUEST_DONE',
  'CREATE_SUCCESS',
  'CREATE_ERROR',

  'DELETE_REQUEST',
  'DELETE_DONE',

  'SAVE_REQUEST',
  'SAVE_DONE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

function createSelectors () {
  const sponsors = _.get(`${namespace}.items`)
  const sponsorsByName = _.flow(sponsors, _.sortBy('name'))
  const sponsor = (sponsorId) => _.get(`${namespace}.items.${sponsorId}`)
  return {
    sponsors,
    sponsorsByName,
    sponsor
  }
}

export const selectors = createSelectors()

export const initialState = {
  items: {},
  saving: false,
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => assignLeft({ loadRequestWaiting: true, loadError: null }),
  [types.LOAD_REQUEST_DONE]: p => assignLeft({ loadRequestWaiting: false }),
  [types.LOAD_SUCCESS]: p => _.set('items', _.keyBy('id', p)),
  [types.LOAD_ERROR]: p => assignLeft({ loadError: p }),

  [types.CREATE_SUCCESS]: p => _.set(`items.${p.id}`, p),

  [types.DELETE_REQUEST]: p => _.flow(
    _.set('deleting', true)
  ),
  [types.DELETE_DONE]: sponsorId => _.flow(
    _.update('items', _.omit([sponsorId])),
    _.set('deleting', false)
  ),

  [types.SAVE_REQUEST]: p => assignLeft({ saving: true }),
  [types.SAVE_DONE]: p => _.flow(
    _.set(`items.${p.id}`, _.set('timestamp', Date.now(), p)),
    _.set('saving', false)
  )
}

export default createReducer(initialState, reducer)
