import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'graphicsElements'

const typeKeys = [
  'GET_ELEMENTS',
  'GET_ELEMENTS_SUCCESS',
  'GET_ELEMENTS_FAILURE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  items: {
    // elementName -> {}
  },
  error: undefined
}

const reducer = {
  [types.GET_ELEMENTS_SUCCESS]: items => _.set('items', _.keyBy('elementName', items)),
  [types.GET_ELEMENTS_FAILURE]: _.set('error')
}

function createSelectors () {
  const elements = _.get(`${namespace}.items`)
  const elementNames = _.flow(
    elements,
    _.keys,
    _.sortBy(_.identity)
  )
  return {
    elements,
    elementNames
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
