import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'holesTexts'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {
    // holeId -> tgId -> text
  },

  loadRequestWaiting: false,
  loadedAt: null,
  loadError: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => _.set('loadRequestWaiting', true),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: ({ holeId, textArray }) => _.flow(
    _.set('loadedAt', Date.now()),
    _.set(`items.${holeId}`, _.flow(_.keyBy('tracking_group_id'), _.mapValues('group_text'))(textArray))
  ),
  [types.LOAD_ERROR]: p => _.set('loadError', p.error)
}

function createSelectors () {
  const someTextsLoaded = _.get(`${namespace}.loadedAt`)
  const textsForHole = holeId => _.get(`${namespace}.items.${holeId}`)

  return {
    someTextsLoaded,
    textsForHole
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
