import en from './en.json'
import i18n from 'i18next'
import XHR from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
import { PATH_WEBLATE_PROXY } from '../config'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(XHR)
  .init({
    resources: { en: { translation: en }},
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    },
    backend: {
      loadPath: `${PATH_WEBLATE_PROXY}/{{lng}}`,
      crossDomain: true
    }
  })

export default i18n
