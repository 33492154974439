import _ from 'lodash/fp'
import { createReducer, createTypes, createActions } from '@fe/redux/reducers/util'

const namespace = 'guidesData'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'SAVE_DISTANCES_REQUEST', // guideId, holeId, distances
  'SAVE_DISTANCES_SUCCESS',
  'SAVE_DISTANCES_ERROR',
  'SAVE_DISTANCES_WAITING'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  items: {},
  saveWaiting: false
}

const reducer = {
  [types.SAVE_DISTANCES_WAITING]: _.set('saveWaiting'),
  [types.LOAD_SUCCESS]: p => _.setWith(Object, `items.${_.get('guide_id', p)}`, p),
  [types.SAVE_DISTANCES_SUCCESS]: p => {
    const holeId = _.get('0.hole_id', p)
    const guideId = _.get('0.guide_id', p)
    if (!holeId || !guideId) {
      console.warn('Data inconsistent while saving distances.')
      return _.identity
    }
    return _.update(`items.${guideId}.holes`, _.map(hole => {
      if (hole.hole_id !== holeId) {
        return hole
      } else {
        return _.set('drawing_data.distances', p, hole)
      }
    }))
  }
}

function createSelectors () {
  const guidesData = _.get(`${namespace}.items`)
  const guideData = guideId => _.flow(
    guidesData,
    _.get(guideId),
    guideData => {
      if (!guideData) {
        return null
      } else {
        return _.update('holes', _.map(_.set('drawing_data.override_image', null)), guideData)
      }
    }
  )
  const saveDistancesWaiting = _.get(`${namespace}.saveWaiting`)
  return {
    guidesData,
    guideData,
    saveDistancesWaiting
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
