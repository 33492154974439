import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'users'

const typeKeys = [
  'GET_USERS',
  'GET_USERS_FAILURE',
  'GET_USERS_SUCCESS',
  'GET_USERS_DONE',

  'CREATE_USER',
  'CREATE_USER_FAILURE',
  'CREATE_USER_SUCCESS',
  'CREATE_USER_DONE',

  'DELETE_USER',
  'DELETE_USER_FAILURE',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_DONE',

  'EDIT_USER_REQUEST',
  'EDIT_USER_SUCCESS',
  'EDIT_USER_FAILURE',

  'UPDATE_USER',
  'UPDATE_USER_SUCCESS',
  'UPDATE_USER_FAILURE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},

  usersRequested: undefined,
  usersFailed: undefined,
  usersSuccess: undefined,

  createUserRequested: undefined,
  createUserFailed: undefined,
  createUserSuccess: undefined,

  deleteUserRequested: undefined,
  deleteUserFailed: undefined,
  deleteUserSuccess: undefined
}

const reducer = {
  [types.GET_USERS]: p => _.flow(
    _.set('usersRequested', Date.now()),
    _.set('usersSuccess', undefined),
    _.set('usersFailed', undefined)
  ),
  [types.GET_USERS_SUCCESS]: p => _.flow(
    _.set('usersSuccess', Date.now()),
    _.set('usersFailed', undefined)
  ),
  [types.GET_USERS_FAILURE]: p => _.flow(
    _.set('usersFailed', Date.now())
  ),
  [types.GET_USERS_DONE]: p => _.set('items', p),

  [types.DELETE_USER]: p => _.flow(
    _.set('deleteUserRequested', Date.now()),
    _.set('deleteUserSuccess', undefined),
    _.set('deleteUserFailed', undefined)
  ),
  [types.DELETE_USER_SUCCESS]: p => _.flow(
    _.set('deleteUserSuccess', Date.now()),
    _.set('deleteUserFailed', undefined)
  ),
  [types.DELETE_USER_FAILURE]: p => _.flow(
    _.set('deleteUserFailed', Date.now())
  ),

  [types.CREATE_USER]: p => _.flow(
    _.set('createUserRequested', Date.now()),
    _.set('createUserSuccess', undefined),
    _.set('createUserFailed', undefined)
  ),
  [types.CREATE_USER_SUCCESS]: p => _.flow(
    _.set('createUserSuccess', Date.now()),
    _.set('createUserFailed', undefined)
  ),
  [types.CREATE_USER_FAILURE]: p => _.flow(
    _.set('createUserFailed', Date.now())
  )
}

function createSelectors () {
  const users = _.flow(
    _.get(`${namespace}.items`),
    _.sortBy('last_name'))
  const usersById = _.flow(
    _.get(`${namespace}.items`),
    _.keyBy('id')
  )

  return {
    users,
    usersById
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
