import _ from 'lodash/fp'
import moment from 'moment'

import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'heatmapFilter'

export const typeKeys = [
  'SET_VALUE',

  'CLEAR_FILTERS',
  'SET_FILTERS'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  filters: {
    male: true,
    female: true,
    home: true,
    guest: true,
    handicap: [0, 72],
    datePlayed: [moment().startOf('year'), moment().endOf('year').startOf('day')],
    timePlayed: [moment().utc().startOf('day'), moment().utc().endOf('day')],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    points: [18, 54],
    shots: [36, 108],
    guide: [],
    shottracked: 0.8,
    includeShots: true,
    includeGametracks: false
  }
}

const reducer = {
  [types.SET_VALUE]: p => _.set(`filters.${p.field}`, p.value),
  [types.SET_FILTERS]: p => _.set(`filters`, p),

  [types.CLEAR_FILTERS]: p => initialState
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const filters = _.get(`${namespace}.filters`)
  const value = field => _.flow(filters, _.get(field))

  return {
    filters,
    value
  }
}

export const selectors = createSelectors()
