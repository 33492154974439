import React, { Component } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import _ from 'lodash/fp'

import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import SnackBarRoot from '../components/SnackBarRoot'
import colors from '@fe/ui/colors'
import images from '../images'

import * as authReducer from '../reducers/auth'
import * as snackMessageReducer from '../reducers/snackMessage'
import { selectors as clubsSelectors } from '../reducers/clubs'
import { selectors as coursesSelectors } from '../reducers/courses'
import { selectors as guidesSelectors } from '../reducers/guides'
import { selectors as regionsSelectors } from '../reducers/regions'

import {
  Business as HomeIcon,
  SupervisorAccount as UsersIcon,
  Menu as MenuIcon,
  AccountBalance as ClubIcon,
  GolfCourse,
  ChromeReaderMode,
  Comment,
  MonetizationOn as PromotionIcon,
  PregnantWoman as AdminIcon,
  Help as SupportIcon,
  Launch as ExternalIcon,
  Email as EmailIcon,
  Assignment as MapperIcon,
  Build as CourseManager,
  ShowChart as StatsIcon,
  Settings as SettingsIcon,
  Close as LogoutIcon
} from '@material-ui/icons'

import HelpButton from '../components/HelpButton'
import LoadingPage from '../components/LoadingPage'

import DrawerLink from '@fe/ui/Drawer/DrawerLink'
import DrawerMenuItemBase from '@fe/ui/Drawer/DrawerMenuItem'

import styles from '@fe/ui/styles/RootLayout'
import { ListItemIcon, ListItemText } from '@material-ui/core'

import { withTranslation } from 'react-i18next'

const translationBase = 'routes'
const getTBase = t => translationKey => t(`${translationBase}.${translationKey}`)

// map from urls to names
const routes = t => ({
  'home': {
    name: t('home.header')
  },
  'clubs': {
    name: t('clubs.header')
  },
  'club': {
    name: t('club.sub.information'),
    role: 'club-info'
  },
  'club/gallery': {
    name: t('club.sub.gallery'),
    role: 'club-gallery'
  },
  'club/hours': {
    name: t('club.sub.opening_hours'),
    role: 'opening-hours'
  },
  'club/club-app': {
    name: 'Club App',
    role: 'club-info'
  },
  'insights': {
    name: t('insights.header'),
    role: 'insights'
  },
  'insights/games': {
    name: t('insights.sub.game_insights'),
    role: 'insights'
  },
  'insights/heatmap': {
    name: t('insights.sub.heatmap'),
    role: 'insights'
  },
  'courses/new': {
    name: t('courses.sub.new')
  },
  'products': {
    name: t('products.header'),
    role: 'products'
  },
  'promotion/sponsors': {
    name: t('promotion.sub.sponsors'),
    role: 'promotion'
  },
  'promotion/sponsor-setups': {
    name: t('promotion.sub.sponsor_setups'),
    role: 'promotion'
  },
  'users': {
    name: t('users.header'),
    role: 'user-management'
  },
  'settings': {
    name: t('settings.header')
  },
  'admin/elements': {
    name: t('admin.sub.elements'),
    role: 'admin'
  },
  'admin/assets': {
    name: 'Assets',
    role: 'admin'
  },
  'admin/queue': {
    name: t('admin.sub.queue_mgmt'),
    role: 'admin'
  },
  'admin/events/jobs': {
    name: 'Jobs',
    role: 'admin'
  },
  'admin/events/glfr-users': {
    name: 'GLFR Users',
    role: 'admin'
  },
  'admin/themes': {
    name: t('admin.sub.themes'),
    role: 'admin'
  },
  'admin/regions': {
    name: t('admin.sub.regions'),
    role: 'admin'
  },
  'admin/mismatches': {
    name: t('admin.sub.mismatches'),
    role: 'admin'
  },
  'admin/atla': {
    name: 'ATLA AI SmartMapping',
    role: 'admin'
  },
  'greenkeeper/areas': {
    name: t('greenkeeper.sub.areas'),
    role: 'areas'
  },
  'greenkeeper/techmap': {
    name: t('greenkeeper.sub.tech_overview'),
    role: 'tech-map'
  },
  'messages': {
    name: 'Messages',
    role: 'communication'
  },
  'some/connections': {
    name: 'Social Media Connections',
    role: 'communication'
  },
  'some/status': {
    name: t('club.sub.status'),
    role: 'club-status'
  },
  'some/feed': {
    name: 'News Feed',
    role: 'club-status'
  },
  'admin/club-info': {
    name: t('admin.sub.club_setup')
  },
  'admin/club-status': {
    name: t('admin.sub.mapping_status')
  },
  'admin/trackmap': {
    name: t('admin.sub.mapping')
  }
})

const DrawerMenuItem = _.compose(
  connect(
    (s, props) => ({
      routeList: props.routeList,
      isAdmin: authReducer.selectors.isAdmin(s),
      roles: authReducer.selectors.activeClubRoles(s)
    })
  )
)(DrawerMenuItemBase)

const RootLoadingScreen = () => (
  <div
    style={{
      backgroundColor: colors.white,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    }}
  >
    <img alt='GLFR Logo' src={'/images/icons/glfr_paleblue.png'} />
  </div>
)

class RootLayout extends Component {
  state = {
    auth: true,
    anchorEl: null,
    menu: null,
    open: true,
    openGroups: {
      undefined
    }
  }

  UNSAFE_componentWillMount() {
    this.props.bootstrap()
  }

  logout = () => {
    this.props.logout()
  }

  handleChange = (event, checked) => {
    this.setState((prevState) => ({ auth: !prevState.auth }))
    this.handleRequestClose()
  }

  handleMenu = (menu) => event => {
    this.setState({ anchorEl: event.currentTarget, menu })
  }

  handleRequestClose = () => {
    this.setState({ anchorEl: null, menu: null })
  }

  handleDrawerOpen = () => {
    this.setState({ open: true })
  }

  handleDrawerClose = () => {
    this.setState({ open: false })
  }

  handleGroupsClick = (group) => () => {
    this.setState((prevState) => ({
      openGroups: {
        [group]: !_.get(`openGroups.${group}`, prevState)
      }
    }))
  }

  isGroupOpen = (group) => {
    return _.get(`openGroups.${group}`, this.state)
  }

  parsePathForTitle = () => {
    const { currentPath, currentClubId, courses, guides, t } = this.props
    const tBase = getTBase(t)
    const staticRouteName = _.get(
      currentPath.replace(RegExp(`/${currentClubId}/(.*)`), '$1') + '.name',
      routes(tBase)
    )

    if (staticRouteName) {
      return (
        <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
          <Typography variant='h6' color='inherit'>
            {staticRouteName}
          </Typography>
          <HelpButton inline inverse keyword={staticRouteName} />
        </div>
      )
    } else if (_.includes('metamap', currentPath)) {
      const parts = _.split('/', currentPath)
      const courseId = _.last(parts)
      return (
        <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
          <Typography variant='h6' color='inherit'>
            {`Map course elements for ${_.getOr('course', `${courseId}.name`, courses)}`}
          </Typography>
        </div>
      )
    }

    const pathObject = _.flow(
      _.split('/'),
      _.chunk(2),
      _.fromPairs
    )(currentPath)

    const coursePath = _.get('courses', pathObject)
    const mismatchPath = (_.get('admin', pathObject) === 'mismatches') && _.flow(_.split('/'), _.last)(currentPath)
    const guidePath = _.get('products', pathObject) || mismatchPath
    if (coursePath) {
      const courseName = _.get(`${coursePath}.name`, courses)
      const holePath = _.get('holes', pathObject)
      if (holePath) {
        const holeOrder = _.flow(
          _.get(`${_.get('courses', pathObject)}.holes`),
          _.find((hole) => _.isEqual(_.parseInt(10, holePath), _.get('id', hole))),
          _.get('order'),
          (order) => order + 1
        )(courses)
        return (
          <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
            <Typography variant='h6' color='inherit'>
              {t('routes.title.course_hole', { courseName: courseName || '', holeOrder: holeOrder || '' })}
            </Typography>
          </div>
        )
      }
      return (
        <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
          <Typography variant='h6' color='inherit'>
            {`${courseName}`}
          </Typography>
          <HelpButton inline inverse href={'https://support.glfr.com/knowledge-base/courses-creating-or-managing-existing-b2b/'} />
        </div>
      )
    } else if (guidePath) {
      const guideName = _.get(`${guidePath}.title`, guides)
      return (
        <div style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', display: 'flex' }}>
          <Typography variant='h6' color='inherit'>
            {`${guideName}`}
          </Typography>
          <HelpButton inline inverse keyword={'New Product'} />
        </div>
      )
    }
    return ''
  }

  renderRoutes = () => {
    const { currentClubId, currentPath, courses, canSeeManagement, t } = this.props
    const tBase = getTBase(t)
    const routeList = routes(tBase)

    const shouldRenderCourses = this.props.isAdmin || _.includes('courses', this.props.roles)

    const infoString = _.reduce((acc, curr) => {
      return `${acc} %0D%0A ${curr}: ${_.get(curr, window.navigator)}`
    }, '', ['vendor', 'appVersion', 'userAgent', 'platform', 'language', 'userLanguage'])

    const mailToObject = {
      subject: 'GLFR Business Support',
      body: `-- Write your question here -- %0D%0A %0D%0A %0D%0A %0D%0A ---------- %0D%0A GLFR business version: ${process.env.REACT_APP_VERSION} %0D%0A current path: ${currentPath} %0D%0A ${infoString}`
    }
    return (
      <React.Fragment>

        <DrawerMenuItem
          name={tBase('home.header')}
          icon={<HomeIcon />}
          routes={['home']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          name={tBase('club.header')}
          icon={<ClubIcon />}
          routes={['club', 'club/club-app', 'club/gallery', 'club/hours']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          name={tBase('courses.header')}
          icon={<GolfCourse />}
          routes={canSeeManagement ? ['courses/new'] : []}
          currentItemId={currentClubId}
          routeList={routeList}
          currentPath={currentPath}>
          {Boolean(shouldRenderCourses) && _.map((course) => {
            const { name, id } = course
            const to = `/${currentClubId}/courses/${id}`
            return (
              <DrawerLink
                key={id}
                name={name}
                to={to}
                active={_.equals(currentPath, to) || _.includes(to, currentPath)} // To keep the item active on /holes/:holeId
              />
            )
          }, courses)}
        </DrawerMenuItem>

        <DrawerMenuItem
          name={tBase('communication.header')}
          icon={<Comment />}
          routes={['some/status', 'some/feed', 'messages', 'some/connections']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          name={tBase('promotion.header')}
          icon={<PromotionIcon />}
          routes={['promotion/sponsors', 'promotion/sponsor-setups']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          icon={<ChromeReaderMode />}
          routes={['products']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          name={tBase('greenkeeper.header')}
          icon={<CourseManager />}
          routes={['greenkeeper/areas', 'greenkeeper/techmap']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          name={tBase('insights.header')}
          icon={<StatsIcon />}
          routes={['insights/games', 'insights/heatmap']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          icon={<UsersIcon />}
          routes={['users']}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        {canSeeManagement &&
          <DrawerMenuItem
            name={tBase('management.header')}
            icon={<MapperIcon />}
            routes={['admin/club-info', 'admin/club-status', 'admin/trackmap']}
            currentItemId={currentClubId}
            currentPath={currentPath}
            routeList={routeList}
          />
        }

        <DrawerMenuItem
          requiresAdmin
          name={tBase('admin.header')}
          icon={<AdminIcon />}
          routes={[
            'admin/elements',
            'admin/themes',
            'admin/regions',
            'admin/assets',
            'admin/events/jobs',
            'admin/events/glfr-users',
            'admin/mismatches',
            'admin/atla'
          ]}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        />

        <DrawerMenuItem
          requiresAdmin
          name={'Support'}
          icon={<SupportIcon />}
          routes={[]}
          currentItemId={currentClubId}
          currentPath={currentPath}
          routeList={routeList}
        >
          <DrawerLink
            name={'Support Center'}
            rightIcon={<ExternalIcon />}
            href={'https://support.glfr.com/categories/glfr-business/'}
            active={false}
          />
          <DrawerLink
            name={'Webinars'}
            rightIcon={<ExternalIcon />}
            href={'http://www.glfr.com/webinars/'}
            active={false}
          />
          <DrawerLink
            name={'Contact Us'}
            rightIcon={<EmailIcon />}
            href={`mailto:support@glfr.com?subject=${_.get('subject', mailToObject)}&body=${_.get('body', mailToObject)}`}
            active={false}
          />
        </DrawerMenuItem>

      </React.Fragment>
    )
  }

  render() {
    if (this.props.notBootstrapped || !this.props.currentPath || !this.props.currentClubId) {
      return (
        <RootLoadingScreen />
      )
    }

    const tBase = getTBase(this.props.t)
    const routeList = routes(tBase)

    const { anchorEl, menu, open } = this.state
    const { classes, children, t, currentClubId, currentPath } = this.props
    const showProfile = Boolean(anchorEl) && (menu === 'showProfile')

    const drawerContents = (
      <React.Fragment>
        <div
          className={classes.drawerHeader}
          style={{
            margin: 6,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: `url(${images.icons.glfrBusinessLogo})`
          }}
        />
        <Divider />
        <List
          className={classes.listRoot}
        >
          {this.renderRoutes()}
        </List>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
          <div style={{
            fontSize: 'smaller',
            color: colors.lightBlue,
            textAlign: 'center'
          }}>
            {process.env.REACT_APP_VERSION}
          </div>

          {_.get('__glfr_business_content_available', window) && (
            <div style={{
              fontSize: 'smaller',
              color: colors.lightBlue,
              textAlign: 'center'
            }}>
              <Button size='small' color='secondary' onClick={e => {
                _.result('location.reload', window)
              }}>{t('button.update_now')}</Button>
            </div>
          )}
        </div>
      </React.Fragment>
    )

    const clubName = _.getOr(t('error.no_club_found'), 'name', this.props.currentClub)
    const largerThanSmall = this.screenSizeLargerThan('sm')
    const truncateName = largerThanSmall ? clubName : _.truncate({ 'length': 10, 'separator': ' ' }, clubName);

    const classnames = largerThanSmall
      ? classNames(classes.content, classes.contentShift)
      : classNames(classes.content, classes.contentShift, classes.contentNoPadding)
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar position='fixed' style={{ backgroundColor: colors.businessBlue, color: colors.white }} className={classNames(classes.appBar, classes.appBarShift)}>
            <Toolbar style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <IconButton
                style={{ color: colors.white }}
                aria-label='open drawer'
                onClick={open ? this.handleDrawerClose : this.handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>

              {this.parsePathForTitle()}

              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <NavLink to={`/${this.props.currentClubId}/clubs`}>
                  <Button classes={{ label: classes.label }}>
                    <Typography variant='h6' style={{ color: colors.white }}>
                      {truncateName}
                    </Typography>
                  </Button>
                </NavLink>

                <IconButton
                  aria-owns={showProfile ? 'menu-appbar' : null}
                  aria-haspopup='true'
                  onClick={this.handleMenu('showProfile')}
                  color='inherit'
                >
                  <Avatar src={this.props.profilePicture} />
                </IconButton>
                <Menu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                  }}
                  open={showProfile}
                  onClose={this.handleRequestClose}
                >
                  <MenuItem onClick={this.logout}>
                    <ListItemIcon><LogoutIcon /></ListItemIcon>
                    <ListItemText>{t('generic.logout')}</ListItemText>
                  </MenuItem>
                  <DrawerMenuItem
                    name={t('settings.header')}
                    icon={<SettingsIcon />}
                    routes={['settings']}
                    currentItemId={currentClubId}
                    currentPath={currentPath}
                    routeList={routeList}
                  />
                </Menu>
              </div>

            </Toolbar>
          </AppBar>

          <Hidden mdUp>
            <Drawer
              variant='temporary'
              anchor={'left'}
              open={open}
              onClose={this.handleDrawerClose}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawerContents}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation='css'>
            <Drawer
              variant='permanent'
              open
            >
              {drawerContents}
            </Drawer>
          </Hidden>

          <div className={classnames} id={'rootDiv'}>
            <React.Suspense fallback={<LoadingPage />}>
              {children}
            </React.Suspense>
          </div>
        </div>
        <SnackBarRoot
          time={this.props.snackMessage.time}
          message={this.props.snackMessage.message}
          autoHideDuration={10000}
        />
      </div>
    )
  }

  // xs: 0
  // sm: 600
  // md: 960
  // lg: 1280
  // xl: 1920
  screenSizeLargerThan(sizeFormat) {
    const screenSize = window.innerWidth
    const sizes = this.props.theme.breakpoints.values
    const askedSize = _.get(sizeFormat, sizes)
    const bigEnough = askedSize < screenSize

    return bigEnough
  }
}

export default _.compose(
  withStyles(styles, { withTheme: true }),
  withTranslation(),
  connect(
    (s) => ({
      notBootstrapped: authReducer.selectors.notBootstrapped(s),
      currentPath: _.get('router.location.pathname', s),
      currentClub: clubsSelectors.getCurrentClub(s),
      currentClubId: clubsSelectors.getActive(s),
      profilePicture: authReducer.selectors.profilePicture(s),
      courses: coursesSelectors.getCourses(s),
      guides: guidesSelectors.items(s),
      snackMessage: snackMessageReducer.selectors.snackMessage(s),
      canSeeManagement: regionsSelectors.canSeeManagement(s),
      isAdmin: authReducer.selectors.isAdmin(s),
      roles: authReducer.selectors.activeClubRoles(s)
    }),
    (d) => ({
      logout: () => d(authReducer.actions.logout()),
      bootstrap: () => d(authReducer.actions.bootstrapRequest())
    })
  )
)(RootLayout)
