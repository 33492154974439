import localStore from 'localforage'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import Analytics from 'react-router-ga'
import { ConnectedRouter } from 'connected-react-router'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import { materialBlues, materialLightBlues, materialReds } from '@fe/ui/colors'
import RootLayout from './containers/RootLayout'
import './index.css' // @Thomas don't let your tools remove this line!
import Login from './pages/Login' // NOTE: Must not be lazy, it is outside RootLayout
import ResetPassword from './pages/ResetPassword' // NOTE: Must not be lazy, it is outside RootLayout

// import registerServiceWorker from './registerServiceWorker'
import { history, store } from './store'

import { ApolloProvider } from 'react-apollo'
import apolloClient from './apollo'

import './i18n'

localStore.config({
  driver: localStore.LOCALSTORAGE,
  name: 'businessGlfr'
})

const theme = createMuiTheme({
  palette: {
    primary: materialBlues,
    secondary: materialLightBlues,
    error: materialReds
  },
  typography: {
    fontFamily: 'Open Sans',
    useNextVariants: true
  }
})

const Landing = React.lazy(() => import('./pages/Landing'))
const AdminElements = React.lazy(() => import('./pages/admin/elements'))
const AdminGeneral = React.lazy(() => import('./pages/admin/general'))
const AdminStatus = React.lazy(() => import('./pages/admin/status'))
const AdminThemes = React.lazy(() => import('./pages/admin/themes'))
const AdminQueue = React.lazy(() => import('./pages/admin/queue'))
const AdminRegions = React.lazy(() => import('./pages/admin/regions'))
const AdminAtla = React.lazy(() => import('./pages/admin/atla'))
const TrackMap = React.lazy(() => import('./pages/admin/trackmap/TrackMapController'))
const Gallery = React.lazy(() => import('./pages/club/Gallery'))
const Information = React.lazy(() => import('./pages/club/information'))
const Hours = React.lazy(() => import('./pages/club/openingHoursAndPrices'))
const Clubs = React.lazy(() => import('./pages/clubs'))
const ClubApp = React.lazy(() => import('./pages/club/clubApp'))
const ClubStatus = React.lazy(() => import('./pages/club/Status'))
const Communication = React.lazy(() => import('./pages/communication'))
const SoMeConncetionsComponent = React.lazy(() => import('./pages/club/information/SoMeConncetionsComponent'))
const NewsFeedComponent = React.lazy(() => import('./pages/club/information/NewsFeedComponent'))
const GamesStatistics = React.lazy(() => import('./pages/statistics/Games.js'))
const Heatmap = React.lazy(() => import('./pages/statistics/Heatmap'))
const Areas = React.lazy(() => import('./pages/coursemanager/Areas'))
const TechMap = React.lazy(() => import('./pages/coursemanager/TechMap'))
const NewCourse = React.lazy(() => import('./pages/courses/NewCourse.js'))
const Course = React.lazy(() => import('./pages/courses/course'))
const Hole = React.lazy(() => import('./pages/courses/course/Hole'))
const HoleEditDrawing = React.lazy(() => import('./pages/courses/course/HoleEditDrawing'))
const HoleEditElevation = React.lazy(() => import('./pages/courses/course/HoleEditElevation'))
const HoleEditLabels = React.lazy(() => import('./pages/courses/course/HoleEditLabels'))
const HoleEditPinChart = React.lazy(() => import('./pages/courses/course/HoleEditPinChart'))
const HoleEditPlayLines = React.lazy(() => import('./pages/courses/course/HoleEditPlayLines'))
const ManageAllHoles = React.lazy(() => import('./pages/courses/course/ManageAllHoles'))
const Jobs = React.lazy(() => import('./pages/events/jobs'))
const GlfrUser = React.lazy(() => import('./pages/events/GlfrUser'))
const FindGlfrUsers = React.lazy(() => import('./pages/events/FindGlfrUsers'))
const Home = React.lazy(() => import('./pages/home'))
const Products = React.lazy(() => import('./pages/products'))
const Guide = React.lazy(() => import('./pages/products/Guide'))
const SetupCourses = React.lazy(() => import('./pages/products/SetupCourses'))
const SetupDistanceMarkers = React.lazy(() => import('./pages/products/SetupDistanceMarkers'))
const SetupRatings = React.lazy(() => import('./pages/products/SetupRatings'))
const SetupTees = React.lazy(() => import('./pages/products/SetupTees'))
const BackPage = React.lazy(() => import('./pages/products/pageSetups/BackPage'))
const Custom = React.lazy(() => import('./pages/products/pageSetups/Custom'))
const FrontPage = React.lazy(() => import('./pages/products/pageSetups/FrontPage'))
const Holes = React.lazy(() => import('./pages/products/pageSetups/Holes'))
const LocalRules = React.lazy(() => import('./pages/products/pageSetups/LocalRules'))
const Welcome = React.lazy(() => import('./pages/products/pageSetups/Welcome'))
const SponsorSetups = React.lazy(() => import('./pages/promotion/SponsorSetups'))
const Sponsors = React.lazy(() => import('./pages/promotion/Sponsors'))
const CreateEditSponsorSetup = React.lazy(() => import('./pages/promotion/createEditSponsorSetup'))
const Users = React.lazy(() => import('./pages/users'))
const PinPositions = React.lazy(() => import('./pages/club/pinPositions'))
const HeightProfiles = React.lazy(() => import('./pages/club/heightProfiles'))
const PrintablePinPositions = React.lazy(() => import('./pages/club/pinPositions/Printable'))
const CreateAssets = React.lazy(() => import('./pages/admin/assets'))
const Settings = React.lazy(() => import('./pages/users/Settings'))
const Mismatches = React.lazy(() => import('./pages/admin/mismatches'))
const MetaMap = React.lazy(() => import('./pages/metamap'))

const Routes = () => (
  <Switch>
    <Route exact path='/signin' component={Login} name='Basic Login' />
    <Route exact path='/reset_password/:token/:email' component={ResetPassword} name='Reset Password' />

    <RootLayout>
      <ApolloProvider client={apolloClient}>
        <Route exact path='/:clubId/clubs' component={Clubs} name='Clubs' />
        <Route exact path='/:clubId/admin/club-info' component={AdminGeneral} name='Club Info' />
        <Route exact path='/:clubId/admin/elements' component={AdminElements} name='Elements' />
        <Route exact path='/:clubId/admin/club-status' component={AdminStatus} name='Club Status' />
        <Route exact path='/:clubId/admin/themes' component={AdminThemes} name='Themes' />
        <Route exact path='/:clubId/admin/queue' component={AdminQueue} name='Queue MGMT' />
        <Route exact path='/:clubId/admin/regions' component={AdminRegions} name='Regions' />
        <Route exact path='/:clubId/admin/trackmap' component={TrackMap} name='Mapping' />
        <Route exact path='/:clubId/admin/atla' component={AdminAtla} name='Atla Mapping' />

        <Route exact path='/:clubId/home' component={Home} name='Home' />

        <Route exact path='/:clubId/club' component={Information} name='Information' />
        <Route exact path='/:clubId/club/gallery' component={Gallery} name='Gallery' />
        <Route exact path='/:clubId/club/hours' component={Hours} name='Hours' />
        <Route exact path='/:clubId/club/club-app' component={ClubApp} name='Club App' />

        <Route exact path='/:clubId/insights/games' component={GamesStatistics} name='Games Statistics' />
        <Route exact path='/:clubId/insights/heatmap' component={Heatmap} name='Heatmap' />

        <Route exact path='/:clubId/courses/new' component={NewCourse} name='New Course' />
        <Route exact path='/:clubId/courses/:courseId' component={Course} name='Course' />
        <Route exact path='/:clubId/courses/:courseId/pin-positions' component={PinPositions} name='Pin Positions' />
        <Route exact path='/:clubId/courses/:courseId/height-profiles' component={HeightProfiles} name='Height Profiles' />
        <Route exact path='/:clubId/courses/:courseId/pin-positions/:courseId/:setupId/print' component={PrintablePinPositions} name='Print Positions' />
        <Route exact path='/:clubId/courses/:courseId/manage-all-holes' component={ManageAllHoles} name='Manage All Holes' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId' component={Hole} name='Hole' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId/edit' component={HoleEditDrawing} name='Hole Edit Drawing' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId/edit-pin-chart' component={HoleEditPinChart} name='Edit Pin Position Chart' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId/edit-elevation' component={HoleEditElevation} name='Edit Elevation' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId/edit-play-lines' component={HoleEditPlayLines} name='Edit Play Lines' />
        <Route exact path='/:clubId/courses/:courseId/holes/:holeId/edit-labels' component={HoleEditLabels} name='Edit Labels' />

        <Route exact path='/:clubId/products' component={Products} name='Products' />
        <Route exact path='/:clubId/products/:guideId' component={Guide} name='Guides' />
        <Route exact path='/:clubId/products/:guideId/setup-courses' component={SetupCourses} name='Setup Courses' />
        <Route exact path='/:clubId/products/:guideId/setup-ratings' component={SetupRatings} name='Setup Ratings' />
        <Route exact path='/:clubId/products/:guideId/setup-tees' component={SetupTees} name='Setup Tees' />
        <Route exact path='/:clubId/products/:guideId/setup-distance-markers' component={SetupDistanceMarkers} name='Setup Distance Markers' />
        <Route exact path='/:clubId/products/:guideId/frontpage' component={FrontPage} name='Front Page' />
        <Route exact path='/:clubId/products/:guideId/backpage' component={BackPage} name='Back Page' />
        <Route exact path='/:clubId/products/:guideId/welcome' component={Welcome} name='Welcome' />
        <Route exact path='/:clubId/products/:guideId/local-rules' component={LocalRules} name='Local Rules' />
        <Route exact path='/:clubId/products/:guideId/holes' component={Holes} name='Holes' />
        <Route exact path='/:clubId/products/:guideId/custom/:type' component={Custom} name='Custom' />

        <Route exact path='/:clubId/promotion/sponsors' component={Sponsors} name='Sponsors' />
        <Route exact path='/:clubId/promotion/sponsor-setups' component={SponsorSetups} name='Sponsor Setups' />
        <Route exact path='/:clubId/promotion/sponsor-setups/:courseId/new' component={CreateEditSponsorSetup} name='Sponsor Setup' />
        <Route exact path='/:clubId/promotion/sponsor-setups/:courseId/:setupId' component={CreateEditSponsorSetup} name='Sponsor Setup' />

        <Route exact path='/:clubId/users' component={Users} name='Users' />
        <Route exact path='/:clubId/settings' component={Settings} name='Settings' />

        <Route exact path='/:clubId/greenkeeper/techmap' component={TechMap} name='Tech' />
        <Route exact path='/:clubId/greenkeeper/areas' component={Areas} name='Areas' />
        <Route exact path='/:clubId/messages' component={Communication} name='Messages' />
        <Route exact path='/:clubId/some/connections' component={SoMeConncetionsComponent} name='Social Media Connections' />
        <Route exact path='/:clubId/some/feed' component={NewsFeedComponent} name='News Feed' />
        <Route exact path='/:clubId/some/status' component={ClubStatus} name='Status' />

        <Route exact path='/:clubId/admin/assets' component={CreateAssets} name='Assets' />
        <Route exact path='/:clubId/admin/events/jobs' component={Jobs} name='Jobs' />
        <Route exact path='/:clubId/admin/events/glfr-users/:userId' component={GlfrUser} name='GLFR User' />
        <Route exact path='/:clubId/admin/events/glfr-users' component={FindGlfrUsers} name='GLFR Users' />

        <Route exact path='/:clubId/admin/mismatches/:guideId?' component={Mismatches} name='Mismatches' />
        <Route exact path='/:clubId/metamap/:courseId?' component={MetaMap} name='Metamapping' />
        <Route exact path='/:clubId/meatmap/:courseId?' component={MetaMap} name='Metamapping' />

        <Route exact path='/' component={Landing} name='Landing' />

      </ApolloProvider>
    </RootLayout>
  </Switch>
)

const EvenEmptierHusk = () => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <ThemeProvider theme={theme} >
      <Provider store={store}>
        <div>
          <ConnectedRouter history={history}>
            <Analytics id='UA-32172310-7'>
              <Routes />
            </Analytics>
          </ConnectedRouter>
        </div>
      </Provider>
    </ThemeProvider>
  </MuiPickersUtilsProvider>
)

// render app to root element
ReactDOM.render(<EvenEmptierHusk />, document.getElementById('root'))

// register service worker
// registerServiceWorker()
