import _ from 'lodash/fp'
import { createSelector } from 'reselect'

import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'clubsList'

export const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},
  loading: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p =>
    _.flow(
      _.set('loadRequestWaiting', true),
      _.set('loadError', null)
    ),
  [types.LOAD_SUCCESS]: p =>
    _.flow(
      _.set('loadRequestWaiting', false),
      _.set('loadedAt', Date.now()),
      _.update('items', (items) => {
        const clubs = _.flow(
          _.keyBy('id')
        )(p)
        return {
          ...items,
          ...clubs
        }
      })
    ),
  [types.LOAD_ERROR]: p =>
    _.set('loadError', _.get('error', p))
}

export default createReducer(initialState, reducer, namespace, { whitelist: ['items', 'loadedAt'] })

function createSelectors () {
  const getActive = _.flow(
    _.get('router.location.pathname'),
    _.split('/'),
    _.get(1)
  )
  const getClubsList = _.get(`${namespace}.items`)
  const getLoadedAt = _.get(`${namespace}.loadedAt`)
  const getCurrentClub = createSelector(
    getActive,
    getClubsList,
    _.get
  )

  return {
    getActive,
    getClubsList,
    getLoadedAt,
    getCurrentClub
  }
}

export const selectors = createSelectors()
