import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { types as teesTypes, actions as teesActions } from '../reducers/tees'

import { getEffect, postEffect, putEffect, deleteEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadTeesEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'tees'))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * createTeeEffect (body) {
  const request = {
    url: (yield call(prependClubsUrl, `tees`)),
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * saveTeesEffect (payload) {
  const body = _.flow(
    _.omit(['created_at', 'updated_at'])
  )(payload)

  const request = {
    url: (yield call(prependClubsUrl, `tees/${_.get('id', body)}`)),
    options: {
      body
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * deleteTeeEffect (teeId) {
  const request = {
    url: (yield call(prependClubsUrl, `tees/${teeId}`))
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadTeesFlow ({ payload }) {
  const tees = yield call(loadTeesEffect, payload)
  if (tees.error) {
    yield put(teesActions.loadError(tees))
  } else {
    yield put(teesActions.loadSuccess(tees))
  }
  yield put(teesActions.loadRequestDone())
}

export function * createTeesFlow ({ payload }) {
  const tees = yield call(createTeeEffect, payload)
  if (tees.error) {
    yield put(teesActions.createError(tees))
  } else {
    yield put(teesActions.createSuccess(tees))
  }
  yield put(teesActions.loadRequest())
  yield put(teesActions.createDone())
}

export function * saveTeesFlow ({ payload }) {
  const tees = yield call(saveTeesEffect, payload)
  if (tees.error) {
    yield put(teesActions.saveError(tees))
  } else {
    yield put(teesActions.saveSuccess(tees))
  }
  yield put(teesActions.saveDone())
}

export function * deleteTeesFlow ({ payload: teeId }) {
  const tees = yield call(deleteTeeEffect, teeId)
  if (tees.error) {
    yield put(teesActions.deleteError(tees))
  } else {
    yield put(teesActions.deleteSuccess(teeId))
  }
  yield put(teesActions.deleteDone())
}

export default function * () {
  return yield all([
    takeLatest(teesTypes.LOAD_REQUEST, loadTeesFlow),
    takeLatest(teesTypes.CREATE_REQUEST, createTeesFlow),
    takeLatest(teesTypes.SAVE_REQUEST, saveTeesFlow),
    takeLatest(teesTypes.DELETE_REQUEST, deleteTeesFlow)
  ])
}
