import _ from 'lodash/fp'
import { all, call, put, take, takeEvery, takeLatest } from 'redux-saga/effects'

import { types as graphicsTypes, actions as graphicsActions } from '../reducers/graphics'
import { getEffect, postEffect, getCurrentClubId, requestBuilder } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * getAllGraphics () {
  const url = `graphics`
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * getGraphicsThemes () {
  const url = `graphics/themes`
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsAttributes () {
  const url = `graphics/attributes`
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsThemeAttributes () {
  const url = 'graphics/theme-attributes'
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsAttributeUnits () {
  const url = 'graphics/attribute-units'
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsElements () {
  const url = 'graphics/elements'
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsGroups () {
  const url = 'graphics/groups'
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsLayers () {
  const url = 'graphics/layers'
  return yield requestBuilder({ url }, getEffect)
}

export function * getGraphicsAttributeGraphics () {
  const url = 'graphics/attribute-graphics'
  return yield requestBuilder({ url }, getEffect)
}

export function * newElementEffect ({ body }) {
  const url = `graphics/elements`
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * newAttributeEffect ({ body }) {
  const url = `graphics/attributes`
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * loadContextDataEffect () {
  const clubId = yield getCurrentClubId()
  const url = `graphics/contexts/${clubId}`
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * graphicsParser (graphics) {
  if (_.get('error', graphics)) {
    return yield put(graphicsActions.getGraphicsFailure())
  }
  const themes = _.flow(
    _.get('themes'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(themes)) {
    yield put(graphicsActions.setThemes(themes))
  }

  const groups = _.flow(
    _.get('groups'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(groups)) {
    yield put(graphicsActions.setGroups(groups))
  }

  const layers = _.flow(
    _.get('layers'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(layers)) {
    yield put(graphicsActions.setLayers(layers))
  }

  const elements = _.flow(
    _.get('elements'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(elements)) {
    yield put(graphicsActions.setElements(elements))
  }

  const attributes = _.flow(
    _.get('attributes'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(attributes)) {
    yield put(graphicsActions.setAttributes(attributes))
  }

  const attributeUnits = _.flow(
    _.get('attributeUnits'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(attributeUnits)) {
    yield put(graphicsActions.setAttributeUnits(attributeUnits))
  }

  const themeAttributes = _.flow(
    _.get('themeAttributes'),
    _.groupBy('themeName')
  )(graphics)
  if (!_.isEmpty(themeAttributes)) {
    yield put(graphicsActions.setThemeAttributes(themeAttributes))
  }

  const geometryTypes = _.flow(
    _.get('geometryTypes')
  )(graphics)
  if (!_.isEmpty(geometryTypes)) {
    yield put(graphicsActions.setGeometryTypes(geometryTypes))
  }

  const graphicsAttributes = _.flow(
    _.get('attributeGraphics'),
    _.groupBy('elementName')
  )(graphics)
  if (!_.isEmpty(graphicsAttributes)) {
    yield put(graphicsActions.setGraphicsAttributes(graphicsAttributes))
  }

  const graphicsMap = _.flow(
    _.get('graphics'),
    _.keyBy('name')
  )(graphics)
  if (!_.isEmpty(graphicsMap)) {
    yield put(graphicsActions.setGraphics(graphicsMap))
  }
}

function * loadGraphicsFlow () {
  const graphics = yield call(getAllGraphics)
  return yield call(graphicsParser, graphics)
}

function * loadGraphicsThemesFlow () {
  const graphics = yield call(getGraphicsThemes)
  return yield call(graphicsParser, { themes: graphics })
}

function * loadGraphicsAttributesFlow () {
  const graphics = yield call(getGraphicsAttributes)
  return yield call(graphicsParser, { attributes: graphics })
}

function * loadGraphicsThemeAttributesFlow () {
  const graphics = yield call(getGraphicsThemeAttributes)
  return yield call(graphicsParser, { themeAttributes: graphics })
}

function * loadGraphicsAttributeUnitsFlow () {
  const graphics = yield call(getGraphicsAttributeUnits)
  return yield call(graphicsParser, { attributeUnits: graphics })
}

function * loadGraphicsElementsFlow () {
  const graphics = yield call(getGraphicsElements)
  return yield call(graphicsParser, { elements: graphics })
}

function * loadGraphicsGroupsFlow () {
  const graphics = yield call(getGraphicsGroups)
  return yield call(graphicsParser, { groups: graphics })
}

function * loadGraphicsLayersFlow () {
  const layers = yield call(getGraphicsLayers)
  return yield call(graphicsParser, { layers })
}

function * loadGraphicsAttributeGraphicsFlow () {
  const graphics = yield call(getGraphicsAttributeGraphics)
  return yield call(graphicsParser, { attributeGraphics: graphics })
}

export function * newElementFlow ({ payload }) {
  const { name, groupName, layerName, geometryType, shorthand } = payload
  const body = { name, groupName, layerName, geometryType, shorthand }
  const { error } = yield newElementEffect({ body })
  if (error) {
    console.warn('couldnt even', error)
    return yield put(graphicsActions.getGraphicsFailure())
  }
  return yield call(loadGraphicsFlow)
}

export function * newAttributeFlow ({ payload }) {
  const { error } = yield newAttributeEffect({ body: payload })
  if (error) {
    console.warn('couldnt even', error)
    return yield put(graphicsActions.getGraphicsFailure())
  }
  return yield call(loadGraphicsFlow)
}

export function * connectAttributeFlow ({ payload }) {
  const result = yield newAttributeEffect({ body: payload })
  if (result.error) {
    console.warn('couldnt even', result.error)
    yield put(graphicsActions.connectAttributeFail(payload))
  }
  yield call(loadGraphicsThemeAttributesFlow)
  return yield put(graphicsActions.connectAttributeSuccess(payload))
}

export function * loadContextDataFlow () {
  const result = yield loadContextDataEffect()
  if (result.error) {
    return yield put(graphicsActions.loadContextDataFailure())
  }
  return yield put(graphicsActions.loadContextDataSuccess(_.mapValues(_.keyBy('id'), result)))
}

export default function * () {
  return yield all([
    takeLatest(graphicsTypes.GET_GRAPHICS, loadGraphicsFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_THEMES, loadGraphicsThemesFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_THEME_ATTRIBUTES, loadGraphicsThemeAttributesFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_ATTRIBUTE_UNITS, loadGraphicsAttributeUnitsFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_ELEMENTS, loadGraphicsElementsFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_GROUPS, loadGraphicsGroupsFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_LAYERS, loadGraphicsLayersFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_ATTRIBUTE_GRAPHICS, loadGraphicsAttributeGraphicsFlow),
    takeLatest(graphicsTypes.GET_GRAPHICS_ATTRIBUTES, loadGraphicsAttributesFlow),
    takeEvery(graphicsTypes.NEW_ELEMENT, newElementFlow),
    takeEvery(graphicsTypes.NEW_ATTRIBUTE, newAttributeFlow),
    takeEvery(graphicsTypes.CONNECT_ATTRIBUTE, connectAttributeFlow),
    takeLatest(graphicsTypes.LOAD_CONTEXT_DATA, loadContextDataFlow)
  ])
}
