import _ from 'lodash/fp'
import * as effects from 'redux-saga/effects'

import * as pinPositionsReducer from '../reducers/pinPositions'

import { getEffect, postEffect, prependClubsUrl } from './network'

import * as snack from './snackMessage'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadPinPositionsEffect (pinSetupId) {
  const request = {
    url: (yield effects.call(prependClubsUrl, `pin-setups/${pinSetupId}`))
  }
  const chan = yield effects.call(getEffect, request)
  return yield effects.take(chan)
}

function * upsertPinPositionEffect (pinPosition) {
  const request = {
    url: (yield effects.call(prependClubsUrl, `pin-setups/${_.get('pinSetupId', pinPosition)}`)),
    options: {
      body: _.flow(
        _.update('outMeters', _.parseInt(10)),
        _.update('sideMeters', _.parseInt(10))
      )(pinPosition)
    }
  }
  const chan = yield effects.call(postEffect, request)
  return yield effects.take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadPinPositionsFlow ({ payload }) {
  const result = yield effects.call(loadPinPositionsEffect, payload)
  if (result.error) {
    yield effects.put(pinPositionsReducer.actions.loadError(result.error))
    yield snack.genericError(result.error)
  } else {
    yield effects.put(pinPositionsReducer.actions.loadSuccess(result))
  }
}

const unkeyBy = key => _.flow(
  _.toPairs,
  _.map(([v0, v1]) => _.set(key, v0, v1))
)

const ungroupBy = key => _.flow(
  _.toPairs,
  _.map(([v0, v1]) => _.map(v => _.set(key, v0, v), v1)),
  _.flatten
)

const preparePinPositions = _.flow(
  _.mapValues(unkeyBy('holeId')),
  ungroupBy('pinSetupId'),
  _.map(p => ({
    outMeters: p.in,
    sideMeters: p.side,
    position: `SRID=4326;POINT(${_.get('longitude', p.position)} ${_.get('latitude', p.position)})`,
    pinSetupId: parseInt(p.pinSetupId),
    holeId: parseInt(p.holeId)
  }))
)

function * upsertPinPositionsFlow ({ payload: pinChanges }) {
  const pinPositions = preparePinPositions(pinChanges)
  const results = yield effects.all(_.map(pp => effects.call(upsertPinPositionEffect, pp), pinPositions))
  if (_.isEmpty(results)) {
    yield effects.put(pinPositionsReducer.actions.upsertError(new Error('Adding pin positions went wrong')))
    yield snack.genericError('Adding pin positions went wrong')
  } else {
    yield effects.put(pinPositionsReducer.actions.upsertSuccess(pinPositions))
  }
}

export default function * () {
  return yield effects.all([
    effects.takeEvery(pinPositionsReducer.types.LOAD_REQUEST, loadPinPositionsFlow),
    effects.takeLatest(pinPositionsReducer.types.UPSERT_REQUEST, upsertPinPositionsFlow)
  ])
}
