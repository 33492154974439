import _ from 'lodash/fp'
import * as effects from 'redux-saga/effects'

import * as pinPositionsReducer from '../reducers/pinPositions'
import * as pinSetupsReducer from '../reducers/pinSetups'

import { getEffect, postEffect, deleteEffect, prependClubsUrl, getCurrentClubId } from './network'

import * as snack from './snackMessage'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

function * loadPinSetupsEffect () {
  const request = {
    url: (yield effects.call(prependClubsUrl, `pin-setups`))
  }
  const chan = yield effects.call(getEffect, request)
  return yield effects.take(chan)
}

function * upsertPinSetupEffect (pinSetup) {
  const clubId = parseInt(yield effects.call(getCurrentClubId))
  const request = {
    url: (yield effects.call(prependClubsUrl, 'pin-setups')),
    options: {
      body: _.flow(
        _.set('clubId', clubId),
        _.update('id', v => v || null) // make sure key exists
      )(pinSetup)
    }
  }
  const chan = yield effects.call(postEffect, request)
  return yield effects.take(chan)
}

function * deletePinSetupsEffect (pinSetupId) {
  const request = {
    url: (yield effects.call(prependClubsUrl, `pin-setups/${pinSetupId}`))
  }
  const chan = yield effects.call(deleteEffect, request)
  return yield effects.take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * loadPinSetupsFlow () {
  const result = yield effects.call(loadPinSetupsEffect)
  if (result.error) {
    yield effects.put(pinSetupsReducer.actions.loadError(result.error))
    yield snack.genericError(result.error)
  } else {
    yield effects.all(_.map(p => effects.put(pinPositionsReducer.actions.loadRequest(p.id)), result))
    yield effects.put(pinSetupsReducer.actions.loadSuccess(result))
  }
}

function * upsertPinSetupFlow ({ payload }) {
  const result = yield effects.call(upsertPinSetupEffect, payload)
  if (result.error) {
    yield effects.put(pinSetupsReducer.actions.upsertError(result.error))
    yield snack.genericError(result.error)
  } else {
    yield effects.put(pinSetupsReducer.actions.loadRequest())
    yield effects.put(pinSetupsReducer.actions.upsertSuccess(payload))
  }
}

function * deletePinSetupFlow ({ payload }) {
  const result = yield effects.call(deletePinSetupsEffect, payload)
  if (result.error) {
    yield effects.put(pinSetupsReducer.actions.deleteError(result.error))
    yield snack.genericError(result.error)
  } else {
    yield effects.put(pinSetupsReducer.actions.loadRequest())
    yield effects.put(pinSetupsReducer.actions.deleteSuccess(payload))
  }
}

export default function * () {
  return yield effects.all([
    effects.takeLatest(pinSetupsReducer.types.LOAD_REQUEST, loadPinSetupsFlow),
    effects.takeLatest(pinSetupsReducer.types.UPSERT_REQUEST, upsertPinSetupFlow),
    effects.takeLatest(pinSetupsReducer.types.DELETE_REQUEST, deletePinSetupFlow)
  ])
}
