import _ from 'lodash/fp'

import { assignLeft, createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'filter'

export const typeKeys = [
  'SET_FILTER',
  'CLEAR_FILTER',

  'SET_IDS'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  filter: {},
  trackingGroupIds: {}
}

const reducer = {
  [types.SET_FILTER]: p => _.update('filter', assignLeft(p)),
  [types.CLEAR_FILTER]: p => _.set('filter', {}),
  [types.SET_IDS]: p => _.set('trackingGroupIds', p)
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const filter = _.get(`${namespace}.filter`)
  const trackingGroupIds = _.get(`${namespace}.trackingGroupIds`)

  return {
    filter,
    trackingGroupIds
  }
}

export const selectors = createSelectors()
