import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/clubsList'

import { getEffect } from './network'

import * as snack from './snackMessage'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadClubsEffect () {
  const request = {
    url: `clubs-list`
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * takeLatest(types.LOAD_REQUEST, loadClubsFlow)
 */
export function * loadClubsListFlow ({ payload }) {
  const clubsList = yield call(loadClubsEffect, payload)
  if (clubsList.error) {
    yield put(actions.loadError(clubsList))
    yield snack.genericError(clubsList.error)
  } else {
    yield put(actions.loadSuccess(clubsList))
  }
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadClubsListFlow)
  ])
}
