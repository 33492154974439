import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

export const updateModes = {
  EDIT: 'edit',
  CREATE: 'create',
  UNKNOWN: 'unknown'
}

const namespace = 'courses'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'CREATE',
  'CREATE_DONE',
  'CREATE_SUCCESS',
  'CREATE_ERROR',

  'SAVE',
  'SAVE_DONE',
  'SAVE_SUCCESS',
  'SAVE_ERROR',

  'DELETE',
  'DELETE_DONE',
  'DELETE_SUCCESS',
  'DELETE_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},

  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,

  createRequestWaiting: false,
  createError: null,
  createdAt: null,

  saveRequestWaiting: false,
  saveError: null,
  savedAt: null,

  deleteRequestWaiting: false,
  deleteError: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => _.flow(
    _.set('loadRequestWaiting', true),
    _.set('items', {})
  ),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('loadedAt', Date.now()),
    _.set('items', _.keyBy('id', p))
  ),
  [types.LOAD_ERROR]: p => _.set('loadError', p.error),

  [types.CREATE]: p => _.set('createRequestWaiting', true),
  [types.CREATE_DONE]: p => _.set('createRequestWaiting', false),
  [types.CREATE_SUCCESS]: p => _.flow(_.set('createdAt', Date.now()), _.set('createError', null)),
  [types.CREATE_ERROR]: p => _.set('createError', p.error),

  [types.SAVE]: p => _.set('saveRequestWaiting', true),
  [types.SAVE_DONE]: p => _.set('saveRequestWaiting', false),
  [types.SAVE_SUCCESS]: p => _.flow(_.set('savedAt', Date.now()), _.set('saveError', null)),
  [types.SAVE_ERROR]: p => _.set('saveError', p.error),

  [types.DELETE]: p => _.set('deleteRequestWaiting', true),
  [types.DELETE_DONE]: p => _.set('deleteRequestWaiting', false),
  [types.DELETE_SUCCESS]: courseId => _.update('items', _.omit(`${courseId}`)),
  [types.DELETE_ERROR]: p => _.set('deleteError', p.error)
}

function createSelectors () {
  const getCourses = _.get(`${namespace}.items`)
  const getCoursesOrdered = _.flow(
    getCourses,
    _.sortBy('name'),
    _.map(_.update('holes', _.sortBy('order')))
  )
  const getCourse = (courseId) => _.get(`${namespace}.items.${courseId}`)
  const saving = _.get(`${namespace}.saveRequestWaiting`)

  const getHolesByCourse = _.flow(
    getCourses,
    _.map((course) => {
      return {
        name: _.get('name', course),
        holes: _.flow(_.get('holes'), _.sortBy('order'))(course)
      }
    }),
    _.sortBy('name')
  )

  // holeId (String) -> courseId (Integer)
  const getCoursesByHole = _.flow(
    getCourses,
    _.flatMap('holes'),
    _.keyBy('id'),
    _.mapValues('course_id')
  )

  const getCourseIdByHoleId = holeId => _.flow(
    getCoursesByHole,
    _.get(_.parseInt(10, holeId))
  )

  return {
    getCourses,
    getCoursesOrdered,
    getCourse,
    saving,
    getHolesByCourse,
    getCoursesByHole,
    getCourseIdByHoleId
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
