import React, { Component } from 'react'
import _ from 'lodash/fp'
import { connect } from 'react-redux'

import { actions as authActions, selectors as authSelectors } from '../reducers/auth'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { withTranslation } from 'react-i18next'

import colors from '@fe/ui/colors'
import SnackWhen from '../components/SnackWhen'

const styles = theme => {
  return ({
    container: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ececec'
    },
    infoBox: {
      backgroundColor: colors.pieNormalBlue
    }
  })
}

class Login extends Component {
  state = {
    sent: false,
    email: '',
    password: '',
    reset: false
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  showReset = () => {
    this.setState((prevState) => ({
      reset: !prevState.reset
    }))
  }

  initPasswordReset = (e) => {
    e.preventDefault()
    const { email } = this.state
    this.props.initPasswordReset(_.toLower(email))
    this.setState({
      sent: true,
      reset: false,
      password: ''
    })
  }

  login = (e) => {
    e.preventDefault()
    const { email, password } = this.state
    this.props.login(
      _.toLower(email),
      password
    )
  }

  render () {
    const { classes, t } = this.props
    return (
      <div className={classes.container}>
        <Card style={{ width: 512 }}>
          <CardHeader title={t('login.header', 'Login')} />
          <CardContent>
            <form onSubmit={this.state.reset ? this.initPasswordReset : this.login}>

              <Typography variant='body2'>{this.state.reset
                ? t('login.helpertext.login', 'Enter your email to receive a reset password email')
                : t('login.helpertext.password_reset', 'Enter email and password to login')
              }</Typography>

              {this.state.sent && (
                <SnackbarContent
                  className={classes.infoBox}
                  onClick={() => { this.setState({ sent: false }) }}
                  message={
                    t('login.snack.reset_send', 'You have been sent an e-mail with steps to reset your password')
                  }
                />
              )}

              <TextField
                id='email'
                label={t('generic.keywords.email')}
                margin='normal'
                value={this.state.email}
                onChange={this.handleChange('email')}
                fullWidth
              />

              <TextField
                disabled={this.state.reset}
                id='password'
                label={t('login.form.password', 'Password')}
                type='password'
                autoComplete='current-password'
                margin='normal'
                value={this.state.password}
                onChange={this.handleChange('password')}
                fullWidth
                helperText={!this.state.reset && this.props.loginError ? t('login.error.login', 'Could not login. Check your email and password then try again.') : false}
              />
              <Button style={{ marginTop: 60 }} fullWidth type='submit' variant='contained' color='primary'>
                {this.state.reset ? t('login.button.reset_password', 'Reset Password') : t('login.button.login', 'Login')}
              </Button>

            </form>
            <Button style={{ marginTop: 20 }} fullWidth type='submit' variant='contained' color='secondary' onClick={this.showReset}>
	      <Typography style={{ color: colors.white }} variant='button'>
		{
		  this.state.reset
		    ? t('login.button.remebered', 'Wait, I remember now')
		    : t('login.button.forgotten', 'I Forgot My Password')
		}
	      </Typography>
            </Button>
          </CardContent>

        </Card>

        <SnackWhen selector={authSelectors.loginRequestWaiting} type='loading'>{t('login.snack.signing_in', 'Signing in..')}</SnackWhen>
        <SnackWhen selector={authSelectors.loginError} type='error'>{t('login.snack.could_not_login', 'Could not log in')}</SnackWhen>

        <SnackWhen selector={authSelectors.resetSuccess} type={'confirmation'}>{t('login.snack.password_reset')}</SnackWhen>

      </div>
    )
  }
}

export default _.compose(
  withTranslation(),
  withStyles(styles, { withTheme: true }),
  connect(
    (s) => ({
      loginError: authSelectors.loginError(s)
    }),
    (d) => ({
      login: (email, password) => d(authActions.loginRequest({ email, password })),
      initPasswordReset: (email) => d(authActions.initPasswordReset(email))
    })
  )
)(Login)
