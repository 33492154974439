import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'jobs'

const typeKeys = [
  'GET_JOBS',
  'GET_JOBS_SUCCESS',
  'GET_JOBS_FAILURE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  items: {
    // elementName -> {}
  },
  error: undefined
}

const reducer = {
  // I know the cardinality is a bit high here
  [types.GET_JOBS_SUCCESS]: result => _.set(
    'items',
    _.flow(
      _.groupBy('clubId'),
      _.mapValues(_.groupBy('guideId')),
      _.mapValues(_.mapValues(_.groupBy('requestId')))
    )(result)),
  [types.GET_JOBS_FAILURE]: _.set('error')
}

function createSelectors () {
  const jobs = _.get(`${namespace}.items`)

  return {
    jobs
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
