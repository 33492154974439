import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

const style = {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  flex: 1
}

export default class LoadingSpinner extends React.PureComponent {
  render () {
    return (
      <div style={style}>
        <CircularProgress
          color='secondary'
          thickness={8} />
      </div>
    )
  }
}
