import _ from 'lodash/fp'
import { createActions, createReducer, createTypes, assignLeft } from '@fe/redux/reducers/util'

export const namespace = 'trackingGroupsMetaData'

const typeKeys = [
  'LOAD_COMMENTS',
  'LOAD_COMMENTS_SUCCESS',
  'LOAD_COMMENTS_ERROR',

  'CREATE_COMMENT',
  'CREATE_COMMENT_SUCCESS',
  'CREATE_COMMENT_ERROR',

  'UPDATE_COMMENT',
  'UPDATE_COMMENT_SUCCESS',
  'UPDATE_COMMENT_ERROR',

  'DELETE_COMMENT',
  'DELETE_COMMENT_SUCCESS',
  'DELETE_COMMENT_ERROR',

  'LOAD_DESCRIPTIONS',
  'LOAD_DESCRIPTIONS_SUCCESS',
  'LOAD_DESCRIPTIONS_ERROR',

  'CREATE_DESCRIPTION',
  'CREATE_DESCRIPTION_SUCCESS',
  'CREATE_DESCRIPTION_ERROR',

  'UPDATE_DESCRIPTION',
  'UPDATE_DESCRIPTION_SUCCESS',
  'UPDATE_DESCRIPTION_ERROR',

  'DELETE_DESCRIPTION',
  'DELETE_DESCRIPTION_SUCCESS',
  'DELETE_DESCRIPTION_ERROR',

  'UPLOAD_COMMENT_IMAGE',
  'UPLOAD_COMMENT_IMAGE_SUCCESS',
  'UPLOAD_COMMENT_IMAGE_ERROR',

  'DELETE_COMMENT_IMAGE',
  'DELETE_COMMENT_IMAGE_SUCCESS',
  'DELETE_COMMENT_IMAGE_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  commentsBusy: false,
  comments: {},
  commentsError: undefined,

  descriptionsBusy: false,
  descriptions: {},
  descriptionsError: undefined
}

const commentsErrorFn = (p) => _.flow(
  _.set('commentsBusy', false),
  _.set('commentsError', p))
const commentsBusyFn = () => _.set('commentsBusy', true)

const descriptionsErrorFn = (p) => _.flow(
  _.set('descriptionsBusy', false),
  _.set('descriptionsError', p)
)
const descriptionsBusyFn = () => _.set('descriptionsBusy', true)

const reducer = {
  // --- COMMENTS ---
  // GET COMMENTS
  [types.LOAD_COMMENTS]: commentsBusyFn,
  [types.LOAD_COMMENTS_SUCCESS]: p => {
    return _.flow(
      _.set('comments', p),
      _.set('commentsBusy', false))
  },
  [types.LOAD_COMMENTS_ERROR]: commentsErrorFn,
  // POST COMMENT
  [types.CREATE_COMMENT]: commentsBusyFn,
  [types.CREATE_COMMENT_SUCCESS]: p => {
    const tgId = _.get('trackingGroupId', p)
    const id = _.get('id', p)
    return _.flow(
      _.set(`comments.${tgId}.${id}`, p),
      _.set('commentsBusy', false)
    )
  },
  [types.CREATE_COMMENT_ERROR]: commentsErrorFn,
  // PUT COMMENT
  [types.UPDATE_COMMENT]: commentsBusyFn,
  [types.UPDATE_COMMENT_SUCCESS]: p => {
    const tgId = _.get('trackingGroupId', p)
    const id = _.get('id', p)
    return _.flow(
      _.update(`comments.${tgId}.${id}`, assignLeft(p)),
      _.set('commentsBusy', false)
    )
  },
  [types.UPDATE_COMMENT_ERROR]: commentsErrorFn,
  // DELETE COMMENT
  [types.DELETE_COMMENT]: commentsBusyFn,
  [types.DELETE_COMMENT_SUCCESS]: p => {
    const tgId = _.get('trackingGroupId', p)
    const id = _.get('id', p)
    _.flow(
      _.omit(`comments.${tgId}.${id}`, p),
      _.set('commentsBusy', false)
    )
  },
  [types.DELETE_COMMENT_ERROR]: commentsErrorFn,
  // --- DESCRIPTIONS ---
  // GET DESCRIPTIONS
  [types.LOAD_DESCRIPTIONS]: descriptionsBusyFn,
  [types.LOAD_DESCRIPTIONS_SUCCESS]: p => {
    return _.flow(
      _.set('descriptions', p),
      _.set('descriptionsBusy', false))
  },
  [types.LOAD_DESCRIPTIONS_ERROR]: descriptionsErrorFn,
  // POST DESCRIPTION
  [types.CREATE_DESCRIPTION]: descriptionsBusyFn,
  [types.CREATE_DESCRIPTION_SUCCESS]: p => {
    const tgId = _.get('trackingGroupId', p)
    return _.flow(
      _.set(`descriptions.${tgId}`, p),
      _.set('descriptionsBusy', false)
    )
  },
  [types.CREATE_DESCRIPTION_ERROR]: descriptionsErrorFn,
  // PUT DESCRIPTION
  [types.UPDATE_DESCRIPTION]: descriptionsBusyFn,
  [types.UPDATE_DESCRIPTION_SUCCESS]: p => {
    const tgId = _.get('trackingGroupId', p)
    return _.flow(
      _.set(`descriptions.${tgId}`, p),
      _.set('descriptionsBusy', false)
    )
  },
  [types.UPDATE_DESCRIPTION_ERROR]: descriptionsErrorFn,
  // DELETE DESCRIPTION
  [types.DELETE_DESCRIPTION]: descriptionsBusyFn,
  [types.DELETE_DESCRIPTION_SUCCESS]: p => {
    _.flow(
      _.omit(`${_.get('trackingGroupId', p)}`),
      _.set('descriptionsBusy', false)
    )
  },
  [types.DELETE_DESCRIPTION_ERROR]: descriptionsErrorFn
}

export default createReducer(initialState, reducer)

function createSelectors () {
  return {
    commentsBusy: _.get(`${namespace}.commentsBusy`),
    descriptionsBusy: _.get(`${namespace}.descriptionsBusy`),
    commentsError: _.get(`${namespace}.commentsError`),
    descriptionsError: _.get(`${namespace}.descriptionsError`),
    comments: (tgId) => _.flow(
      _.get(`${namespace}.comments.${tgId}`),
      _.orderBy('createdAt', 'desc')
    ),
    description: (trackingGroupId) => _.get(`${namespace}.descriptions.${trackingGroupId}`),
    descriptions: _.get(`${namespace}.descriptions`),
    allComments: _.get(`${namespace}.comments`)
  }
}

export const selectors = createSelectors()
