import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import CloseIcon from '@material-ui/icons/Close'

function TransitionUp (props) {
  return <Slide direction='up' {...props} />
}

function TransitionDown (props) {
  return <Slide direction='down' {...props} />
}

export default class SnackBarRoot extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    top: PropTypes.bool,
    spinner: PropTypes.bool,
    autoHideDuration: PropTypes.number
  }
  static defaultProps = {
    top: false,
    spinner: false
  }
  state = {
    message: '',
    time: 0
  }
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.time > this.props.time) {
      this.setState({ message: nextProps.message })
    }
  }
  onClose = (e, reason) => {
    if (reason !== 'clickaway') {
      this.setState({
        message: ''
      })
    }
  }
  render () {
    return (
      <Snackbar
        key={'why-do-i-need-a-key'}
        open={Boolean(this.state.message)}
        onClose={this.onClose}
        autoHideDuration={this.props.autoHideDuration}
        TransitionComponent={this.props.top ? TransitionDown : TransitionUp}
        anchorOrigin={{
          vertical: this.props.top ? 'top' : 'bottom',
          horizontal: 'center'
        }}>
        <SnackbarContent
          action={(
            this.props.spinner
              ? <CircularProgress
                color='secondary'
                thickness={8} />
              : <IconButton
                key='close'
                color='secondary'
                onClick={this.onClose}>
                <CloseIcon />
              </IconButton>)}
          message={this.state.message}
        />
      </Snackbar>
    )
  }
}
