import _ from 'lodash/fp'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/holesTees'
import { selectors as clubsSelectors } from '../reducers/clubs'
import { actions as teesActions } from '../reducers/tees'

import {
  getEffect,
  prependClubsUrl,
  putEffect
} from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadHolesTeesEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'holes_tees'))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveHolesTeesEffect (hole) {
  const clubId = yield select(clubsSelectors.getActive)
  const holeId = _.get('id', hole)

  const body = _.set('club_id', clubId, hole)
  const request = {
    url: (yield call(prependClubsUrl, `holes/${holeId}`)),
    options: {
      body
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadHolesTeesFlow ({ payload }) {
  const result = yield call(loadHolesTeesEffect)
  yield put(actions.loadRequestDone())

  if (result.error) {
    yield put(actions.loadError(result))
  } else {
    yield put(actions.loadSuccess(result))
  }
}

export function * saveHolesTeesFlow ({ payload }) {
  const hole = _.flow(
    _.omit([
      'created_at',
      'description',
      'name',
      'order',
      'tees',
      'updated_at'
    ]),
    _.update('distances', _.map(_.omit(['id', 'created_at', 'updated_at'])))
  )(payload)
  const result = yield call(saveHolesTeesEffect, hole)

  if (result.error) {
    yield put(actions.saveError(result))
  } else {
    yield put(actions.saveSuccess())
  }
  yield put(actions.loadRequest())
  yield take([types.LOAD_ERROR, types.LOAD_SUCCESS])
  yield put(actions.saveDone())
  yield put(teesActions.loadRequest())
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadHolesTeesFlow),
    takeLatest(types.SAVE, saveHolesTeesFlow)
  ])
}
