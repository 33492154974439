import _ from 'lodash/fp'
import { put, call, takeLatest, take, all } from 'redux-saga/effects'

import { types, actions } from '../reducers/sponsorSetups'
import { deleteEffect, getEffect, postEffect, putEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadSponsorSetupsEffect () {
  const url = yield prependClubsUrl('sponsor-setups')
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveSponsorSetupEffect (sponsorSetup) {
  const { id } = sponsorSetup
  const url = yield prependClubsUrl(`sponsor-setups/${id}`)
  const request = {
    url,
    options: {
      body: sponsorSetup
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * createSponsorSetupEffect (sponsorSetup) {
  const url = yield prependClubsUrl(`sponsor-setups`)
  const request = {
    url,
    options: {
      body: sponsorSetup
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteSponsorSetupEffect (sponsorSetupId) {
  const url = yield prependClubsUrl(`sponsor-setups/${sponsorSetupId}`)
  const request = {
    url
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadSponsorSetupsFlow () {
  const sponsors = yield call(loadSponsorSetupsEffect)

  if (sponsors.error) {
    yield put(actions.loadError(sponsors))
  } else {
    yield put(actions.loadSuccess(sponsors))
  }

  yield put(actions.loadRequestDone())
}

export function * saveSponsorSetupFlow ({ payload }) {
  const { sponsorSetup } = payload
  const sponsorSetupId = sponsorSetup.id
  if (!sponsorSetupId) {
    const sponsorSetupWithoutPositions = _.flow(
      _.set('sponsor_positions', [])
    )(sponsorSetup)
    const res = yield call(createSponsorSetupEffect, sponsorSetupWithoutPositions)
    if (res.error) {
    } else {
      const sponsorSetupFinal = {
        ...sponsorSetup,
        id: res.id,
        sponsor_positions: _.map(_.set('sponsor_setup_id', res.id), sponsorSetup.sponsor_positions)
      }
      yield call(saveSponsorSetupEffect, sponsorSetupFinal)

      yield put(actions.saveDone({ sponsorSetupId }))
      return yield put(actions.loadRequest())
    }
  } else {
    yield call(saveSponsorSetupEffect, sponsorSetup)
    yield put(actions.saveDone({ sponsorSetupId }))
    return yield put(actions.loadRequest())
  }
}

export function * deleteSponsorSetupFlow ({ payload }) {
  const { sponsorSetupId } = payload
  yield call(deleteSponsorSetupEffect, sponsorSetupId)

  yield put(actions.deleteDone(sponsorSetupId))
  yield put(actions.loadRequest())
}

export function * initiateUpdateFlow (objectWithPayload) {
  const { payload } = objectWithPayload
  yield call(loadSponsorSetupsFlow, objectWithPayload)

  yield put(actions.initiateUpdateResult(payload))
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadSponsorSetupsFlow),
    takeLatest(types.SAVE, saveSponsorSetupFlow),
    takeLatest(types.DELETE, deleteSponsorSetupFlow),
    takeLatest(types.INITIATE_UPDATE, initiateUpdateFlow)
  ])
}
