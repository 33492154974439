import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { types, actions } from '../reducers/sponsors'
import { getEffect, deleteEffect, postEffect, putEffect, prependClubsUrl, getCurrentClubId } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadSponsorsEffect () {
  const url = yield prependClubsUrl('sponsors')
  const request = {
    url
  }

  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveSponsorEffect (sponsor) {
  const { id } = sponsor
  const url = yield prependClubsUrl(`sponsors/${id}`)
  const request = {
    url,
    options: {
      body: sponsor
    }
  }

  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * createSponsorEffect (sponsor) {
  const url = yield prependClubsUrl(`sponsors`)
  const request = {
    url,
    options: {
      body: sponsor
    }
  }

  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * saveSponsorLogoEffect (sponsor) {
  const { id, logo } = sponsor
  const url = yield prependClubsUrl(`sponsors/${id}/logo`)
  const request = {
    url,
    options: {
      body: {
        data: logo
      }
    }
  }

  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteSponsorEffect (sponsorId) {
  const url = yield prependClubsUrl(`sponsors/${sponsorId}`)
  const request = {
    url
  }

  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadSponsorsFlow () {
  const sponsors = yield call(loadSponsorsEffect)

  if (sponsors.error) {
    yield put(actions.loadError(sponsors))
  } else {
    yield put(actions.loadSuccess(sponsors))
  }

  yield put(actions.loadRequestDone())
}

export function * createSponsorFlow ({ payload: { name, url, logo } }) {
  const clubId = yield call(getCurrentClubId)
  const sponsor = yield call(createSponsorEffect, { name, url, logo, club_id: clubId })
  const error = _.get('error', sponsor)
  if (error) {
    yield put(actions.createError(error))
  } else {
    yield call(saveSponsorLogoEffect, _.set('logo', logo, sponsor))
    yield put(actions.createSuccess(_.set('logo', true, sponsor)))
  }
}

export function * saveSponsorFlow ({ payload: sponsor }) {
  const result = yield call(saveSponsorEffect, sponsor)

  if (typeof sponsor.logo === 'string') {
    yield call(saveSponsorLogoEffect, sponsor)

    yield put(actions.saveDone(_.set('logo', true, result)))
  } else {
    yield put(actions.saveDone(result))
  }
}

export function * deleteSponsorFlow ({ payload: sponsorId }) {
  yield call(deleteSponsorEffect, sponsorId)
  yield put(actions.deleteDone(sponsorId))
}

export default function * () {
  return yield all([
    takeLatest(types.CREATE_REQUEST, createSponsorFlow),
    takeLatest(types.LOAD_REQUEST, loadSponsorsFlow),
    takeLatest(types.SAVE_REQUEST, saveSponsorFlow),
    takeLatest(types.DELETE_REQUEST, deleteSponsorFlow)
  ])
}
