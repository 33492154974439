import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import * as usersReducer from '../reducers/users'
import * as authReducer from '../reducers/auth'
import * as snack from './snackMessage'

import { getEffect, putEffect, postEffect, deleteEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

function * getUsersEffect (payload) {
  const request = {
    url: (yield call(prependClubsUrl, 'users', _.get('clubId', payload)))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

function * createUserEffect (user) {
  const request = {
    url: (yield call(prependClubsUrl, 'users')),
    options: {
      body: user
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

function * updateUserEffect (payload) {
  const profile = _.get('profile', payload)
  const url = `users/${_.get('id', profile)}`
  const request = {
    url,
    options: {
      body: profile
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

function * deleteUserEffect (userId) {
  const request = {
    url: (yield call(prependClubsUrl, `users/${userId}`))
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

function * editUserRolesEffect (user) {
  const request = {
    url: (yield call(prependClubsUrl, `users/${user.id}/roles`)),
    options: {
      body: user
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * getUsersFlow ({ payload }) {
  const users = yield call(getUsersEffect, payload)
  if (users.error) {
    yield put(usersReducer.actions.getUsersFailure())
  } else {
    yield put(usersReducer.actions.getUsersSuccess())
  }
  yield put(usersReducer.actions.getUsersDone(_.keyBy('id', users)))
}

function * createUserFlow ({ payload }) {
  const result = yield call(createUserEffect, payload)
  if (result.error) {
    yield put(usersReducer.actions.createUserFailure())
  } else {
    yield put(usersReducer.actions.createUserSuccess())
  }
  yield put(usersReducer.actions.createUserDone())
  yield put(usersReducer.actions.getUsers())
}

function * deleteUserFlow ({ payload }) {
  const result = yield call(deleteUserEffect, payload)
  if (result.error) {
    yield put(usersReducer.actions.deleteUserFailure())
  } else {
    yield put(usersReducer.actions.deleteUserSuccess())
  }
  yield put(usersReducer.actions.deleteUserDone())
  yield put(usersReducer.actions.getUsers())
}

function * editUserFlow ({ payload }) {
  const result = yield call(editUserRolesEffect, payload)
  if (result.error) {
    if (result.error.status === 403) {
      yield snack.setMessage(`You can't change this user's roles`)
    } else {
      yield snack.genericError()
    }
    yield put(usersReducer.actions.editUserFailure(result.error))
  } else {
    yield put(usersReducer.actions.getUsers())
    yield take(usersReducer.types.GET_USERS_DONE)
    yield put(usersReducer.actions.editUserSuccess(result))
  }
}

function * updateUserFlow ({ payload }) {
  const result = yield call(updateUserEffect, payload)
  if (result.error) {
    return
  }
  yield put(authReducer.actions.reloadProfileRequest())
}

export default function * () {
  return yield all([
    takeLatest(usersReducer.types.GET_USERS, getUsersFlow),
    takeLatest(usersReducer.types.CREATE_USER, createUserFlow),
    takeLatest(usersReducer.types.DELETE_USER, deleteUserFlow),
    takeLatest(usersReducer.types.EDIT_USER_REQUEST, editUserFlow),
    takeLatest(usersReducer.types.UPDATE_USER, updateUserFlow)
  ])
}
