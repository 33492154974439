"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validator = exports.update = exports.set = exports.mergeLeft = exports.isUUID = exports.createTypes = exports.createSelector = exports.createReducer = exports.createActions = exports.assignLeft = exports.anyValue = void 0;

var _lodash = _interopRequireDefault(require("lodash"));

var _fp = require("lodash/fp");

var _reselect = require("reselect");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}

var anyValue = function anyValue() {
  return true;
};

exports.anyValue = anyValue;

var assignLeft = _fp.assign.convert({
  rearg: true
});

exports.assignLeft = assignLeft;

var mergeLeft = _fp.merge.convert({
  rearg: true
});

exports.mergeLeft = mergeLeft;
var set = (0, _fp.setWith)(Object);
exports.set = set;
var update = (0, _fp.updateWith)(Object);
exports.update = update;

var isUUID = function isUUID(v) {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(v);
};

exports.isUUID = isUUID;

var validator = function validator() {
  var requiredKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function (object) {
    var invalidReturns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

    if (_typeof(object) !== 'object') {
      console.error("".concat(object, " is not an object"));
      return object;
    }

    var isValid = _lodash.default.reduce(requiredKeys, function (acc, validator, key) {
      if (!acc) {
        return false;
      }

      if (typeof validator !== 'function') {
        console.warn("Validator for \"".concat(key, "\" must be a function."));
        return acc;
      }

      var valid = validator(object[key]);

      if (!valid) {
        console.warn("Validator for \"".concat(key, "\" disagrees with value:"), object[key]);
      }

      return valid;
    }, true);

    if (invalidReturns) {
      return isValid && object;
    }

    return object;
  };
}; //  ██████╗ ███████╗██████╗ ██╗   ██╗ ██████╗███████╗██████╗ ███████╗
//  ██╔══██╗██╔════╝██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
//  ██████╔╝█████╗  ██║  ██║██║   ██║██║     █████╗  ██████╔╝███████╗
//  ██╔══██╗██╔══╝  ██║  ██║██║   ██║██║     ██╔══╝  ██╔══██╗╚════██║
//  ██║  ██║███████╗██████╔╝╚██████╔╝╚██████╗███████╗██║  ██║███████║
//  ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝  ╚═════╝╚══════╝╚═╝  ╚═╝╚══════╝


exports.validator = validator;

var createReducer = function createReducer() {
  var initialState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var handlers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  var reducerFunction = function reducerFunction() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](action.payload)(state);
    }

    return state;
  }; // Persistence is no more


  return reducerFunction;
};

exports.createReducer = createReducer;

var createTypes = function createTypes(typeKeys, optionalNamespace) {
  var typeValues = (0, _fp.isEmpty)(optionalNamespace) ? typeKeys : (0, _fp.map)(function (type) {
    return (0, _fp.join)('/', (0, _fp.concat)(optionalNamespace, type));
  }, typeKeys);
  return (0, _fp.zipObject)(typeKeys, typeValues);
};

exports.createTypes = createTypes;

var createActions = function createActions(typeMap) {
  var types = (0, _fp.keys)(typeMap);
  var typeValues = (0, _fp.values)(typeMap);
  var actionKeys = (0, _fp.map)(_fp.camelCase, types);

  var actionFunction = function actionFunction(type) {
    return function (payload) {
      return {
        type: type,
        payload: payload
      };
    };
  };

  return (0, _fp.zipObject)(actionKeys, (0, _fp.map)(actionFunction, typeValues));
}; //  ███████╗███████╗██╗     ███████╗ ██████╗████████╗ ██████╗ ██████╗ ███████╗
//  ██╔════╝██╔════╝██║     ██╔════╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝
//  ███████╗█████╗  ██║     █████╗  ██║        ██║   ██║   ██║██████╔╝███████╗
//  ╚════██║██╔══╝  ██║     ██╔══╝  ██║        ██║   ██║   ██║██╔══██╗╚════██║
//  ███████║███████╗███████╗███████╗╚██████╗   ██║   ╚██████╔╝██║  ██║███████║
//  ╚══════╝╚══════╝╚══════╝╚══════╝ ╚═════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝


exports.createActions = createActions;
var createSelector = (0, _reselect.createSelectorCreator)(_reselect.defaultMemoize, _lodash.default.isEqual);
exports.createSelector = createSelector;