import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/clubStatus'

import { deleteEffect, getEffect, postEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadClubStatusEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'announcement'))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveClubStatusEffect (payload) {
  const request = {
    url: (yield call(prependClubsUrl, 'announcement')),
    options: {
      body: payload
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteClubStatusEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'announcement'))
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadClubStatusFlow () {
  const clubStatus = yield call(loadClubStatusEffect)
  if (clubStatus.error) {
    yield put(actions.loadError(clubStatus.error))
  } else {
    yield put(actions.loadSuccess(clubStatus))
  }
  yield put(actions.loadRequestDone())
}

export function * saveClubStatusFlow ({ payload }) {
  const result = yield call(saveClubStatusEffect, payload)

  if (!result.error) {
    yield put(actions.loadRequest())
    yield take(types.LOAD_REQUEST_DONE)
  } else {
    yield put(actions.saveError(result.error))
  }

  yield put(actions.saveRequestDone())
}

export function * deleteClubStatusFlow () {
  const result = yield call(deleteClubStatusEffect)

  if (!result.error) {
    yield put(actions.loadRequest())
    yield take(types.LOAD_REQUEST_DONE)
  } else {
    yield put(actions.deleteError(result.error))
  }

  yield put(actions.deleteRequestDone())
}

export default function * () {
  return yield all([
    takeLatest(types.DELETE_REQUEST, deleteClubStatusFlow),
    takeLatest(types.SAVE_REQUEST, saveClubStatusFlow),
    takeLatest(types.LOAD_REQUEST, loadClubStatusFlow)
  ])
}
