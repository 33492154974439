import React, { Component } from 'react'
import _ from 'lodash/fp'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { actions as authActions, selectors as authSelectors } from '../reducers/auth'
import SnackWhen from '../components/SnackWhen'

const styles = theme => {
  return ({
    container: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#ececec'
    }
  })
}

class Login extends Component {
  state = {
    password: '',
    passwordRepeat: ''
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  passwordsExist = () => {
    const { password, passwordRepeat } = this.state
    return (password && passwordRepeat)
  }

  differentPasswords = () => {
    const { password, passwordRepeat } = this.state
    if (!password || !passwordRepeat) {
      return false
    }
    if (_.isEqual(password, passwordRepeat)) {
      return false
    }
    return true
  }

  resetPassword = (e) => {
    e.preventDefault()
    if (this.differentPasswords()) {
      return false
    }
    const { password } = this.state
    this.props.resetPassword(password)
  }

  render () {
    const { classes } = this.props
    return (
      <div className={classes.container}>
        <Card style={{ width: 512 }}>
          <CardHeader title={`Reset`} />
          <CardContent>
            <form onSubmit={this.resetPassword}>

              <Typography variant='body2'>{'Enter your new password'}</Typography>

              <TextField
                disabled={this.state.reset}
                id='password'
                label='Password'
                type='password'
                autoComplete='current-password'
                margin='normal'
                value={this.state.password}
                onChange={this.handleChange('password')}
                fullWidth
              />

              <TextField
                disabled={!this.state.password}
                error={this.differentPasswords()}
                id='passwordRepeat'
                label='Repeat Password'
                type='password'
                autoComplete='current-password'
                margin='normal'
                value={this.state.passwordRepeat}
                onChange={this.handleChange('passwordRepeat')}
                fullWidth
                helperText={this.differentPasswords() ? 'Passwords do not match.' : ''}
              />

              <Button disabled={this.differentPasswords() || !this.passwordsExist()} style={{ marginTop: 60 }} fullWidth type='submit' variant='contained' color='primary'>
                {'Reset Password'}
              </Button>

            </form>
          </CardContent>

        </Card>

        <SnackWhen selector={authSelectors.resetError} type={'error'}>Could Not Reset Password</SnackWhen>
        <SnackWhen selector={authSelectors.resetWaiting} type={'loading'}>Resetting Password</SnackWhen>
      </div>
    )
  }
}

export default _.compose(
  withStyles(styles, { withTheme: true }),
  connect(
    (s, ownProps) => {
      const email = _.flow(
        _.get('match.params.email'),
        decodeURIComponent
      )(ownProps)
      const token = _.get('match.params.token', ownProps)
      return {
        email,
        token
      }
    },
    (d, ownProps) => {
      const email = _.flow(
        _.get('match.params.email'),
        decodeURIComponent
      )(ownProps)
      const token = _.get('match.params.token', ownProps)
      return {
        resetPassword: (password) => d(authActions.passwordReset({ password, email, token }))
      }
    }
  )
)(Login)
