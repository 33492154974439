import { all, call, put, take, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash/fp'

import { types, actions } from '../reducers/communication'
import { selectors as clubSelectors } from '../reducers/clubs'
import { getEffect, prependClubsUrl, postEffect } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * getMessagesEffect () {
  const request = {
    url: yield prependClubsUrl('notifications')
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * postMessageEffect (payload) {
  const club = yield select(clubSelectors.getCurrentClub)
  const clubName = _.get('name', club)
  const body = _.set('title', clubName, payload)
  const request = {
    url: yield prependClubsUrl('notifications'),
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * getSubscriptionsEffect (guides) {
  const guidesArray = _.map((g) => {
    return `guides=${g}`
  }, guides)
  const guidesString = guidesArray.join('&')
  const request = _.isEmpty(guides)
    ? { url: yield prependClubsUrl(`notifications/subscribers`) }
    : { url: yield prependClubsUrl(`notifications/subscribers?${guidesString}`) }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * getMessagesFlow ({ payload }) {
  const res = yield call(getMessagesEffect)
  if (res.error) {
    yield put(actions.messagesError({ error: res.error }))
  } else {
    const messages = _.keyBy('id', res)
    yield put(actions.messagesSuccess(messages))
  }
}

export function * postMessageFlow ({ payload }) {
  const res = yield call(postMessageEffect, payload)
  if (res.error) {
    yield put(actions.postMessageError({ error: res.error }))
  } else {
    yield put(actions.postMessageSuccess())
    yield put(actions.messages())
  }
}

export function * getSubscriptionsFlow ({ payload }) {
  const res = yield call(getSubscriptionsEffect, payload)
  if (res.error) {
    yield put(actions.subscriptionsError({ error: res.error }))
  } else {
    const subscriptions = res
    yield put(actions.subscriptionsSuccess(subscriptions))
  }
}

export default function * () {
  return yield all([
    takeLatest(types.POST_MESSAGE, postMessageFlow),
    takeLatest(types.MESSAGES, getMessagesFlow),
    takeLatest(types.SUBSCRIPTIONS, getSubscriptionsFlow)
  ])
}
