"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _colors = _interopRequireDefault(require("../colors"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var drawerWidth = 240;

var styles = function styles(theme) {
  return {
    root: {
      width: '100%',
      height: '100%',
      zIndex: 1,
      overflow: 'hidden'
    },
    label: {
      textTransform: 'none'
    },
    appFrame: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      height: '100%'
    },
    listRoot: {
      width: drawerWidth
    },
    square: {
      height: 300,
      width: 300
    },
    content: _defineProperty({
      backgroundColor: _colors.default.content,
      marginLeft: 0,
      width: '100%',
      flexGrow: 1,
      padding: theme.spacing(1) * 3,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      height: 'calc(100% - 64px)',
      marginTop: 64
    }, theme.breakpoints.up('sm'), {
      content: {
        height: 'calc(100% - 56px)',
        marginTop: 56
      }
    }),
    contentShift: _defineProperty({}, theme.breakpoints.up('md'), {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      marginLeft: drawerWidth,
      width: "calc(100% - ".concat(drawerWidth, "px)")
    }),
    contentNoPadding: {
      padding: 0
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: _defineProperty({}, theme.breakpoints.up('md'), {
      width: "calc(100% - ".concat(drawerWidth, "px)"),
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      })
    }),
    drawerHeader: {
      minHeight: 51
    }
  };
};

var _default = styles;
exports.default = _default;