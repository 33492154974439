import _ from 'lodash/fp'
import { createReducer, createTypes, createActions } from '@fe/redux/reducers/util'
import { createSelector } from 'reselect'

const namespace = 'communication'

const typeKeys = [
  'MESSAGES',
  'MESSAGES_SUCCESS',
  'MESSAGES_ERROR',
  'POST_MESSAGE',
  'POST_MESSAGE_SUCCESS',
  'POST_MESSAGE_ERROR',
  'SUBSCRIPTIONS',
  'SUBSCRIPTIONS_SUCCESS',
  'SUBSCRIPTIONS_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  messages: {},
  subscriptions: {},
  error: undefined,
  success: undefined,
  postError: undefined,
  postSuccess: undefined
}

const reducer = {
  [types.MESSAGES_SUCCESS]: messages => _.flow(
    _.set('messages', messages),
    _.set('success', Date.now())
  ),
  [types.MESSAGES_ERROR]: p => _.flow(
    _.set('error', Date.now())
  ),
  [types.POST_MESSAGE_SUCCESS]: messages => _.flow(
    _.set('postSuccess', Date.now())
  ),
  [types.POST_MESSAGE_ERROR]: p => _.flow(
    _.set('postError', Date.now())
  ),
  [types.SUBSCRIPTIONS_SUCCESS]: subscriptions => _.flow(
    _.set('subscriptions', subscriptions),
    _.set('success', Date.now())
  ),
  [types.SUBSCRIPTIONS_ERROR]: p => _.flow(
    _.set('error', Date.now())
  ),
  [types.SUBSCRIPTIONS]: p => _.flow(
    (s) => {
      console.log('subscriptions type')
      return s
    }
  )
}

function createSelectors () {
  const sortedMessages = (rev = true) => _.flow(
    _.get(`${namespace}.messages`),
    _.sortBy('created_at'),
    rev ? _.reverse : _.identity
  )

  const messages = (rev) => (start, stop) => createSelector(
    sortedMessages(rev),
    (sortedMessages) => {
      if (!start && !stop) {
        return sortedMessages
      }
      return sortedMessages
    }
  )

  const subscriptions = _.get(`${namespace}.subscriptions`)

  return {
    messages,
    subscriptions
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
