import _ from 'lodash/fp'
import * as utils from '@fe/redux/reducers/util'

const namespace = 'pinSetups'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'UPSERT_REQUEST',
  'UPSERT_SUCCESS',
  'UPSERT_ERROR',

  'DELETE_REQUEST',
  'DELETE_SUCCESS',
  'DELETE_ERROR'
]

export const types = utils.createTypes(typeKeys, namespace)

export const actions = utils.createActions(types)

function createSelectors () {
  const pinSetups = _.get(`${namespace}.items`)
  const pinSetup = (courseId) => (setupId) => _.get(`${namespace}.items.${courseId}.${setupId}`)
  return {
    pinSetups,
    pinSetup
  }
}

export const selectors = createSelectors()

export const initialState = {
  items: {
    // courseId -> pinSetupId -> { courseId, clubId, id, name }
  },

  saving: false,
  saveError: null,

  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => utils.assignLeft({ loadRequestWaiting: true, loadError: null }),
  [types.LOAD_SUCCESS]: p => utils.assignLeft({ items: _.mapValues(_.keyBy('id'), _.groupBy('courseId', p)), loadRequestWaiting: false }),
  [types.LOAD_ERROR]: p => utils.assignLeft({ loadError: p, loadRequestWaiting: false }),

  [types.UPSERT_REQUEST]: p => utils.assignLeft({ saveError: null, saving: true }),
  [types.UPSERT_SUCCESS]: p => utils.assignLeft({ [`items.${p.courseId}.${p.id}`]: _.set('timestamp', Date.now(), p), saving: false }),
  [types.UPSERT_ERROR]: p => utils.assignLeft({ saveError: p, saving: false }),

  [types.DELETE_SUCCESS]: p => _.unset(`items.${p}`)
}

export default utils.createReducer(initialState, reducer)
