import _ from 'lodash/fp'
import { createSelector } from 'reselect'

import { appClubLogoLocation, printClubLogoLocation } from '../config'
import { assignLeft, createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'clubs'

export const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_SINGLE',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'CREATE_REQUEST',
  'CREATE_SUCCESS',
  'CREATE_ERROR',

  'SET_PRICE_TEXT',
  'UPDATE_CLUB',
  'SAVE_CLUB',
  'DONE_SAVING',
  'SWITCH_CLUB',

  'DIALOG_UPLOAD_HIDE',
  'DIALOG_UPLOAD_ROUND_TOGGLE',
  'DIALOG_UPLOAD_RECTANGULAR_TOGGLE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},
  saving: false,
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,

  dialogUploadRoundVisible: false,
  dialogUploadRectangularVisible: false,

  creatingClub: false
}

const reducer = {
  [types.LOAD_REQUEST]: p =>
    _.flow(
      _.set('loadRequestWaiting', true),
      _.set('loadError', null)
    ),
  [types.LOAD_REQUEST_SINGLE]: p =>
    _.flow(
      _.set('loadRequestWaiting', true),
      _.set('loadError', null)
    ),
  [types.LOAD_REQUEST_DONE]: p =>
    _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p =>
    _.flow(
      _.set('loadedAt', Date.now()),
      _.update('items', (items) => {
        const clubs = _.flow(
          _.keyBy('id')
        )(p)
        return {
          ...items,
          ...clubs
        }
      })
    ),
  [types.LOAD_ERROR]: p =>
    _.set('loadError', _.get('error', p)),
  [types.SAVE_CLUB]: p =>
    _.set('saving', true),
  [types.DONE_SAVING]: p =>
    _.set('saving', false),

  [types.DIALOG_UPLOAD_HIDE]: p => assignLeft({
    dialogUploadRoundVisible: false,
    dialogUploadRectangularVisible: false
  }),
  [types.DIALOG_UPLOAD_ROUND_TOGGLE]: p => _.update('dialogUploadRoundVisible', v => !v),
  [types.DIALOG_UPLOAD_RECTANGULAR_TOGGLE]: p => _.update('dialogUploadRectangularVisible', v => !v),

  [types.CREATE_REQUEST]: p => _.set('creatingClub', true),
  [types.CREATE_SUCCESS]: p => _.set('creatingClub', false),
  [types.CREATE_ERROR]: p => _.flow(_.set('creatingClub', false))
}

export default createReducer(initialState, reducer, namespace, { whitelist: ['items', 'loadedAt'] })

function createSelectors () {
  const loadedAt = _.get(`${namespace}.loadedAt`)
  const getActive = _.flow(
    _.get('router.location.pathname'),
    _.split('/'),
    _.get(1)
  )
  const getClubs = _.get(`${namespace}.items`)
  const getClub = (clubId) => _.get(`${namespace}.items.${clubId}`)
  const getSaving = _.get(`${namespace}.saving`)
  const getLoadedAt = _.get(`${namespace}.loadedAt`)
  const getCurrentClub = createSelector(
    getActive,
    getClubs,
    _.get
  )

  const urlLogoRound = createSelector(
    getCurrentClub,
    loadedAt,
    (club, loadedAt) => _.get('logo_file_name', club) ? (appClubLogoLocation + _.get('logo_file_name', club) + '?h=' + loadedAt) : ''
  )

  const urlLogoRectangular = createSelector(
    getCurrentClub,
    loadedAt,
    (club, loadedAt) => _.get('logo_file_name', club) ? (printClubLogoLocation + _.get('logo_file_name', club) + '?h=' + loadedAt) : ''
  )

  const dialogUploadRoundVisible = _.get(`${namespace}.dialogUploadRoundVisible`)
  const dialogUploadRectangularVisible = _.get(`${namespace}.dialogUploadRectangularVisible`)

  const creatingClub = _.get(`${namespace}.creatingClub`)

  return {
    getActive,
    getClubs,
    getClub,
    getSaving,
    getLoadedAt,
    getCurrentClub,
    urlLogoRound,
    urlLogoRectangular,
    dialogUploadRoundVisible,
    dialogUploadRectangularVisible,
    creatingClub
  }
}

export const selectors = createSelectors()
