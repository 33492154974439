import _ from 'lodash/fp'
import { createTypes, createActions, createReducer, mergeLeft } from '@fe/redux/reducers/util'

const namespace = 'drawingStyles'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => _.set('loadRequestWaiting', true),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('loadedAt', Date.now()),
    _.update('items', mergeLeft(
      _.keyBy('id', p)
    ))
  )
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const drawingStyles = _.flow(
    _.get(`${namespace}.items`),
    _.values,
    _.sortBy('name')
  )
  return {
    drawingStyles
  }
}

export const selectors = createSelectors()
