import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions } from '../reducers/regions'
import { types as authTypes } from '../reducers/auth'

import { getEffect } from './network'

import * as snack from './snackMessage'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadRegionsEffect () {
  const request = {
    url: `regions`
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * takeLatest(types.LOAD_REQUEST, loadClubsFlow)
 */
export function * loadRegionsFlow ({ payload }) {
  const regions = yield call(loadRegionsEffect, payload)
  if (regions.error) {
    yield put(actions.loadError(regions))
    yield snack.genericError(regions.error)
  } else {
    yield put(actions.loadSuccess(regions))
  }
}

export default function * () {
  return yield all([
    takeLatest(authTypes.BOOTSTRAP_DONE, loadRegionsFlow)
  ])
}
