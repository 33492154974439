import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/guidesData'
import * as guidesReducer from '../reducers/guides'
import { getEffect, postEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadGuidesDataEffect (guideId) {
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/data`))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveDistancesEffect ({ guideId, holeId, distances }) {
  const body = _.map(_.flow(
    _.omit(['tees', 'green', 'distance', 'id']),
    _.update('destination', _.omit(['z']))
  ), distances)
  const request = {
    url: (yield call(prependClubsUrl, `guides/${guideId}/holes/${holeId}/distance_markers`)),
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * takeLatest(types.LOAD_REQUEST, loadGuidesDataFlow)
 */
export function * loadGuidesDataFlow ({ payload: guideId }) {
  const data = yield call(loadGuidesDataEffect, guideId)
  const error = _.get('error', data)
  if (error) {
    yield put(actions.loadError(error))
  } else {
    yield put(actions.loadSuccess(data))
  }
}

/**
 * takeLatest(types.SAVE_DISTANCES_REQUEST, saveDistancesRequestFlow)
 */
export function * saveDistancesRequestFlow ({ payload }) {
  yield put(actions.saveDistancesWaiting(true))
  const result = yield call(saveDistancesEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(actions.saveDistancesError(error))
  } else {
    yield put(guidesReducer.actions.setDistances(payload))
    yield put(actions.saveDistancesSuccess(result))
  }
  yield put(actions.saveDistancesWaiting(false))
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadGuidesDataFlow),
    takeLatest(types.SAVE_DISTANCES_REQUEST, saveDistancesRequestFlow)
  ])
}
