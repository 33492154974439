import _ from 'lodash/fp'
import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

export const namespace = 'drawingData'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'SAVE_REQUEST',
  'SAVE_SUCCESS',
  'SAVE_ERROR',

  'LOAD_TRACKINGS',
  'LOAD_TRACKINGS_SUCCESS',
  'LOAD_TRACKINGS_ERROR',

  'GET_CONTEXTS',
  'GET_CONTEXTS_SUCCESS',
  'GET_CONTEXTS_ERROR',

  'LOAD_TRACKING_GROUP_COURSES',
  'LOAD_TRACKING_GROUP_COURSES_SUCCESS',
  'LOAD_TRACKING_GROUP_COURSES_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  items: {
    // holeId -> drawingData
  },
  trackings: {

  },
  courses: {}
}

const reducer = {
  [types.LOAD_SUCCESS]: p => {
    const holeId = _.get('id', p)
    return _.set(`items.${holeId}`, p)
  },
  [types.LOAD_TRACKINGS_SUCCESS]: p => {
    return _.set(`trackings`, _.keyBy('id', p))
  },
  [types.LOAD_TRACKING_GROUP_COURSES_SUCCESS]: p => {
    return _.set('courses', p)
  },
  [types.SAVE_REQUEST]: p => {
    return (
      _.flow(
        _.set('saving', true),
        _.set('saveRequest', Date.now())
      )
    )
  },
  [types.SAVE_SUCCESS]: p => {
    return (
      _.flow(
        _.set('saving', false),
        _.set('saveSuccess', Date.now())
      )
    )
  },
  [types.SAVE_ERROR]: p => {
    return (
      _.flow(
        _.set('saving', false),
        _.set('saveError', Date.now())
      )
    )
  },
  [types.GET_CONTEXTS_SUCCESS]: p => {
    return _.set('contexts', _.keyBy('name', p))
  }
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const drawingData = _.get(`${namespace}.items`)

  const saving = _.get(`${namespace}.saving`)
  const saveSuccess = (s) => _.flow(
    _.get(`${namespace}.saveRequest`),
    _.gt(_.get(`${namespace}.saveSuccess`, s))
  )(s)

  const drawingDataHole = holeId => _.flow(drawingData, _.get(holeId))
  const trackings = _.get(`${namespace}.trackings`)

  const teeTrackingsByHoleIdTeeId = _.flow(
    trackings,
    _.filter({ elementName: 'tee' }),
    _.groupBy('customAttributes.holeId'),
    _.mapValues(_.keyBy('customAttributes.teeId'))
  )

  const areaGrouping = _.flow(
    _.groupBy('elementName'),
    _.map(_.reduce((acc, curr) => {
      const area = _.get('area', curr)
      const name = _.get('elementName', curr)
      return ({
        area: acc.area + area,
        count: acc.count + 1,
        name
      })
    }, { area: 0, count: 0, name: '' })
    ))

  const validityFiltering = _.filter((t) => {
    const name = _.get('elementName', t)
    if (_.includes(name, ['mound', 'hollow', 'background']) || !name) {
      return false
    }
    const area = _.get('area', t)
    return area === 0 || area > 0
  })

  const allAreas = _.flow(
    _.get(`${namespace}.trackings`),
    validityFiltering,
    areaGrouping
  )

  const courseAreas = (courseId, holeIds = []) => (state) => _.flow(
    _.get(`${namespace}.trackings`),
    validityFiltering,
    (trackings) => {
      const courseTrackingGroupIds = _.flow(
        _.get(`${namespace}.courses.${courseId}`),
        _.omitBy((hole) => {
          return _.includes(_.get('holeId', hole), holeIds)
        }),
        _.map('trackingGroupIds'),
        _.flatten
      )(state)
      return _.pickBy((tracking) => {
        const id = _.get('id', tracking)
        return _.includes(id, courseTrackingGroupIds)
      }, trackings)
    },
    areaGrouping
  )(state)

  const courses = _.get(`${namespace}.courses`)

  const contexts = _.get(`${namespace}.contexts`)

  return {
    contexts,
    drawingData,
    drawingDataHole,
    teeTrackingsByHoleIdTeeId,
    trackings,
    allAreas,
    courseAreas,
    courses,
    saving,
    saveSuccess
  }
}

export const selectors = createSelectors()
