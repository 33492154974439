import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import assets from './assets'
import auth from './auth'
import clubs from './clubs'
import clubsList from './clubsList'
import clubStatus from './clubStatus'
import countries from './countries'
import courses from './courses'
import drawingData from './drawingData'
import drawingStyles from './drawingStyles'
import filter from './filter'
import gallery from './gallery'
import geoimages from './geoimages'
import graphics from './graphics'
import graphicsElements from './graphicsElements'
import guides from './guides'
import guidesData from './guidesData'
import holesTees from './holesTees'
import holesTexts from './holesTexts'
import jobs from './events/jobs'
import logo from './logo'
import organisations from './organisations'
import organisationsCoursesTees from './organisationsCoursesTees'
import status from './status'
import roles from './roles'
import tees from './tees'
import trackingGroupsMetaData from './trackingGroupsMetaData'
import sponsors from './sponsors'
import sponsorSetups from './sponsorSetups'
import uiProducts from './uiProducts'
import users from './users'
import communication from './communication'
import snackMessage from './snackMessage'
import pinSetups from './pinSetups'
import pinPositions from './pinPositions'
import regions from './regions'
import heatmapFilter from './heatmapFilter'

export default function createRootReducer (history) {
  const appReducer = combineReducers({
    assets,
    auth,
    clubs,
    clubsList,
    clubStatus,
    communication,
    countries,
    courses,
    drawingData,
    drawingStyles,
    filter,
    gallery,
    geoimages,
    graphics,
    graphicsElements,
    guides,
    guidesData,
    holesTees,
    holesTexts,
    jobs,
    organisations,
    organisationsCoursesTees,
    status,
    roles,
    logo,
    tees,
    trackingGroupsMetaData,
    sponsors,
    sponsorSetups,
    uiProducts,
    users,
    snackMessage,
    pinSetups,
    pinPositions,
    regions,
    heatmapFilter,

    router: connectRouter(history)
  })

  const rootReducer = (state, action) => {
    if (action.type === 'auth/LOGOUT' || action.type === 'auth/LOGIN_REQUEST') {
      const { router } = state
      state = { router }
    }

    return appReducer(state, action)
  }

  return rootReducer
}
