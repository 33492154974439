import _ from 'lodash/fp'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import { deleteEffect, getEffect, postEffect, prependClubsUrl } from './network'

import { actions, types, selectors as gallerySelectors } from '../reducers/gallery'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadGalleryEffect () {
  const url = yield prependClubsUrl('gallery')
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * uploadImageEffect (data) {
  const url = yield prependClubsUrl('gallery')
  const body = { data }
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteImageEffect (imageId) {
  const url = yield prependClubsUrl(`gallery/${imageId}`)
  const request = { url }
  const chan = yield call(deleteEffect, request)
  const result = yield take(chan)
  if (!result.error) {
    yield put(actions.purgeImage({ imageId }))
  }
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadGalleryFlow ({ payload }) {
  const gallery = yield call(loadGalleryEffect, payload)

  if (gallery.error) {
    yield put(actions.loadError(gallery))
  } else {
    yield put(actions.loadSuccess(gallery))
  }
}

export function * uploadImageFlow ({ payload }) {
  yield put(actions.setBlocked({ blocked: true }))
  const result = yield uploadImageEffect(payload)
  yield put(actions.addGalleryImage(result))
  yield put(actions.setBlocked({ blocked: false }))
  yield put(actions.uiDialogUploadHide())
}

export function * deleteSelectedFlow () {
  const deleteArray = yield select(gallerySelectors.getDeleteArray)
  if (_.isEmpty(deleteArray)) {
    return
  }
  yield put(actions.setBlocked({ blocked: true }))
  yield _.map(id => call(deleteImageEffect, id), deleteArray)
  yield put(actions.setBlocked({ blocked: false }))
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadGalleryFlow),
    takeLatest(types.UPLOAD_IMAGE, uploadImageFlow),
    takeLatest(types.DELETE_SELECTED, deleteSelectedFlow)
  ])
}
