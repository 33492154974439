import _ from 'lodash/fp'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import * as authReducer from '../reducers/auth'
import { actions, selectors, types } from '../reducers/clubs'
import { getClubResources } from './auth'

import { assignLeft } from '@fe/redux/reducers/util'
import { getEffect, prependClubsUrl, putEffect, postEffect } from './network'

import { LOCATION_CHANGE } from 'connected-react-router'
import { navigateReplaceRoot } from './redirects'

import * as snack from './snackMessage'

const decorateClub = _.flow(
  _.update('position.longitude', parseFloat),
  _.update('position.latitude', parseFloat),
  _.update('regionIds', ids => ids || [])
)

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadClubEffect ({ clubId: optionalClubId } = {}) {
  const request = {
    url: (yield call(prependClubsUrl, undefined, optionalClubId))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * loadClubsEffect () {
  const request = {
    url: `clubs`
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * putClubEffect (club) {
  const request = {
    url: `clubs/${club.id}`,
    options: {
      body: decorateClub(club)
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * createClubEffect (club) {
  club = decorateClub(club)

  const request = {
    url: `clubs`,
    options: {
      body: {
        name: club.name,
        handle: club.handle,
        srid: club.srid,
        region_ids: club.regionIds,
        position: club.position,
        'active': false,
        'admin_id': 0,
        'address': {
          'street': '',
          'city': '',
          'zip': '',
          'country_code': 'dk'
        },
        'drawing_style_id': 6
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

/**
 * takeLatest(types.LOAD_REQUEST, loadClubsFlow)
 */
export function * loadClubsFlow ({ payload }) {
  const clubs = yield call(loadClubsEffect, payload)
  if (clubs.error) {
    yield put(actions.loadError(clubs))
  } else {
    yield put(actions.loadSuccess(clubs))
  }
  yield put(actions.loadRequestDone())
}

export function * loadClubFlow ({ payload }) {
  const club = yield call(loadClubEffect, payload)
  if (club.error) {
    yield put(actions.loadError(club))
  } else {
    yield put(actions.loadSuccess([club]))
  }
  yield put(actions.loadRequestDone())
}

export function * putClubFlow ({ payload }) {
  const club = yield select(selectors.getCurrentClub)
  const mergedClub = assignLeft(payload, club)

  const result = yield call(putClubEffect, mergedClub)

  if (!result.error) {
    yield put(actions.loadRequestSingle())
    yield take(types.LOAD_REQUEST_DONE)
  } else {
    yield snack.setMessage('Saving club has failed')
  }
  yield put(actions.doneSaving())
}

export function * switchClubFlow ({ payload }) {
  const { clubId, url = 'home' } = payload
  yield put(authReducer.actions.reloadProfileRequest())
  yield take([authReducer.types.RELOAD_PROFILE_SUCCESS, authReducer.types.RELOAD_PROFILE_ERROR])
  yield call(navigateReplaceRoot, `/${clubId}/${url}`)
  yield take(LOCATION_CHANGE)
  yield call(getClubResources)
}

export function * createClubFlow ({ payload }) {
  const result = yield call(createClubEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(actions.createError(error))
  } else {
    const clubId = _.get('id', result)
    yield put(actions.loadRequest())
    yield take(types.LOAD_REQUEST_DONE)
    yield put(actions.createSuccess(result))
    yield call(switchClubFlow, { payload: { clubId, url: 'club' } })
  }
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadClubsFlow),
    takeLatest(types.LOAD_REQUEST_SINGLE, loadClubFlow),
    takeLatest(types.SAVE_CLUB, putClubFlow),
    takeLatest(types.SWITCH_CLUB, switchClubFlow),

    takeLatest(types.CREATE_REQUEST, createClubFlow)
  ])
}
