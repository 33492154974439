import _ from 'lodash/fp'
import { assignLeft, createReducer, createTypes, createActions } from '@fe/redux/reducers/util'

const namespace = 'status'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

function createSelectors () {
  const selfIntersections = _.get(`${namespace}.selfIntersections`)
  const boundarySelfIntersections = _.get(`${namespace}.boundarySelfIntersections`)
  const greensWithinBoundary = _.get(`${namespace}.greensWithinBoundary`)
  return {
    selfIntersections,
    boundarySelfIntersections,
    greensWithinBoundary
  }
}

export const selectors = createSelectors()

export const initialState = {
  selfIntersections: [], // id, elementName
  boundarySelfIntersections: [], // array of hole ids
  greensWithinBoundary: {}, // holeId -> [greenId1, greenId2, ..]
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => assignLeft({ loadRequestWaiting: true, loadError: null }),
  [types.LOAD_REQUEST_DONE]: p => assignLeft({ loadRequestWaiting: false }),
  [types.LOAD_SUCCESS]: p => assignLeft(p),
  [types.LOAD_ERROR]: p => assignLeft({ loadError: p })
}

export default createReducer(initialState, reducer)
