import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import * as JobsReducer from '../../reducers/events/jobs'
import { postEffect } from '../network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

function * getJobsEffect (body) {
  console.log('getJobsEffect', body)
  const url = `events/jobs`
  const request = {
    url,
    options: {
      body
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * getJobsFlow ({ payload }) {
  console.log('getJobsFlow', payload)
  const result = yield call(getJobsEffect, payload)
  if (result.error) {
    yield put(JobsReducer.actions.getJobsFailure(result.error))
  } else {
    yield put(JobsReducer.actions.getJobsSuccess(result))
  }
}

export default function * () {
  return yield all([
    takeLatest(JobsReducer.types.GET_JOBS, getJobsFlow)
  ])
}
