import { decamelizeKeys } from 'humps'
import _ from 'lodash/fp'
import { replace } from 'connected-react-router'
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'

import { actions, selectors, types } from '../reducers/courses'
import { actions as teesActions, types as teesTypes } from '../reducers/tees'
import {
  deleteEffect,
  getCurrentClubId,
  getEffect,
  postEffect,
  prependClubsUrl,
  putEffect
} from './network'
import { assignLeft } from '@fe/redux/reducers/util'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadCoursesEffect () {
  const request = {
    url: (yield call(prependClubsUrl, 'courses'))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveCourseEffect (course) {
  const courseId = _.get('id', course)
  const request = {
    url: (yield call(prependClubsUrl, `courses/${courseId}`)),
    options: {
      body: course
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * createCourseEffect (course) {
  const request = {
    url: (yield call(prependClubsUrl, `courses`)),
    options: {
      body: course
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteCourseEffect (courseId) {
  const request = {
    url: (yield call(prependClubsUrl, `courses/${courseId}`))
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadCoursesFlow ({ payload }) {
  const courses = yield call(loadCoursesEffect)

  if (courses.error) {
    yield put(actions.loadError(courses))
  } else {
    yield put(actions.loadSuccess(courses))
  }
  yield put(actions.loadRequestDone())
}

export function * saveCourseFlow ({ payload }) {
  const courseId = _.get('id', payload)
  const course = yield select(selectors.getCourse(courseId))
  const mergedCourse = assignLeft(payload, course)

  const result = yield call(saveCourseEffect, mergedCourse)
  if (result.error) {
    yield put(actions.saveError(result))
  } else {
    yield put(actions.saveSuccess())
  }
  yield put(actions.loadRequest())
  yield take([types.LOAD_ERROR, types.LOAD_SUCCESS])
  yield put(actions.saveDone())
}

export function * createCourseFlow ({ payload }) {
  const result = yield call(createCourseEffect, decamelizeKeys(payload))
  if (result.error) {
    yield put(actions.createError(result))
  } else {
    yield put(actions.createSuccess(result))
    yield put(actions.loadRequest())
    const { type: loadResponseType } = yield take([types.LOAD_ERROR, types.LOAD_SUCCESS])
    const clubId = yield getCurrentClubId()
    if (loadResponseType === types.LOAD_SUCCESS) {
      yield put(teesActions.loadRequest())
      yield take([teesTypes.LOAD_ERROR, teesTypes.LOAD_SUCCESS])
      yield put(replace(`/${clubId}/courses/${_.get('id', result)}`))
    } else {
      console.warn('There was some error fetching things. Just sending you back to the home page.')
      yield put(replace(`/${clubId}/home`))
    }
  }
  yield put(actions.createDone())
}

export function * deleteCourseFlow ({ payload }) {
  const courseId = _.get('id', payload)
  const result = yield call(deleteCourseEffect, courseId)
  if (result.error) {
    yield put(actions.deleteError(result))
  } else {
    yield put(actions.deleteSuccess(courseId))
    const clubId = yield getCurrentClubId()
    yield put(replace(`/${clubId}/home`))
  }
  yield put(actions.loadRequest())
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadCoursesFlow),
    takeLatest(types.SAVE, saveCourseFlow),
    takeLatest(types.CREATE, createCourseFlow),
    takeLatest(types.DELETE, deleteCourseFlow)
  ])
}
