import humps from 'humps'
import _ from 'lodash/fp'

import { usersLocation } from '../config'
import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'auth'

const typeKeys = [
  'BOOTSTRAP_REQUEST',
  'BOOTSTRAP_DONE',

  'SET_TOKEN',

  'LOGIN_REQUEST',
  'LOGIN_SUCCESS',
  'LOGIN_ERROR',
  'LOGIN_REQUEST_DONE',

  'LOGOUT',

  'RENEW_SESSION_REQUEST',
  'RENEW_SESSION_SUCCESS',

  'INIT_PASSWORD_RESET',
  'PASSWORD_RESET',
  'PASSWORD_RESET_SUCCESS',
  'PASSWORD_RESET_ERROR',
  'PASSWORD_RESET_DONE',

  'RELOAD_PROFILE_REQUEST',
  'RELOAD_PROFILE_SUCCESS',
  'RELOAD_PROFILE_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

// state partition when there is no token
const noToken = {
  token: null,
  tokenExpiredAt: null
}

export const initialState = {
  ...noToken,
  error: null,
  loginRequestWaiting: false,
  bootstrapped: false,
  nextPathName: undefined,
  profile: undefined,
  resetWaiting: false,
  resetError: undefined,
  reset: undefined
}

const reducer = {
  [types.BOOTSTRAP_DONE]: p => _.set('bootstrapped', true),
  [types.LOGIN_REQUEST]: p => _.flow(
    _.set('loginRequestWaiting', true),
    _.set('error', false)
  ),
  [types.SET_TOKEN]: p => _.flow(
    _.set('token', p.token),
    _.set('tokenExpiredAt', p.expired_at)
  ),
  [types.LOGIN_REQUEST_DONE]: p => _.set('loginRequestWaiting', false),
  [types.LOGIN_SUCCESS]: p => _.flow(
    _.set('nextPathName', _.get('nextPathName', p)),
    _.set('token', p.token),
    _.set('tokenExpiredAt', p.expired_at),
    _.set('profile', humps.camelizeKeys(p)),
    _.unset('error')
  ),
  [types.LOGOUT]: p => _.flow(
    _.set('nextPathName', _.get('nextPathName', p)),
    _.set('token', null),
    _.set('tokenExpiredAt', null),
    _.unset('error'),
    _.unset('profile')
  ),
  [types.LOGIN_ERROR]: p => s => {
    if (p.avoidRedirects) {
      return {
        ...s,
        error: p.error || true
      }
    } else {
      return {
        ...s,
        ...noToken,
        error: p.error || true
      }
    }
  },
  [types.RENEW_SESSION_SUCCESS]: p => _.set('tokenExpiredAt', p.expired_at),
  [types.INIT_PASSWORD_RESET]: p => _.unset('error'),

  [types.PASSWORD_RESET]: p => _.flow(
    _.set('resetError', false),
    _.set('reset', false),
    _.set('resetWaiting', true)
  ),
  [types.PASSWORD_RESET_SUCCESS]: p => _.flow(
    _.set('reset', p)
  ),
  [types.PASSWORD_RESET_ERROR]: p => _.flow(
    _.set('resetError', p)
  ),
  [types.PASSWORD_RESET_DONE]: p => _.flow(
    _.set('resetWaiting', false)
  ),
  [types.RELOAD_PROFILE_SUCCESS]: profile => _.set('profile', humps.camelizeKeys(profile))
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const auth = _.get(namespace)
  const profile = _.flow(auth, _.get('profile'))
  const isAdmin = _.flow(profile, _.get('admin'))
  const getToken = _.flow(auth, _.get('token'))
  const notBootstrapped = _.flow(auth, _.get('bootstrapped'), _.eq(false))
  const notLoggedIn = _.flow(getToken, _.isNil)
  const loginError = _.flow(auth, _.get('error'))
  const loginRequestWaiting = _.flow(auth, _.get('loginRequestWaiting'))
  const resetWaiting = _.flow(auth, _.get('resetWaiting'))
  const resetError = _.flow(auth, _.get('resetError'))
  const resetSuccess = _.flow(auth, _.get('reset'))

  // this could also stand somewhere else
  const activeClubId = s => {
    const path = _.get('router.location.pathname', s)
    const clubId = _.get(1, _.split('/', path))
    if (!_.isInteger(_.parseInt(10, clubId))) {
      return _.flow(
        _.get('router.location.pathname'),
        _.split('/'),
        _.get(1)
      )(s)
    }
    return clubId
  }

  const activeClubRoles = s => {
    const clubId = `${activeClubId(s)}`
    return _.flow(profile, _.get('roles'), _.getOr([], clubId))(s)
  }

  const profilePicture = state => {
    const p = profile(state)
    const fileName = _.get('photoFileName', p)
    return fileName 
      ? usersLocation() + p.id + '_' + fileName
      : usersLocation() + 'profile_picture_missing.png'
  }

  return {
    auth,
    profile,
    profilePicture,
    isAdmin,
    getToken,
    notBootstrapped,
    notLoggedIn,
    loginError,
    loginRequestWaiting,
    resetWaiting,
    resetError,
    resetSuccess,
    activeClubId,
    activeClubRoles
  }

  /// ///////////////////require('../view/constants.js').usersLocation() + userId + '_' + fileName
}

export const selectors = createSelectors()
