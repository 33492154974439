import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import createRootReducer from '../reducers'
import rootSaga from '../sagas'

const sagaMiddleware = createSagaMiddleware()
const initialState = {}

export const history = createBrowserHistory()
const reactRouterMiddleware = routerMiddleware(history)

export const store = createStore(
  createRootReducer(history),
  initialState,
  compose(
    applyMiddleware(sagaMiddleware, reactRouterMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  )
)

sagaMiddleware.run(rootSaga)
