import _ from 'lodash/fp'
import { createReducer, createTypes, createActions } from '@fe/redux/reducers/util'

export const updateModes = {
  EDIT: 'editing',
  CREATE: 'creating',
  UNKNOWN: 'unknown'
}

export const sponsorPositions = {
  FULLPAGE_PRE_FIRST: 'fullpage-first',
  FULLPAGE_PRE_SQUEEZE: 'fullpage-first-squeeze',
  FULLPAGE_PRE_SECOND: 'fullpage-second',
  FULLPAGE_CENTERED_FIRST: 'fullpage-centered-first',
  FULLPAGE_FILLED_FIRST: 'fullpage-filled-first',
  FULLPAGE_CENTERED_SECOND: 'fullpage-centered-second',
  FULLPAGE_FILLED_SECOND: 'fullpage-filled-second',
  FULLPAGE_CENTERED: 'fullpage-centered-in-progress',
  FULLPAGE_FILLED: 'fullpage-filled-in-progress',
  FIRST: 'first',
  FIRST_SQUEEZE: 'first_squeeze',
  SECOND: 'second'
}

export const isPendingFullPage = (position) => {
  return position === sponsorPositions.FULLPAGE_PRE_FIRST ||
    position === sponsorPositions.FULLPAGE_PRE_SQUEEZE ||
    position === sponsorPositions.FULLPAGE_PRE_SECOND
}

export const isFirstFullpage = (position) => {
  return position === sponsorPositions.FULLPAGE_CENTERED_FIRST ||
    position === sponsorPositions.FULLPAGE_FILLED_FIRST ||
    position === sponsorPositions.FULLPAGE_PRE_FIRST
}

export const isSecondFullpage = (position) => {
  return position === sponsorPositions.FULLPAGE_CENTERED_SECOND ||
    position === sponsorPositions.FULLPAGE_FILLED_SECOND ||
    position === sponsorPositions.FULLPAGE_PRE_SECOND
}

export const isCenteredFullpage = (position) => {
  return position === sponsorPositions.FULLPAGE_CENTERED_FIRST ||
    position === sponsorPositions.FULLPAGE_CENTERED_SECOND
}

export const isFilledFullpage = (position) => {
  return position === sponsorPositions.FULLPAGE_FILLED_FIRST ||
    position === sponsorPositions.FULLPAGE_FILLED_SECOND
}

export const isFullPage = (position) => {
  return isCenteredFullpage(position) || isFilledFullpage(position)
}

const namespace = 'sponsorSetups'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',
  'INITIATE_UPDATE',
  'INITIATE_UPDATE_RESULT',
  'CLEAR_UPDATE',
  'UPDATE',
  'DELETE',
  'DELETE_DONE',
  'SAVE',
  'SAVE_DONE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: undefined,
  [updateModes.EDIT]: null,
  [updateModes.CREATE]: null,
  saving: false,
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,
  activeSponsorSetupId: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => _.set('loadRequestWaiting', true),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('loadedAt', Date.now()),
    _.set('items', _.keyBy('id', p))
  ),
  [types.LOAD_ERROR]: p => _.set('loadError', _.get('error', p)),
  [types.CLEAR_UPDATE]: p => _.set(_.get('updateMode', p), null),
  [types.DELETE]: p => _.flow(
    _.set('deleting', true)
  ),
  [types.DELETE_DONE]: p => _.flow(
    _.set('deleting', false),
    _.update('items', _.omit(p))
  ),
  [types.SAVE]: p => _.set('saving', true),
  [types.SAVE_DONE]: p => _.set('saving', false)
}

function createSelectors () {
  const getAll = _.get(`${namespace}.items`)
  const sponsorSetupsByCourseId = courseId => _.flow(getAll, _.filter((ss) => {
    return _.get('course_id', ss) === _.parseInt(10, courseId)
  }), _.sortBy('name'))
  const getSponsorSetup = (id) => _.get(`${namespace}.items.${id}`)
  const getEditing = _.get(`${namespace}.${updateModes.EDIT}`)
  const getCreating = _.get(`${namespace}.${updateModes.CREATE}`)

  return {
    getAll,
    sponsorSetupsByCourseId,
    getSponsorSetup,
    getEditing,
    getCreating
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
