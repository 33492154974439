import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'countries'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_FAILURE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: []
}

const reducer = {
  [types.LOAD_SUCCESS]: _.set('items')
}

function createSelectors () {
  const countries = _.get(`${namespace}.items`)

  return {
    countries
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
