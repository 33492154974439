import _ from 'lodash/fp'
import { put } from 'redux-saga/effects'

import * as snackMessageReducer from '../reducers/snackMessage'

export function * setMessage (message) {
  yield put(snackMessageReducer.actions.setMessage(message))
}

export function * genericError (action) {
  const message = _.getOr('An error occurred', 'payload.reason', action)
  yield setMessage(message)
}

export function * genericSave () {
  yield setMessage('Saved')
}
