import * as util from '@fe/redux/reducers/util'
import _ from 'lodash/fp'

const namespace = 'snackMessage'

const typeKeys = [
  // string
  'SET_MESSAGE'
]

export const types = util.createTypes(typeKeys, namespace)

export const actions = util.createActions(types)

const createSelectors = () => {
  return {
    snackMessage: _.get(namespace)
  }
}

export const selectors = createSelectors()

const initialState = {
  time: 0,
  message: ''
}

const reducer = {
  [types.SET_MESSAGE]: message => _.flow(_.set('message', message), _.set('time', Date.now()))
}

export default util.createReducer(initialState, reducer, namespace)
