import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'holesTees'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'SAVE',
  'SAVE_DONE',
  'SAVE_SUCCESS',
  'SAVE_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},

  loadRequestWaiting: false,
  loadedAt: null,
  loadError: null,

  saveRequestWaiting: false,
  saveError: null,
  savedAt: null

}

const reducer = {
  [types.LOAD_REQUEST]: p => _.set('loadRequestWaiting', true),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('loadedAt', Date.now()),
    _.set('items', _.flow(
      _.groupBy('hole_id'),
      _.mapValues(_.keyBy('tee_id'))
    )(p))
  ),
  [types.LOAD_ERROR]: p => _.set('loadError', p.error),

  [types.SAVE]: p => _.set('saveRequestWaiting', true),
  [types.SAVE_DONE]: p => _.set('saveRequestWaiting', false),
  [types.SAVE_SUCCESS]: p => _.flow(_.set('savedAt', Date.now()), _.set('saveError', null)),
  [types.SAVE_ERROR]: p => _.set('saveError', p.error)
}

function createSelectors () {
  const holesTees = _.get(`${namespace}.items`)
  const getTeesForHole = (holeId) => _.get(`${namespace}.items.${holeId}`)
  const saving = _.get(`${namespace}.saveRequestWaiting`)
  const getTeesForHoles = (holeIds) => _.flow(
    _.get(`${namespace}.items`),
    _.pick(holeIds)
  )

  return {
    holesTees,
    getTeesForHole,
    saving,
    getTeesForHoles
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
