import _ from 'lodash/fp'
import { createActions, createTypes, createReducer } from '@fe/redux/reducers/util'

export const namespace = 'assets'

const typeKeys = [
  'GET_CATEGORIES',
  'GET_CATEGORIES_SUCCESS',
  'GET_CATEGORIES_FAILURE',

  'GET_ASSETS',
  'GET_ASSETS_SUCCESS',
  'GET_ASSETS_FAILURE',

  'POST_ASSETS',
  'POST_ASSETS_SUCCESS',
  'POST_ASSETS_FAILURE',

  'DELETE_ASSET',
  'DELETE_ASSET_SUCCESS',
  'DELETE_ASSET_FAILURE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  assets: {
    items: {},
    postStatus: undefined,
    getStatus: undefined,
    deleteStatus: undefined
  },
  categories: {
    items: {},
    status: undefined
  }
}

const reducer = {
  [types.GET_CATEGORIES]: () => _.set('categories.status', undefined),
  [types.GET_CATEGORIES_SUCCESS]: p => _.set('categories.items', p),
  [types.GET_CATEGORIES_FAILURE]: p => _.set('categories.status', p),

  [types.GET_ASSETS]: () => _.set('assets.getStatus', undefined),
  [types.GET_ASSETS_SUCCESS]: p => _.set('assets.items', _.keyBy('name', p)),
  [types.GET_ASSETS_FAILURE]: p => _.set('assets.getStatus', p),

  [types.POST_ASSETS]: p => _.set('assets.postStatus', undefined),
  [types.POST_ASSETS_SUCCESS]: p => _.set('assets.postStatus', p),
  [types.POST_ASSETS_FAILURE]: p => _.set('assets.postStatus', p),

  [types.DELETE_ASSET]: p => _.set('assets.deleteStatus', undefined),
  [types.DELETE_ASSET_SUCCESS]: p => _.set('assets.deleteStatus', p),
  [types.DELETE_ASSET_FAILURE]: p => _.set('assets.deleteStatus', p)
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const ns = _.get(namespace)

  return {
    categories: _.flow(ns, _.get('categories.items')),
    categoriesStatus: _.flow(ns, _.get('categories.status')),
    assets: _.flow(ns, _.get('assets.items')),
    assetsGetStatus: _.flow(ns, _.get('assets.getStatus')),
    assetsPostStatus: _.flow(ns, _.get('assets.postStatus')),
    deleteStatus: _.flow(ns, _.get('assets.deleteStatus'))
  }
}

export const selectors = createSelectors()
