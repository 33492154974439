import _ from 'lodash/fp'
import * as utils from '@fe/redux/reducers/util'

const namespace = 'pinPositions'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'UPSERT_REQUEST',
  'UPSERT_SUCCESS',
  'UPSERT_ERROR'
]

export const types = utils.createTypes(typeKeys, namespace)

export const actions = utils.createActions(types)

function createSelectors () {
  const pinPositions = _.get(`${namespace}.items`)
  const pinPositionsByName = _.flow(pinPositions, _.sortBy('name'))
  return {
    pinPositions,
    pinPositionsByName
  }
}

export const selectors = createSelectors()

export const initialState = {
  items: {
    // pinSetupId -> holeId -> { sideMeters, outMeters }
  }
}

const reducer = {
  [types.LOAD_SUCCESS]: p => _.set(`items.${_.get('0.pinSetupId', p)}`, _.keyBy('holeId', p)),

  [types.UPSERT_SUCCESS]: ps => _.flow(_.map(p => _.setWith(Object, `items.${p.pinSetupId}.${p.holeId}`, p), ps))
}

export default utils.createReducer(initialState, reducer)
