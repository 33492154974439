import _ from 'lodash/fp'

import { assignLeft, createActions, createReducer, createTypes } from '@fe/redux/reducers/util'

const namespace = 'logo'

export const typeKeys = [
  'CREATE_LOGO',
  'CREATE_LOGO_PRINT',
  'SAVE_LOGO',
  'SAVE_LOGO_DONE',
  'SHOW_MODAL',
  'CLEAR_DATA'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  data: {},
  syncingRound: false,
  syncingRectangular: false,
  modalShown: 'none'
}

const reducer = {
  [types.CREATE_LOGO]: data => _.set('data', data),

  [types.SAVE_LOGO]: ({ roundData, rectangularData }) => assignLeft({
    syncingRound: Boolean(roundData),
    syncingRectangular: Boolean(rectangularData),
    modalShown: (roundData && 'round') || (rectangularData && 'rectangular') || 'none'
  }),

  [types.SAVE_LOGO_DONE]: p => assignLeft({
    data: {
      roundData: null, // TODO could probably be undefined
      rectangularData: null // TODO could probably be undefined
    },
    syncingRound: false,
    syncingRectangular: false,
    modalShown: 'none'
  }),

  [types.SHOW_MODAL]: ({ modal }) => _.set('modalShown', modal),

  [types.CLEAR_DATA]: p => _.set('data', {
    roundData: null, // TODO could probably be undefined
    rectangularData: null // TODO could probably be undefined
  })
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const logo = _.get(`${namespace}.data`)
  const syncingRound = _.get(`${namespace}.syncingRound`)
  const syncingRectangular = _.get(`${namespace}.syncingRectangular`)
  const syncing = s => syncingRound(s) || syncingRectangular(s)
  const modalShown = _.get(`${namespace}.modalShown`)
  return {
    logo,
    syncingRound,
    syncingRectangular,
    syncing,
    modalShown
  }
}

export const selectors = createSelectors()
