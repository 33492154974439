import _ from 'lodash/fp'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'

// Reducers

// Internal misc

// Mui components

// Own components
import { IconButton } from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import withStyles from '@material-ui/core/styles/withStyles'
import colors from '@fe/ui/colors'

const styles = theme => ({
  button: {
    margin: theme.spacing(1),
    position: 'absolute',
    right: '0px',
    top: '0px'
  },
  compact: {
    margin: '0px',
    padding: '0px',
    width: 'auto',
    height: 'auto'
  }
})

class HelpButton extends Component {
  parseKeywordForHelpLink = (keyword) => {
    switch (keyword) {
      case 'Filters':
        return 'greenkeeper-tech-overview-b2b'
      case 'Information':
        return 'club-information-b2b'
      case 'Status':
        return 'club-status-b2b'
      case 'NewCourse':
        return 'courses-creating-or-managing-existing-b2b'
      case 'Opening Hours and Pricing':
        return 'club-opening-hours-pricing-b2b'
      case 'Users':
        return 'users-adding-or-removing-a-user-b2b'
      case 'Gallery':
        return 'club-gallery-b2b'
      case 'Sponsors':
        return 'promotion-creating-sponsor-b2b'
      case 'Sponsor Setups':
        return 'promotion-sponsor-setup-b2b'
      case 'New Product':
        return 'products-create-new-product-b2b'
      case 'Templates':
        return 'products-guide-pages-custom-b2b'
      case 'Areas':
        return 'greenkeeper-areas-b2b'
      case 'Communication':
        return 'communication-glfr-app-messages-b2b'
      case 'Game Insights':
        return 'insights-game-insights-b2b'
      case 'Tech Overview':
        return 'greenkeeper-tech-overview-b2b'
      case 'Heatmap':
        return 'insights-heatmap-b2b'
      default:
        return false
    }
  }

  render () {
    const { classes, children, compact = false, inverse, inline, keyword, href } = this.props
    const link = this.parseKeywordForHelpLink(keyword)
    if (!link && !href) {
      return children || false
    }
    const button = (
      <IconButton
        target='_blank'
        href={link
          ? `https://support.glfr.com/knowledge-base/${link}`
          : href}
        variant='fab'
        className={classNames({ [classes.compact]: compact, [classes.button]: !inline })}
      >
        <Info style={{ color: inverse ? colors.white : colors.businessBlue }} />
      </IconButton>
    )
    if (inline) {
      return button
    }
    return (
      <div style={{ position: 'relative' }}>
        {button}
        {children}
      </div>
    )
  }
}

export default _.compose(
  withStyles(styles),
  connect(
    (s) => ({
    }),
    (d) => ({
    })
  )
)(HelpButton)
