import { all } from 'redux-saga/effects'

import assetsSaga from './assets'
import authSaga from './auth'
import clubs from './clubs'
import clubsList from './clubsList'
import clubStatus from './clubStatus'
import countries from './countries'
import courses from './courses'
import drawingData from './drawingData'
import drawingStyles from './drawingStyles'
import filter from './filter'
import gallery from './gallery'
import graphics from './graphics'
import graphicsElements from './graphicsElements'
import guides from './guides'
import guidesData from './guidesData'
import holesTees from './holesTees'
import holesTexts from './holesTexts'
import jobs from './events/jobs'
import logo from './logo'
import { watchRequests } from './network'
import organisations from './organisations'
import organisationsCoursesTees from './organisationsCoursesTees'
import roles from './roles'
import status from './status'
import sponsors from './sponsors'
import sponsorSetups from './sponsorSetups'
import tees from './tees'
import trackingGroupsMetaData from './trackingGroupsMetaData'
import redirects from './redirects'
import users from './users'
import communication from './communication'
import regions from './regions'
import pinSetups from './pinSetups'
import pinPositions from './pinPositions'

export default function * rootSaga () {
  yield all([
    assetsSaga(),
    authSaga(),
    clubs(),
    clubsList(),
    clubStatus(),
    communication(),
    countries(),
    courses(),
    drawingData(),
    drawingStyles(),
    filter(),
    gallery(),
    graphics(),
    graphicsElements(),
    guides(),
    guidesData(),
    holesTees(),
    holesTexts(),
    jobs(),
    logo(),
    organisations(),
    organisationsCoursesTees(),
    roles(),
    status(),
    redirects(),
    sponsors(),
    sponsorSetups(),
    tees(),
    trackingGroupsMetaData(),
    watchRequests(),
    users(),
    regions(),
    pinSetups(),
    pinPositions()
  ])
}
