import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions, types } from '../reducers/holesTexts'

import {
  getEffect,
  prependClubsUrl
} from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

export function * loadHolesTextsEffect (holeId) {
  const request = {
    url: (yield call(prependClubsUrl, `holes/${holeId}/texts`))
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadHolesTextsFlow ({ payload: holeId }) {
  const textArray = yield call(loadHolesTextsEffect, holeId)
  yield put(actions.loadRequestDone())

  if (textArray.error) {
    yield put(actions.loadError(textArray))
  } else {
    yield put(actions.loadSuccess({ holeId, textArray }))
  }
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadHolesTextsFlow)
  ])
}
