import moment from 'moment'
import 'moment/min/locales'

// set global moment locale
const locale = window.navigator.userLanguage || window.navigator.language
moment.locale(locale)

export const ENV = process.env.REACT_APP_ENV
export const isProduction = ENV === 'production'

export const PATH_API_BASE = isProduction
  ? 'https://business-api.glfr.com'
  : 'https://business-api-staging.glfr.com'

export const PATH_WEBLATE_PROXY = isProduction
  ? 'https://weblate-proxy.glfr.com'
  : 'https://weblate-proxy-staging.glfr.com'

export const newHost = PATH_API_BASE

export const PATH_GLFR_API = isProduction
  ? 'https://api.glfr.com'
  : 'https://api-staging.glfr.com'

export const PATH_GRAPHQL = isProduction ? 'https://business-be.glfr.com/graphql' : 'https://business-be-staging.glfr.com/graphql'

const bucket = function () {
  switch (ENV) {
    case 'production':
      return 'ingo-prod'
    case 'staging':
      return 'ingo-staging'
    default:
      return 'ingo-staging'
  }
}

export const aws = {
  endpoint: 'https://s3-eu-west-1.amazonaws.com',
  bucket: bucket(),
  tempBucket: 'ingo-temp'
}

export const S3_PATH = aws.endpoint + '/' + aws.bucket + '/'

export const getClubLogo = (clubId) => `${S3_PATH}clubs/round/${clubId}_logo.png`
export const statusImagePath = (clubId) => (imageId) => `${S3_PATH}images/status/${clubId}/${imageId}.jpg`
export const newsPostImagePath = (clubId) => (imageId) => `${S3_PATH}images/news/${clubId}/${imageId}.jpg`

export function fileUpload ({ endpoint, token, file, onProgress, onSuccess, onFailure }) {
  // eslint-disable-next-line
  const xhr = new XMLHttpRequest()
  xhr.upload.addEventListener('progress', e => {
    if (e.lengthComputable) {
      const progress = e.loaded / e.total
      onProgress(progress)
    }
  })
  xhr.onreadystatechange = e => {
    const { readyState, status } = xhr
    if (readyState === 4) {
      if (status === 200 || status === 201) {
        onSuccess()
      } else {
        onFailure()
      }
    }
  }
  const completeEndpoint = `${PATH_API_BASE}/${endpoint}`
  xhr.open('POST', completeEndpoint, true)
  if (token) {
    xhr.setRequestHeader('X-Auth-Token', token)
  }
  xhr.send(file)
}

export function fileDelete ({ endpoint, token, onSuccess, onFailure }) {
  // eslint-disable-next-line
  const xhr = new XMLHttpRequest()
  xhr.onreadystatechange = e => {
    const { readyState, status } = xhr
    if (readyState === 4) {
      if (status === 200 || status === 201 || status === 204) {
        onSuccess()
      } else {
        onFailure()
      }
    }
  }
  const completeEndpoint = `${PATH_API_BASE}/${endpoint}`
  xhr.open('DELETE', completeEndpoint, true)
  if (token) {
    xhr.setRequestHeader('X-Auth-Token', token)
  }
  xhr.send()
}

export const awsLocation = aws.endpoint + '/' + aws.bucket + '/'
export const awsTempLocation = () => aws.endpoint + '/' + aws.tempBucket + '/'
export const appClubLogoLocation = awsLocation + 'clubs/round/'
export const printClubLogoLocation = awsLocation + 'clubs/app/'

export const feedClubStatusLocation = (clubHandle = '') => newHost + '/feeds/club-status/' + clubHandle
export const clubsLocation = () => awsLocation + 'clubs/original/'
export const guidesLocation = () => awsLocation + 'guides/'
export const overviewsLocation = () => awsLocation + 'images/overviews/'
export const spritesLocation = () => awsLocation + 'images/sprites/'
export const sponsorsLocation = () => awsLocation + 'images/sponsors/'
export const guidesIconLocation = () => awsLocation + 'images/guides/card/'
export const usersLocation = () => awsLocation + 'users/profile_images/original/'
export const galleryLocation = () => awsLocation + 'images/clubs/card/'
export const webLocation = () => awsLocation + 'images/clubs/web/'
export const digitalGuideLocation = () => awsLocation + 'course-guides/albatross-3/'
export const commentImagesLocation = (clubId, commentId) => (imageId) => awsLocation + `images/comments/thumbnail/${clubId}/${commentId}/${imageId}.png`
export const commentImagesLocationOriginal = (clubId, commentId) => (imageId) => awsLocation + `images/comments/original/${clubId}/${commentId}/${imageId}.png`
