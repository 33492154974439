import _ from 'lodash/fp'
import { all, put, select, takeLatest } from 'redux-saga/effects'

import Fuse from 'fuse.js'

import * as FilterReducer from '../reducers/filter'
import * as DrawingDataReducer from '../reducers/drawingData'
import * as TrackingGroupsMetaDataReducer from '../reducers/trackingGroupsMetaData'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

// Only returns intersection if both arrays have content
// Else it returns the array with content, or the empty array
function softIntersection (first, second) {
  const emptyFirst = _.isEmpty(first)
  const emptySecond = _.isEmpty(second)
  if (!emptyFirst) {
    if (emptySecond) {
      return first
    }
    return _.intersection(first, second)
  }
  if (emptySecond) {
    return []
  }
  return second
}

// Use a string to search in another array using Fuse
function filterSearch (array, searchArray, keys = [], id = 'id') {
  const strings = _.flow(_.replace(' ', ''), _.split(','))(searchArray)
  // If no search strings are found, undefined is returned, which is handled by softIntersection
  if (strings) {
    const fuseOptions = {
      threshold: 0.2,
      keys,
      id
    }
    const searchFuse = new Fuse(array, fuseOptions)
    const ids = _.flow(
      _.map((string) => searchFuse.search(string)),
      _.reduce((acc, idArray) => _.union(acc, idArray), [])
    )(strings)
    return ids
  }
}

export function * setIdsFlow () {
  // Select filter and data to filter on
  const filter = yield select(FilterReducer.selectors.filter)
  const trackings = yield select(DrawingDataReducer.selectors.trackings)
  const trackingGroupsMetaData = yield select(TrackingGroupsMetaDataReducer.selectors.descriptions)

  let matchingIds = []

  // Find elements matching elementName
  const filterElementName = _.get('elementName', filter)
  if (filterElementName) {
    const elementNameIds = filterSearch(_.values(trackings), filterElementName, ['elementName'])
    matchingIds = softIntersection(matchingIds, elementNameIds)
  }

  // Find elements matching name
  const filterName = _.get('name', filter)
  if (filterName) {
    const nameIds = filterSearch(_.values(trackingGroupsMetaData), filterName, ['name'], 'trackingGroupId')
    matchingIds = softIntersection(matchingIds, nameIds)
  }

  // Find elements matching description
  const filterDescription = _.get('description', filter)
  if (filterDescription) {
    const descriptionIds = filterSearch(_.values(trackingGroupsMetaData), filterDescription, ['description'], 'trackingGroupId')
    matchingIds = softIntersection(matchingIds, descriptionIds)
  }
  yield put(FilterReducer.actions.setIds(_.keyBy(_.identity, matchingIds)))
}

export function * clearIdsFlow ({ payload }) {
  yield put(FilterReducer.actions.setIds([]))
}

export default function * () {
  return yield all([
    takeLatest(FilterReducer.types.SET_FILTER, setIdsFlow),
    takeLatest(FilterReducer.types.CLEAR_FILTER, clearIdsFlow)
  ])
}
