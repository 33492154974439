import _ from 'lodash/fp'
import { createTypes, createActions, createReducer } from '@fe/redux/reducers/util'

const namespace = 'uiProducts'

const typeKeys = [
  'DIALOG_GLFR_GUIDE_HIDE',
  'DIALOG_GLFR_GUIDE_TOGGLE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

const initialState = {
  dialogGlfrGuideVisible: false
}

const reducer = {
  [types.DIALOG_GLFR_GUIDE_HIDE]: p => _.set('dialogGlfrGuideVisible', false),
  [types.DIALOG_GLFR_GUIDE_TOGGLE]: p => _.update('dialogGlfrGuideVisible', v => !v)
}

function createSelectors () {
  const dialogGlfrGuideVisible = _.get(`${namespace}.dialogGlfrGuideVisible`)
  return {
    dialogGlfrGuideVisible
  }
}

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
