import _ from 'lodash/fp'

import { createSelector } from 'reselect'

import { assignLeft, createActions, createReducer, createTypes } from '@fe/redux/reducers/util'
import * as authReducer from './auth'

const namespace = 'gallery'

export const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'SET_BLOCKED',

  'UPLOAD_IMAGE',
  'ADD_GALLERY_IMAGE',
  'TOGGLE_SELECT_IMAGE',
  'PURGE_IMAGE',
  'SELECT_FOR_DELETION',
  'DELETE_SELECTED',
  'DELETE_CANCEL',

  'UI_DIALOG_UPLOAD_SHOW',
  'UI_DIALOG_UPLOAD_HIDE',
  'UI_DIALOG_UPLOAD_TOGGLE'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},
  deleteArray: [],
  blocked: false,
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,
  uiDialogUploadOpen: false
}

const reducer = {
  [types.LOAD_REQUEST]: p => assignLeft({
    loadRequestWaiting: true,
    loadError: null
  }),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('items', _.keyBy('id', p)),
    _.set('loadRequestWaiting', false)
  ),
  [types.LOAD_ERROR]: ({ error: loadError }) => assignLeft({
    loadRequestWaiting: false,
    loadError
  }),
  [types.SET_BLOCKED]: ({ blocked }) => assignLeft({ blocked }),
  [types.ADD_GALLERY_IMAGE]: p => _.set(`items.${p.id}`, p),
  [types.TOGGLE_SELECT_IMAGE]: p => s => {
    if (_.indexOf(p.imageId, s.deleteArray) !== -1) {
      return { ...s, deleteArray: _.without([p.imageId], s.deleteArray) }
    } else {
      return { ...s, deleteArray: [...s.deleteArray, p.imageId] }
    }
  },
  [types.PURGE_IMAGE]: ({ imageId }) => _.flow(
    _.update('items', _.omitBy(i => i.id === _.parseInt(10, imageId))),
    _.update('deleteArray', _.without([imageId]))
  ),
  [types.SELECT_FOR_DELETION]: id => _.update('deleteArray', _.union([id])),
  [types.DELETE_CANCEL]: p => _.set('deleteArray', []),

  [types.UI_DIALOG_UPLOAD_SHOW]: p => _.set('uiDialogUploadOpen', true),
  [types.UI_DIALOG_UPLOAD_HIDE]: p => _.set('uiDialogUploadOpen', false),
  [types.UI_DIALOG_UPLOAD_TOGGLE]: p => _.update('uiDialogUploadOpen', v => !v)
}

export default createReducer(initialState, reducer)

function createSelectors () {
  const loading = _.get(`${namespace}.loadRequestWaiting`)
  // getGalleries -> all galleries for all loaded clubs (we don't clear these)
  const getGalleries = _.get(`${namespace}.items`)
  // getGallery -> just get the gallery images for *this* club
  const getGallery = createSelector(
    authReducer.selectors.activeClubId,
    getGalleries,
    loading,
    (id, galleries, loading) => {
      const res = _.filter({ 'club_id': _.parseInt(10, id) }, galleries)
      if (_.isEmpty(res)) {
        // if not loading this returns false, otherwise []
        return !loading && res
      } else {
        return res
      }
    }
  )

  const getDeleteArray = _.get(`${namespace}.deleteArray`)
  const getSyncing = _.get(`${namespace}.blocked`)
  const uiDialogUploadOpen = _.get(`${namespace}.uiDialogUploadOpen`)

  return {
    getGallery,
    getDeleteArray,
    getSyncing,
    uiDialogUploadOpen
  }
}

export const selectors = createSelectors()
