"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.teeColors = exports.materialReds = exports.materialOranges = exports.materialLightBlues = exports.materialGreens = exports.materialBlues = exports.isValidColor = exports.getContrastColor = exports.default = void 0;
var colors = {
  // Greens
  paleGreen: '#acc58c',
  paleGreenTransparent: '#acc58c80',
  lightGreen: '#739d3b',
  primaryDarkGreen: '#326038',
  // Blues
  paleBlue: '#dbe6ec',
  paleBlueTransparent: '#dbe6ec80',
  lightBlue: '#99a9b3',
  primaryDarkBlue: '#163739',
  businessBlue: '#163739',
  // Reds
  paleRed: '#dd6f65',
  paleRedTransparent: '#dd6f6580',
  lightRed: '#da594d',
  primaryDarkRed: '#d54336',
  trackFillRed: '#FFcccc50',
  trackStrokeRed: '#FF3030',
  strokeRedTransparent: '#FF303080',
  // Oranges
  paleOrange: '#f1af4a',
  paleOrangeTransparent: '#f1af4a80',
  lightOrange: '#f69500',
  primaryDarkOrange: '#d07c00',
  // primary colors (style guide)
  primaryLightGreen: '#739D3B',
  // primaryDarkGreen: '#326038',
  // primaryDarkBlue: '#163739',
  primaryOrange: '#F69500',
  primaryRed: '#D54336',
  // primary colors (NOT in style guide)
  primaryTeal: '#387A7D',
  primaryDeepPurple: '#6A1B9A',
  bordeaux: '#5F021F',
  // other
  pieLightBlue: '#BFE6F7',
  // par
  pieNormalBlue: '#40A9C2',
  // birdie
  pieNormalBlueTransparent: '#40A9C280',
  // secondary colors (style guide)
  secondaryGreen: '#ACC58C',
  secondaryLightBlue: '#DBE6EC',
  secondaryPaleBlue: '#99A9B3',
  secondaryDarkGrey: '#2C2C2C',
  secondaryWhiteGrey: '#FAFAFA',
  secondaryDarkGreyTransparent: '#2C2C2C80',
  secondaryWhite: '#FFFFFF',
  secondaryLightGrey: '#ECECEC',
  secondaryLightGreyTransparent: '#ECECEC80',
  // navigation
  navigationBarBackgroundColor: '#326038',
  // primaryDarkGreen
  navigationHeaderTint: '#FFFFFF',
  navigationBarTintColor: '#FFFFFF',
  placeholderText: '#AAAAAA',
  graphLines: '#99999980',
  // drawer
  drawerIconSelected: '#739D3B',
  // primaryLightGreen
  drawerIconDefault: '#2C2C2C',
  // secondaryDarkGrey
  drawerTextDefault: '#2C2C2C',
  // secondaryDarkGrey
  borderColor: '#ececec',
  // status colors
  success: '#43A047',
  error: '#D54336',
  // primaryRed
  warning: '#F69500',
  // primaryOrange
  // borders
  borderActive: '#FFFFFF',
  borderNeutral: '#CCCCCC',
  borderEvenMoreNeutral: '#DDDDDD',
  // feelings
  black: '#000000',
  white: '#FFFFFF',
  snow: '#EBEBEB',
  transparent: '#00000000',
  // ripples
  rippleNeutral: 'rgba(255,255,255,0.2)',
  // parallax colors from jam redesign #4213412312421
  parallax: '#f0f0f0',
  content: '#f8f8f8',
  interactions: '#ffffff',
  actionbar: '#326038',
  // CMY
  magenta: '#FF00FF',
  cyan: '#00FFFF',
  yellow: '#FFFF00'
};
var materialBlues = {
  '50': '#e3e7e7',
  '100': '#b9c3c4',
  '200': '#8b9b9c',
  '300': '#5c7374',
  '400': '#395557',
  '500': '#163739',
  '600': '#133133',
  '700': '#102a2c',
  '800': '#0c2324',
  '900': '#061617',
  'A100': '#58f0ff',
  'A200': '#25ebff',
  'A400': '#00daf1',
  'A700': '#00c3d7',
  'contrastDefaultColor': 'light'
};
exports.materialBlues = materialBlues;
var materialOranges = {
  '50': '#fef2e0',
  '100': '#fcdfb3',
  '200': '#fbca80',
  '300': '#f9b54d',
  '400': '#f7a526',
  '500': '#f69500',
  '600': '#f58d00',
  '700': '#f38200',
  '800': '#f27800',
  '900': '#ef6700',
  'A100': '#ffffff',
  'A200': '#ffede3',
  'A400': '#ffcdb0',
  'A700': '#ffbd96',
  'contrastDefaultColor': 'light'
};
exports.materialOranges = materialOranges;
var materialLightBlues = {
  '50': '#e8f5f8',
  '100': '#c6e5ed',
  '200': '#a0d4e1',
  '300': '#79c3d4',
  '400': '#5db6cb',
  '500': '#40a9c2',
  '600': '#3aa2bc',
  '700': '#3298b4',
  '800': '#2a8fac',
  '900': '#1c7e9f',
  'A100': '#40a9c2',
  'A200': '#40a9c2',
  'A400': '#40a9c2',
  'A700': '#40a9c2',
  'contrastDefaultColor': 'light'
};
exports.materialLightBlues = materialLightBlues;
var materialReds = {
  '50': '#fae8e7',
  '100': '#f2c7c3',
  '200': '#eaa19b',
  '300': '#e27b72',
  '400': '#db5f54',
  '500': '#d54336',
  '600': '#d03d30',
  '700': '#ca3429',
  '800': '#c42c22',
  '900': '#ba1e16',
  'A100': '#ffedec',
  'A200': '#ffbcb9',
  'A400': '#ff8b86',
  'A700': '#ff726d',
  'contrastDefaultColor': 'light'
};
exports.materialReds = materialReds;
var materialGreens = {
  50: '#e6ece7',
  100: '#c2cfc3',
  200: '#99b09c',
  300: '#709074',
  400: '#517856',
  500: '#326038',
  600: '#2d5832',
  700: '#264e2b',
  800: '#1f4424',
  900: '#133317',
  A100: '#73ff82',
  A200: '#40ff55',
  A400: '#0dff28',
  A700: '#00f21b',
  'contrastDefaultColor': 'light'
}; // Found a better contrast color generator
// https://stackoverflow.com/a/35970186

exports.materialGreens = materialGreens;

var getContrastColor = function getContrastColor(hex) {
  if (!hex) {
    return '#FFFFFF';
  }

  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  } // convert 3-digit hex to 6-digits.


  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  } // Fallback is just white


  if (hex.length !== 6) {
    return '#FFFFFF';
  } // Get rgb components


  var r = parseInt(hex.slice(0, 2), 16);
  var g = parseInt(hex.slice(2, 4), 16);
  var b = parseInt(hex.slice(4, 6), 16); // http://stackoverflow.com/a/3943023/112731

  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
};

exports.getContrastColor = getContrastColor;

var isValidColor = function isValidColor(colorCode) {
  return /(^#[0-9A-F]{6}$)/i.test(colorCode);
};

exports.isValidColor = isValidColor;
var teeColors = ['#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FFA500', '#FFFFFF', '#000000'];
exports.teeColors = teeColors;
var _default = colors;
exports.default = _default;