import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions as drawingDataActions, types as drawingDataTypes } from '../reducers/drawingData'

import { getEffect, postEffect, prependClubsUrl } from './network'
import * as snack from './snackMessage'

export function * loadDrawingDataEffect (holeId) {
  const url = yield prependClubsUrl(`holes/${holeId}/drawing/1`)
  const request = { url }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * saveDrawingDataEffect (data) {
  const holeId = _.get('holeid', data)
  const url = yield prependClubsUrl(`holes/${holeId}/save_drawing`) // TODO i know this is weird
  const request = { url, options: { body: data } }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * loadTrackingsEffect () {
  const url = yield prependClubsUrl('tracking_groups')
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * loadTrackingGroupCoursesEffect () {
  const url = yield prependClubsUrl('tracking_groups/courses')
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * getContextsEffect () {
  const url = 'graphics/contexts'
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * loadTrackingsFlow () {
  const result = yield call(loadTrackingsEffect)
  const error = _.get('error', result)
  if (error) {
    yield put(drawingDataActions.loadTrackingsError(error))
  } else {
    yield put(drawingDataActions.loadTrackingsSuccess(result))
  }
}

function * loadTrackingGroupCoursesFlow () {
  const result = yield call(loadTrackingGroupCoursesEffect)
  const error = _.get('error', result)
  if (error) {
    return yield put(drawingDataActions.loadTrackingGroupCoursesError(error))
  }
  const massagedData = _.flow(
    _.groupBy('courseId'),
    _.omitBy((holes) => (_.size(holes) < 2)),
    _.mapValues(_.keyBy('holeId'))
  )(result)
  return yield put(drawingDataActions.loadTrackingGroupCoursesSuccess(massagedData))
}

function * loadDrawingDataFlow ({ payload: holeId }) {
  const result = yield call(loadDrawingDataEffect, holeId)
  const error = _.get('error', result)
  if (error) {
    yield put(drawingDataActions.loadError(error))
  } else {
    yield put(drawingDataActions.loadSuccess(result))
  }
}

function * saveDrawingDataFlow ({ payload: data }) {
  const result = yield call(saveDrawingDataEffect, data)
  const error = _.get('error', result)
  if (error) {
    yield put(drawingDataActions.saveError(error))
  } else {
    yield put(drawingDataActions.saveSuccess(result))
    yield put(drawingDataActions.loadRequest(_.get('holeid', data)))
    yield snack.setMessage('Saved!')
  }
}

function * getContextsFlow () {
  const result = yield call(getContextsEffect)
  const error = _.get('error', result)
  if (error) {
    yield put(drawingDataActions.getContextsError(error))
  } else {
    yield put(drawingDataActions.getContextsSuccess(result))
  }
}

export default function * () {
  return yield all([
    takeLatest(drawingDataTypes.LOAD_REQUEST, loadDrawingDataFlow),
    takeLatest(drawingDataTypes.SAVE_REQUEST, saveDrawingDataFlow),
    takeLatest(drawingDataTypes.LOAD_TRACKINGS, loadTrackingsFlow),
    takeLatest(drawingDataTypes.LOAD_TRACKING_GROUP_COURSES, loadTrackingGroupCoursesFlow),
    takeLatest(drawingDataTypes.GET_CONTEXTS, getContextsFlow)
  ])
}
