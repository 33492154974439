import _ from 'lodash/fp'
import { assignLeft, createReducer, createTypes, createActions } from '@fe/redux/reducers/util'

import * as authReducer from './auth'

const namespace = 'roles'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

function createSelectors () {
  const roles = _.flow(
    _.get(`${namespace}.items`)
  )
  const selectableRoles = s => {
    const activeClubRoles = authReducer.selectors.activeClubRoles(s)
    const allRoles = roles(s)
    const isAdmin = authReducer.selectors.isAdmin(s)
    return isAdmin ? allRoles : _.intersection(activeClubRoles, roles(s))
  }
  return {
    roles,
    selectableRoles
  }
}

export const selectors = createSelectors()

export const initialState = {
  items: [],
  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => assignLeft({ loadRequestWaiting: true, loadError: null }),
  [types.LOAD_REQUEST_DONE]: p => assignLeft({ loadRequestWaiting: false }),
  [types.LOAD_SUCCESS]: p => _.set('items', p),
  [types.LOAD_ERROR]: p => assignLeft({ loadError: p })
}

export default createReducer(initialState, reducer)
