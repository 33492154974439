import _ from 'lodash/fp'
import { createTypes, createActions, createReducer, assignLeft } from '@fe/redux/reducers/util'

const namespace = 'tees'

const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'CREATE_REQUEST',
  'CREATE_DONE',
  'CREATE_SUCCESS',
  'CREATE_ERROR',

  'SAVE_REQUEST',
  'SAVE_DONE',
  'SAVE_SUCCESS',
  'SAVE_ERROR',

  'DELETE_REQUEST',
  'DELETE_DONE',
  'DELETE_SUCCESS',
  'DELETE_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},

  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,

  createRequestWaiting: false,
  createError: null,
  createdAt: null,

  saveRequestWaiting: false,
  saveError: null,
  savedAt: null,

  deleteRequestWaiting: false,
  deleteError: null
}

const reducer = {
  [types.LOAD_REQUEST]: p => _.set('loadRequestWaiting', true),
  [types.LOAD_REQUEST_DONE]: p => _.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    _.set('loadedAt', Date.now()),
    _.set('items', _.flow(
      _.groupBy('course_id'),
      _.mapValues(_.keyBy('id'))
    )(p))
  ),
  [types.LOAD_ERROR]: p => _.set('loadError', p.error),

  [types.CREATE_REQUEST]: p => _.set('createRequestWaiting', true),
  [types.CREATE_DONE]: p => _.set('createRequestWaiting', false),
  [types.CREATE_SUCCESS]: p => _.flow(
    _.set(`items.${_.get('course_id', p)}.${_.get('id', p)}`, p),
    assignLeft({ createdAt: Date.now(), createError: undefined })
  ),
  [types.CREATE_ERROR]: p => _.set('createError', p.error),

  [types.SAVE_REQUEST]: p => _.set('saveRequestWaiting', true),
  [types.SAVE_DONE]: p => _.set('saveRequestWaiting', false),
  [types.SAVE_SUCCESS]: p => _.flow(
    _.set(`items.${_.get('course_id', p)}.${_.get('id', p)}`, p),
    assignLeft({ savedAt: Date.now(), saveError: undefined })
  ),
  [types.SAVE_ERROR]: p => _.set('saveError', p.error),

  [types.DELETE_REQUEST]: p => _.set('deleteRequestWaiting', true),
  [types.DELETE_DONE]: p => _.set('deleteRequestWaiting', false),
  [types.DELETE_SUCCESS]: teeId => _.update('items', _.omit(teeId)),
  [types.DELETE_ERROR]: p => _.set('deleteError', p.error)
}

function createSelectors () {
  const getTees = _.get(`${namespace}.items`)
  const getTeesById = _.flow(
    getTees,
    _.flatMap(_.values),
    _.keyBy('id')
  )
  const getTeesByCourseId = _.flow(
    getTees,
    _.flatMap(_.values),
    _.groupBy('course_id'),
    _.mapValues(_.sortBy('total_distance'))
  )
  const getTeesForCourse = (courseId) => _.get(`${namespace}.items.${courseId}`)
  const getTeesForCourseSortedByDistance = (courseId) => _.flow(
    getTeesForCourse(courseId),
    _.sortBy('total_distance')
  )
  const getTee = (teeId) => _.flow(
    getTeesById,
    _.get(teeId)
  )
  return {
    getTees,
    getTeesById,
    getTeesByCourseId,
    getTeesForCourse,
    getTeesForCourseSortedByDistance,
    getTee
  }
}

export const isValid = t => (
  t &&
  !_.isEmpty(t.name) &&
  _.isNumber(t.course_rating) &&
  t.course_rating >= 0 &&
  _.isNumber(t.slope_rating) &&
  t.slope_rating >= 0 &&
  /^#[0-9A-F]{6}$/i.test(t.color)
)

export const selectors = createSelectors()

export default createReducer(initialState, reducer)
