import { ApolloClient } from 'apollo-client'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from 'apollo-link-context'

import { PATH_GRAPHQL } from '../config'
import { TOKEN_KEY } from '../sagas/auth'
import Store from 'localforage'

const httpLink = createHttpLink({
  uri: PATH_GRAPHQL
})

const uploadToS3HttpLink = createUploadLink({
  uri: PATH_GRAPHQL
})

const authLink = setContext(async (_request, { headers }) => {
  const token = await Store.getItem(TOKEN_KEY)
  return {
    headers: {
      ...headers,
      'X-Auth-Token': token && token.token
    }
  }
})

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Tee': return `${object.id}-${object.distance}-${object.color}-${object.name}`
      default: return defaultDataIdFromObject(object)
    }
  }
})

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
})

export default apolloClient

export const uploadToS3ApolloClient = new ApolloClient({
  link: authLink.concat(uploadToS3HttpLink),
  cache
})
