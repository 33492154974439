import _ from 'lodash/fp'
import * as reselect from 'reselect'
import * as util from '@fe/redux/reducers/util'
import jsMD5 from 'js-md5'

import { digitalGuideLocation, guidesLocation, overviewsLocation, guidesIconLocation } from '../config'

import { selectors as coursesSelectors } from './courses'

const namespace = 'guides'

const typeKeys = [
  'COPY',
  'COPY_DONE',
  'COPY_SUCCESS',
  'COPY_ERROR',

  'DELETE',
  'DELETE_DONE',
  'DELETE_SUCCESS',
  'DELETE_ERROR',

  'LOAD_REQUEST',
  'LOAD_REQUEST_DONE',
  'LOAD_SUCCESS',
  'LOAD_ERROR',

  'LOAD_APP_ORDERS',
  'LOAD_APP_ORDERS_DONE',

  'SET_APP_ORDERS',
  'SET_APP_ORDERS_FAILURE',
  'SET_APP_ORDERS_DONE',

  'CLEAR_UPDATE',

  'UPDATE',
  'UPDATE_SAVING',

  'SAVE',
  'SAVE_REDIRECT',
  'SAVE_DONE',
  'SAVE_SUCCESS',
  'SAVE_SUCCESS_REDIRECT',
  'SAVE_ERROR',

  'SET_DISTANCES',

  'GENERATE_PDF',
  'GENERATE_PDF_DONE',
  'GENERATE_PDF_SUCCESS',
  'GENERATE_PDF_ERROR',

  'GENERATE_TEE_SIGNS',
  'GENERATE_TEE_SIGNS_DONE',
  'GENERATE_TEE_SIGNS_SUCCESS',
  'GENERATE_TEE_SIGNS_ERROR',
  'GENERATE_TEE_SIGNS_URL',
  'SET_TEE_SIGNS_URL',

  'GENERATE_WEB_IMAGES',
  'GENERATE_WEB_IMAGES_URL_REQUEST',
  'GENERATE_WEB_IMAGES_URL_SET',

  'GENERATE_GREEN_DRAWINGS',
  'GENERATE_GREEN_DRAWINGS_URL_REQUEST',
  'GENERATE_GREEN_DRAWINGS_URL_SET',

  'GENERATE_DIGITAL',
  'GENERATE_DIGITAL_DONE',
  'GENERATE_DIGITAL_SUCCESS',
  'GENERATE_DIGITAL_ERROR',
  'GENERATE_OVERVIEW',
  'GENERATE_OVERVIEW_DONE',
  'GENERATE_OVERVIEW_SUCCESS',
  'GENERATE_OVERVIEW_ERROR',

  'UPLOAD_LOGO',
  'UPLOAD_LOGO_DONE',
  'UPLOAD_LOGO_SUCCESS',
  'UPLOAD_LOGO_ERROR',
  'TOGGLE_LOGO_UPLOAD',

  'RELEASE_ALL',

  'GET_OVERRIDES',
  'GET_OVERRIDES_DONE',
  'GET_OVERRIDES_ERROR',
  'GET_OVERRIDES_SUCCESS'
]

export const types = util.createTypes(typeKeys, namespace)

export const actions = util.createActions(types)

export const initialState = {
  items: {},

  appOrders: undefined,
  appOrdersError: undefined,
  setAppOrdersDone: true,
  saveRequestWaiting: false,
  saveError: null,
  savedAt: null,

  loadRequestWaiting: false,
  loadError: null,
  loadedAt: null,

  teeSignsUrls: {}, // guideId -> url

  generatePdfRequestWaiting: {}, // guideId -> timestamp
  generatePdfError: null,
  generateTeeSignsRequestWaiting: {}, // guideId -> timestamp
  generateTeeSignsError: null,
  generateDigitalRequestWaiting: {}, // guideId -> timestamp
  generateDigitalError: null,
  generateOverviewRequestWaiting: {}, // guideId -> timestamp
  generateOverviewError: null,
  copyRequestWaiting: false,
  copyError: null,
  deleteRequestWaiting: false,
  deleteError: null,
  logoUploadRequestWaiting: false,
  logoUploadError: null,
  showUploadGuideLogo: false,

  overrides: {}
}

const reducer = {
  [types.LOAD_APP_ORDERS_DONE]: p => _.set('appOrders', p),
  [types.SET_APP_ORDERS]: p => _.flow(
    _.set('appOrdersError', undefined),
    _.set('setAppOrdersDone', false)
  ),
  [types.SET_APP_ORDERS_FAILURE]: p => _.set('appOrdersError', p),
  [types.SET_APP_ORDERS_DONE]: p => _.set('setAppOrdersDone', true),
  [types.LOAD_REQUEST]: p => _.flow(
    util.set('loadRequestWaiting', true),
    util.set('items', {})
  ),
  [types.LOAD_REQUEST_DONE]: p => util.set('loadRequestWaiting', false),
  [types.LOAD_SUCCESS]: p => _.flow(
    util.set('loadedAt', Date.now()),
    util.set('items', _.keyBy('guide_id', p))
  ),
  [types.LOAD_ERROR]: p => util.set('loadError', p.error),

  [types.SAVE]: p => util.set('saveRequestWaiting', true),
  [types.SAVE_REDIRECT]: p => util.set('saveRequestWaiting', true),
  [types.SAVE_DONE]: p => util.set('saveRequestWaiting', false),
  [types.SAVE_SUCCESS]: p => _.flow(
    util.set('savedAt', Date.now()),
    util.set('saveError', null)
  ),
  [types.SAVE_SUCCESS_REDIRECT]: p => _.flow(
    util.set('savedAt', Date.now()),
    util.set('saveError', null)
  ),
  [types.SAVE_ERROR]: p => util.set('saveError', p.error),

  [types.SET_DISTANCES]: ({ guideId, holeId, distances }) => util.update(`items.${guideId}.distances`, _.flow(
    _.reject(_.flow(
      _.get('hole_id'),
      _.isEqual(holeId)
    )),
    _.concat(_.map(util.set('hole_id', holeId), distances))
  )),

  [types.GENERATE_PDF]: p => util.set(`generatePdfRequestWaiting.${p.guide.guide_id}`, Date.now()),
  [types.GENERATE_PDF_ERROR]: p => util.set('generatePdfError', p.error),
  [types.GENERATE_PDF_DONE]: p => util.set(`generatePdfRequestWaiting.${p.guide.guide_id}`, false),

  [types.GENERATE_TEE_SIGNS]: p => util.set(`generateTeeSignsRequestWaiting.${p.guide.guide_id}`, Date.now()),
  [types.GENERATE_TEE_SIGNS_ERROR]: p => util.set('generateTeeSignsError', p.error),
  [types.GENERATE_TEE_SIGNS_DONE]: p => util.set(`generateTeeSignsRequestWaiting.${p.guide.guide_id}`, false),
  [types.SET_TEE_SIGNS_URL]: p => util.set(`teeSignsUrls.${p.guideId}`, p.url),

  [types.GENERATE_WEB_IMAGES_URL_SET]: ({ guideId, url }) => util.set(`webImagesUrls.${guideId}`, url),

  [types.GENERATE_GREEN_DRAWINGS_URL_SET]: ({ guideId, url }) => util.set(`greenDrawingsUrls.${guideId}`, url),

  [types.GENERATE_DIGITAL]: p => util.set(`generateDigitalRequestWaiting.${p.guide.guide_id}`, Date.now()),
  [types.GENERATE_DIGITAL_ERROR]: p => util.set('generateDigitalError', p.error),
  [types.GENERATE_DIGITAL_DONE]: p => util.set(`generateDigitalRequestWaiting.${p.guide.guide_id}`, false),

  [types.GENERATE_OVERVIEW]: p => util.set(`generateOverviewRequestWaiting.${p.guide.guide_id}`, Date.now()),
  [types.GENERATE_OVERVIEW_ERROR]: p => util.set('generatePdfError', p.error),
  [types.GENERATE_OVERVIEW_DONE]: p => util.set(`generateOverviewRequestWaiting.${p.guide.guide_id}`, false),

  [types.COPY]: p => util.set('copyRequestWaiting', true),
  [types.COPY_DONE]: p => util.set('copyRequestWaiting', false),
  [types.COPY_ERROR]: p => util.set('copyError', p.error),

  [types.DELETE]: p => util.set('deleteRequestWaiting', true),
  [types.DELETE_DONE]: p => util.set('deleteRequestWaiting', false),
  [types.DELETE_SUCCESS]: guideId => util.update('items', _.omit(`${guideId}`)),
  [types.DELETE_ERROR]: p => util.set('deleteError', p),

  [types.UPLOAD_LOGO]: p => util.set('logoUploadRequestWaiting', true),
  [types.UPLOAD_LOGO_DONE]: p => util.set('showUploadGuideLogo', false),
  [types.TOGGLE_LOGO_UPLOAD]: p => util.update('showUploadGuideLogo', v => !v),
  [types.UPLOAD_LOGO_SUCCESS]: p => util.set('logoUploadRequestWaiting', false),
  [types.UPLOAD_LOGO_ERROR]: p => util.set('logoUploadError', p.error),

  [types.GET_OVERRIDES_SUCCESS]: p => util.set('overrides', p)
}

function createSelectors () {
  const setAppOrdersDone = _.get(`${namespace}.setAppOrdersDone`)
  const copyRequestWaiting = _.get(`${namespace}.copyRequestWaiting`)
  const copyError = _.get(`${namespace}.copyError`)
  const generatingDigital = guideId => state => _.get(`${namespace}.generateDigitalRequestWaiting.${guideId}`, state) > _.get(`${namespace}.items.${guideId}.generated_at_json`, state)
  const generatingOverview = guideId => state => _.get(`${namespace}.generateOverviewRequestWaiting.${guideId}`, state) > _.get(`${namespace}.items.${guideId}.generated_at_overview`, state)
  const generatingPdf = guideId => state => _.get(`${namespace}.generatePdfRequestWaiting.${guideId}`, state) > _.get(`${namespace}.items.${guideId}.generated_at_pdf`, state)
  const generatingTeeSigns = guideId => state => _.get(`${namespace}.generateTeeSignsRequestWaiting.${guideId}`, state) > _.get(`${namespace}.items.${guideId}.generated_at_tee_signs`, state)
  const items = _.get(`${namespace}.items`)
  const guidesByTitle = _.flow(items, _.sortBy((g) => _.upperCase(_.get('title', g))))
  const saveError = _.get(`${namespace}.saveError`)
  const saveRequestWaiting = _.get(`${namespace}.saveRequestWaiting`)

  const generatePdfRequestWaiting = (guideId) => _.get(`${namespace}.generatePdfRequestWaiting.${guideId}`)
  const generateTeeSignsRequestWaiting = (guideId) => _.get(`${namespace}.generateTeeSignsRequestWaiting.${guideId}`)
  const generateDigitalRequestWaiting = (guideId) => _.get(`${namespace}.generateDigitalRequestWaiting.${guideId}`)
  const generateOverviewRequestWaiting = (guideId) => _.get(`${namespace}.generateOverviewRequestWaiting.${guideId}`)
  const overrides = () => _.get(`${namespace}.overrides`)
  const courseIdToGuideId = reselect.createSelector(
    items,
    _.flow(
      _.filter(_.get('generated_at_json')),
      _.map(_.pick(['generated_at_json', 'courses', 'guide_id'])),
      _.map(g => util.update('courses', _.map(util.set('timestamp', g.generated_at_json)), g)),
      _.map('courses'),
      _.flatten,
      _.groupBy('course_id'),
      _.mapValues(_.flow(_.maxBy('timestamp'), _.get('guide_id')))
    )
  )

  const guidesWithCourse = (courseId) => _.flow(
    items,
    _.filter(
      _.flow(
        _.get('courses'),
        _.keyBy('course_id'),
        _.get(courseId)
      )
    )
  )

  const digitalUrl = digitalGuideLocation()
  const guideUrl = ({ clubId, guideId, version }) => {
    const url = `${guidesLocation()}${jsMD5(clubId + '_' + guideId)}.pdf`
    if (version) {
      const versionId = _.get('version', version)
      return `${url}?versionId=${versionId}`
    }
    return `${url}?h=${(new Date()).getTime()}`
  }
  const overviewUrl = ({ clubId, guideId }) => {
    const url = `${overviewsLocation()}${jsMD5(clubId + '_' + guideId)}.png`
    return `${url}?h=${(new Date()).getTime()}`
  }

  const guideLogoLocation = (guide) => `${guidesIconLocation()}${_.get('icon_file_name', guide)}?cache=${_.get('updated_at', guide)}`

  const coursesHolesImages = reselect.createSelector(
    courseIdToGuideId,
    coursesSelectors.getCourses,
    (guideMap, courses) => _.mapValues(
      c => (
        _.flow(
          util.update('holes', _.keyBy('id')),
          _.get('holes'),
          _.mapValues(h => guideMap[c.id] && `${digitalUrl}${guideMap[c.id]}/${h.id}.jpeg`)
        )(c)
      ),
      courses
    )
  )

  const teeSignUrl = (guideId) => _.get(`${namespace}.teeSignsUrls.${guideId}`)
  const webImagesUrl = (guideId) => _.get(`${namespace}.webImagesUrls.${guideId}`)
  const greenDrawingsUrl = (guideId) => _.get(`${namespace}.greenDrawingsUrls.${guideId}`)

  return {
    setAppOrdersDone,
    copyRequestWaiting,
    copyError,
    generatingDigital,
    generatingOverview,
    generatingPdf,
    generatingTeeSigns,
    items,
    guidesByTitle,
    saveError,
    saveRequestWaiting,
    coursesHolesImages,
    teeSignUrl,
    webImagesUrl,
    greenDrawingsUrl,
    guideUrl,
    overviewUrl,
    generatePdfRequestWaiting,
    generateTeeSignsRequestWaiting,
    generateDigitalRequestWaiting,
    generateOverviewRequestWaiting,
    guideLogoLocation,
    guidesWithCourse,
    overrides
  }
}

export const selectors = createSelectors()

export default util.createReducer(initialState, reducer)
