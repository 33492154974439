import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { types, actions } from '../reducers/status'
import { getEffect, prependClubsUrl } from './network'

//  ███████╗███████╗███████╗███████╗ ██████╗████████╗███████╗
//  ██╔════╝██╔════╝██╔════╝██╔════╝██╔════╝╚══██╔══╝██╔════╝
//  █████╗  █████╗  █████╗  █████╗  ██║        ██║   ███████╗
//  ██╔══╝  ██╔══╝  ██╔══╝  ██╔══╝  ██║        ██║   ╚════██║
//  ███████╗██║     ██║     ███████╗╚██████╗   ██║   ███████║
//  ╚══════╝╚═╝     ╚═╝     ╚══════╝ ╚═════╝   ╚═╝   ╚══════╝

function * loadSelfIntersectionsEffect () {
  const url = yield prependClubsUrl('status/self-intersections')
  const request = {
    url
  }

  const chan = yield call(getEffect, request)
  return yield take(chan)
}

function * loadBoundarySelfIntersectionsEffect () {
  const url = yield prependClubsUrl('status/boundary-self-intersections')
  const request = {
    url
  }

  const chan = yield call(getEffect, request)
  return yield take(chan)
}

function * loadGreensWithinBoundaryEffect () {
  const url = yield prependClubsUrl('status/greens-within-boundary')
  const request = {
    url
  }

  const chan = yield call(getEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

export function * loadFlow () {
  const selfIntersections = yield call(loadSelfIntersectionsEffect)
  const boundarySelfIntersections = yield call(loadBoundarySelfIntersectionsEffect)
  const greensWithinBoundary = yield call(loadGreensWithinBoundaryEffect)
  const payload = {
    selfIntersections,
    boundarySelfIntersections,
    greensWithinBoundary: _.keyBy('holeId', greensWithinBoundary)
  }
  if (selfIntersections.error || boundarySelfIntersections.error || greensWithinBoundary.error) {
    yield put(actions.loadError(payload))
  } else {
    yield put(actions.loadSuccess(payload))
  }

  yield put(actions.loadRequestDone())
}

export default function * () {
  return yield all([
    takeLatest(types.LOAD_REQUEST, loadFlow)
  ])
}
