import _ from 'lodash/fp'

import { createActions, createReducer, createTypes } from '@fe/redux/reducers/util'
import { selectors as authSelectors } from './auth'

const namespace = 'regions'

export const typeKeys = [
  'LOAD_REQUEST',
  'LOAD_SUCCESS',
  'LOAD_ERROR'
]

export const types = createTypes(typeKeys, namespace)

export const actions = createActions(types)

export const initialState = {
  items: {},
  loading: false,
  loadError: null,
  loadedAt: null
}

const reducer = {
  [types.LOAD_REQUEST]: p =>
    _.flow(
      _.set('loadRequestWaiting', true),
      _.set('loadError', null)
    ),
  [types.LOAD_SUCCESS]: p =>
    _.flow(
      _.set('loadRequestWaiting', false),
      _.set('loadedAt', Date.now()),
      _.update('items', (items) => {
        const regions = _.flow(
          _.keyBy('region')
        )(p)
        return {
          ...items,
          ...regions
        }
      })
    ),
  [types.LOAD_ERROR]: p =>
    _.set('loadError', _.get('error', p))
}

export default createReducer(initialState, reducer, namespace, { whitelist: ['items', 'loadedAt'] })

function createSelectors () {
  const getRegions = _.get(`${namespace}.items`)
  const getRegionsWhereOwner = _.flow(
    getRegions,
    _.filter(
      _.flow(
        _.get('roles'),
        _.includes('owner')
      )
    ),
    _.map('region')
  )
  const canSeeManagement = s => authSelectors.isAdmin(s) || !_.isEmpty(getRegionsWhereOwner(s))
  return {
    getRegions,
    getRegionsWhereOwner,
    canSeeManagement
  }
}

export const selectors = createSelectors()
