import _ from 'lodash/fp'
import { all, call, put, take, takeLatest } from 'redux-saga/effects'

import { actions as metaDataActions, types as metaDataTypes } from '../reducers/trackingGroupsMetaData'

import { getEffect, postEffect, prependClubsUrl, deleteEffect, putEffect } from './network'

export function * loadCommentsEffect () {
  const url = yield prependClubsUrl(`tracking_groups/comments`)
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * createCommentEffect (comment) {
  const url = yield prependClubsUrl(`tracking_groups/comments`)
  const request = {
    url,
    options: {
      body: comment
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * updateCommentEffect (comment) {
  const url = yield prependClubsUrl(`tracking_groups/comments/${_.get('id', comment)}`)
  const request = {
    url,
    options: {
      body: comment
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * deleteCommentEffect (comment) {
  const url = yield prependClubsUrl(`tracking_groups/comments/${_.get('id', comment)}`)
  const request = {
    url
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

export function * loadDescriptionsEffect () {
  const url = yield prependClubsUrl(`tracking_groups/descriptions`)
  const request = {
    url
  }
  const chan = yield call(getEffect, request)
  return yield take(chan)
}

export function * createDescriptionEffect (description) {
  const url = yield prependClubsUrl(`tracking_groups/${_.get('trackingGroupId', description)}/descriptions`)
  const request = {
    url,
    options: {
      body: description
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * updateDescriptionEffect (description) {
  const url = yield prependClubsUrl(`tracking_groups/${_.get('trackingGroupId', description)}/descriptions`)
  const request = {
    url,
    options: {
      body: description
    }
  }
  const chan = yield call(putEffect, request)
  return yield take(chan)
}

export function * deleteDescriptionEffect (description) {
  const url = yield prependClubsUrl(`tracking_groups/${_.get('trackingGroupId', description)}/descriptions`)
  const request = {
    url
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

export function * uploadImageEffect (payload) {
  const url = yield prependClubsUrl(`tracking_groups/comments/${_.get('commentId', payload)}/images`)
  const request = {
    url,
    options: {
      body: {
        data: _.get('data', payload)
      }
    }
  }
  const chan = yield call(postEffect, request)
  return yield take(chan)
}

export function * deleteImageEffect (payload) {
  const { imageId, commentId } = payload
  const url = yield prependClubsUrl(`tracking_groups/comments/${commentId}/images/${imageId}`)
  const request = {
    url
  }
  const chan = yield call(deleteEffect, request)
  return yield take(chan)
}

//  ███████╗ █████╗  ██████╗  █████╗ ███████╗
//  ██╔════╝██╔══██╗██╔════╝ ██╔══██╗██╔════╝
//  ███████╗███████║██║  ███╗███████║███████╗
//  ╚════██║██╔══██║██║   ██║██╔══██║╚════██║
//  ███████║██║  ██║╚██████╔╝██║  ██║███████║
//  ╚══════╝╚═╝  ╚═╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝

function * loadCommentsFlow () {
  const result = yield call(loadCommentsEffect)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.loadCommentsError(error))
  } else {
    const commentsObject = _.flow(
      _.groupBy('trackingGroupId'),
      _.mapValues(_.keyBy('id'))
    )(result)
    yield put(metaDataActions.loadCommentsSuccess(commentsObject))
  }
}

function * createCommentFlow ({ payload }) {
  const result = yield call(createCommentEffect, _.get('comment', payload))
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.createCommentError(error))
  } else {
    const dataArray = _.get('dataArray', payload)
    if (_.size(dataArray) > 0) {
      const imageUploadFns = _.map((data) => {
        return put(metaDataActions.uploadCommentImage({ data, commentId: _.get('id', result) }))
      }, dataArray)
      yield all(imageUploadFns)
    }
    yield put(metaDataActions.createCommentSuccess(result))
  }
}

function * updateCommentFlow ({ payload }) {
  const result = yield call(updateCommentEffect, _.get('comment', payload))
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.updateCommentError(error))
  } else {
    const dataArray = _.get('dataArray', payload)
    if (_.size(dataArray) > 0) {
      const imageUploadFns = _.map((data) => {
        return put(metaDataActions.uploadCommentImage({ data, commentId: _.get('id', result) }))
      }, dataArray)
      yield all(imageUploadFns)
    }
    yield put(metaDataActions.updateCommentSuccess(result))
  }
}

function * deleteCommentFlow ({ payload }) {
  const result = yield call(deleteCommentEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.deleteCommentError(error))
  } else {
    const commentsObject = _.flow(
      _.groupBy('trackingGroupId'),
      _.mapValues(_.keyBy('id'))
    )(result)
    yield put(metaDataActions.deleteCommentSuccess(commentsObject))
  }
}

function * loadDescriptionsFlow () {
  const result = yield call(loadDescriptionsEffect)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.loadDescriptionsError(error))
  } else {
    const descriptionsObject = _.keyBy('trackingGroupId', result)
    yield put(metaDataActions.loadDescriptionsSuccess(descriptionsObject))
  }
}

function * createDescriptionFlow ({ payload }) {
  const result = yield call(createDescriptionEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.createDescriptionError(error))
  } else {
    yield put(metaDataActions.createDescriptionSuccess(result))
  }
}

function * updateDescriptionFlow ({ payload }) {
  const result = yield call(updateDescriptionEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.updateDescriptionError(error))
  } else {
    yield put(metaDataActions.updateDescriptionSuccess(result))
  }
}

function * deleteDescriptionFlow ({ payload }) {
  const result = yield call(deleteDescriptionEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.deleteDescriptionError(error))
  } else {
    const descriptionsObject = _.keyBy('trackingGroupId', [result])
    yield put(metaDataActions.deleteDescriptionSuccess(descriptionsObject))
  }
}

function * uploadImageFlow ({ payload }) {
  const result = yield call(uploadImageEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.uploadCommentImageError(error))
  } else {
    yield put(metaDataActions.uploadCommentImageSuccess(result))
    yield put(metaDataActions.loadComments())
  }
}

function * deleteImageFlow ({ payload }) {
  const result = yield call(deleteImageEffect, payload)
  const error = _.get('error', result)
  if (error) {
    yield put(metaDataActions.deleteCommentImageError(error))
  } else {
    yield put(metaDataActions.deleteCommentImageSuccess(result))
    yield put(metaDataActions.loadComments())
  }
}

export default function * () {
  return yield all([
    takeLatest(metaDataTypes.LOAD_COMMENTS, loadCommentsFlow),
    takeLatest(metaDataTypes.CREATE_COMMENT, createCommentFlow),
    takeLatest(metaDataTypes.UPDATE_COMMENT, updateCommentFlow),
    takeLatest(metaDataTypes.DELETE_COMMENT, deleteCommentFlow),
    takeLatest(metaDataTypes.LOAD_DESCRIPTIONS, loadDescriptionsFlow),
    takeLatest(metaDataTypes.CREATE_DESCRIPTION, createDescriptionFlow),
    takeLatest(metaDataTypes.UPDATE_DESCRIPTION, updateDescriptionFlow),
    takeLatest(metaDataTypes.DELETE_DESCRIPTION, deleteDescriptionFlow),
    takeLatest(metaDataTypes.UPLOAD_COMMENT_IMAGE, uploadImageFlow),
    takeLatest(metaDataTypes.DELETE_COMMENT_IMAGE, deleteImageFlow)
  ])
}
